import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import { TableList } from '@components'

const PaymentDetails: FC = () => {
  const theme = useTheme()
  return (
    <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
      <Grid theme={theme} item xs={6}>
        <TableList
          listTitle="FA/Gross Capitalized Cost"
          items={[
            {
              title: 'Selling Price',
              value: '$00,000.00'
            },
            {
              title: 'Options',
              value: '$00,000.00'
            },
            {
              title: 'F&I Products',
              value: '$00,000.00'
            },
            {
              title: 'Fees',
              value: '$00,000.00'
            },
            {
              title: 'Trade-In Inequity',
              value: '$00,000.00'
            },
            {
              title: 'Taxes Capitalization',
              value: '$00,000.00'
            },
            {
              title: 'Total',
              value: '$00,000.00',
              hasBoldText: true
            }
          ]}
        />
        <TableList
          listTitle="Capitalized Cost Reduction"
          items={[
            {
              title: 'Down Payment',
              value: '$00,000.00'
            },
            {
              title: 'Trade-In Equity',
              value: '$00,000.00'
            },
            {
              title: 'Rebates & Incentives',
              value: '$00,000.00'
            },
            {
              title: 'Total',
              value: '$00,000.00',
              hasBoldText: true
            }
          ]}
        />
        <TableList
          listTitle="NFA/Adjusted Capitalized Cost"
          items={[
            {
              title: 'Balance',
              value: '$00,000.00',
              hasBoldText: true
            }
          ]}
        />
      </Grid>
      <Grid theme={theme} item xs={6}>
        <TableList
          listTitle="Due at Signing"
          items={[
            {
              title: 'Down Payment',
              value: '$00,000.00'
            },
            {
              title: 'First Periodic Payment',
              value: '$00,000.00'
            },
            {
              title: 'Upfront Options',
              value: '$00,000.00'
            },
            {
              title: 'Upfront F&Is',
              value: '$00,000.00'
            },
            {
              title: 'Upfront Fees',
              value: '$00,000.00'
            },
            {
              title: 'Total',
              value: '$00,000.00',
              hasBoldText: true
            }
          ]}
        />
        <TableList
          listTitle="Payment Details"
          items={[
            {
              title: 'Monthly Payment',
              value: '$00,000.00'
            },
            {
              title: 'Term',
              value: '00'
            },
            {
              title: 'Buy Rate',
              value: '0.00%'
            },
            {
              title: 'Markup Rate',
              value: '0.00%'
            },
            {
              title: 'Sell Rate',
              value: '0.00%'
            },
            {
              title: 'Days to 1st Payment',
              value: '00'
            },
            {
              title: 'Financed Charges',
              value: '$00,000.00'
            },
            {
              title: 'Financed Charges',
              value: '$00,000.00',
              hasBoldText: true
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default PaymentDetails
