export const accessToken = 'settings'

export const ACCESS_TOKEN_KEY = 'access_token'
export const SETTINGS = 'settings'
export const EXTERNAL_SUBSCRIPTION_KEY = 'external-subscription-key'
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{6,35}$/
export const PHONE_REGEX =
  /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const ZIP_CODE_REGEX = /^\d{5,5}?(-\d{4})?$/
export const WEBSITE_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
export const vehcileStatuses = {
  VEHICLE_AVAILABLE_CONFIRMTAION: 'PENDING_AVAILABILITY_CHECK',
  GET_READY_CHECKLIST: 'PENDING_POST_SALE_DEALER_ACTIONS',
  SCHEDULE_PICKUP: 'PENDING_PICKUP',
  PENDING_RETURN: 'PENDING_RETURN',
  COMPLETED: 'COMPLETE',
  CANCELED: 'CANCELED'
}

export const WORKQUEUE_SCREEN_COMPONENTS = [
  {
    heading: 'Vehicle Availability Confirmation',
    subheading: 'Are these vehicles available?'
  },
  {
    heading: 'Get Ready Checklist',
    subheading: 'Mark get ready checklist.'
  },
  {
    heading: 'Scheduled Pick-ups',
    subheading: 'Mark checklist for pick-ups.'
  },
  {
    heading: 'Returns',
    subheading: 'Mark checklist for returns.'
  },
  {
    heading: 'Completed',
    subheading: 'Below is the list of completed orders.'
  },
  {
    heading: 'Cancelled',
    subheading: 'Below is the list of cancelled orders.'
  }
]
export const urlPattern = /^(https?:\/\/)?[\da-z.-]+\.[a-z.]{2,6}\/?.*$/i

export const DEFAULT_RENTAL_FREQUENCY = 'Monthly'

export const DEFAULT_RENTAL_MODE = 'Advance'

export const RENTAL_FREQUENCY = [
  { id: 'Monthly', text: 'Monthly' },
  { id: 'Quarterly', text: 'Quarterly' },
  { id: 'SemiAnnual', text: 'Semi Annual' },
  { id: 'Annual', text: 'Annual' }
]

export enum CustomerColumns {
  CUSTOMER_NAME = 'full_name',
  VINS = 'vins',
  StockNumbers = 'stockNumbers',
  REFERENCE_ID = 'reference_id',
  SUBSCRIPTION_START_DATE = 'subscription_start_date',
  SUBSCRIPTION_END_DATE = 'subscription_end_date',
  Order_Ids = 'orderIds',
  Order_Status = 'order_status',
  VIN = 'vin',
  ORDER_IDENTIFIER = 'order_identifier',
  Stock_Number = 'stock_number',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  DEALER_ID = 'dealer_id'
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc'
}

export enum SortOrderFields {
  order_updated_at = 'order_updated_at'
}

export const DisclaimerNames = {
  CCPA: 'CCPA',
  TCPA: 'TCPA',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS'
}

export const ConsentStatus = {
  PUBLISHED: 'Published'
}
