import { FC, useEffect, useState } from 'react'
import { Link, useTheme } from '@mui/material'
import { Dialog, Box, Button, Grid, Typography } from '@ntpkunity/controls'
import { VehicleCardUi } from 'components'
import { useModal } from 'react-modal-hook'
import { useGetTrimCode } from 'apis/dealer-addon.service'
import { IVehicleDetail } from '@models'
import { useStoreContext } from '@store/storeContext'
// import { getUser } from "@helpers/methods";

const AddNewInventoryOption: FC<{
  setfinalOption: any
  setcategory: any
  vehicleData: IVehicleDetail
  setlistChanged: any
}> = ({ setfinalOption, setcategory, vehicleData, setlistChanged }) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [categoryName, setCategoryName] = useState(setcategory)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [attachedOption, setattachedOption] = useState(
    vehicleData ? vehicleData.dealer_options : []
  )
  const [allOption, setallOption] = useState<any>([])
  const { data: dealerOptions } = useGetTrimCode(
    states?.dealerAndLenderInfo?.dealer_code,
    states?.orderData?.selected_vehicle?.model_code
  )
  // const dealerData = getUser();

  const loadOptions = () => {
    let prev: any = []
    prev = dealerOptions?.map((options: any) => {
      return options.id
    })
    setallOption(prev)
  }
  const removeItem = (id: number) => {
    setattachedOption(attachedOption.filter((i) => i !== id))
    vehicleData.dealer_options = vehicleData.dealer_options.filter((item: any) => item !== id)
  }
  const addItem = (id: number) => {
    setattachedOption([...attachedOption, id])
    vehicleData.dealer_options = vehicleData.dealer_options.concat([id])
  }
  useEffect(() => {
    setfinalOption(attachedOption)
    setCategoryName(setcategory)
  }, [attachedOption, allOption])
  const [openOptionModal, closeOptionModal] = useModal(
    () => (
      <>
        <Dialog
          disablePortal
          className="dialog-with-border"
          theme={theme}
          title={selectedOption?.product_name}
          open
          onCloseDialog={closeOptionModal}
          noFooter
        >
          <Box theme={theme} className="img-wrap">
            <img src={selectedOption.image[0]} alt="option detail" />
          </Box>
        </Dialog>
      </>
    ),
    [selectedOption]
  )

  return (
    <>
      <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
        {categoryName?.map((addOnDetail: any, index: number) => (
          <Grid theme={theme} key={index} item xs={12} md={6} lg={3}>
            <VehicleCardUi theme={theme} className="vehicle-card full-width" onLoad={loadOptions}>
              <Typography theme={theme} className="tag" component="span">
                {addOnDetail.offered_by}
              </Typography>
              <Box theme={theme} className="vc-img-wrap h-120">
                <img className="prd-img" src={addOnDetail?.image[0]} alt="Option Detail" />
              </Box>
              <Typography theme={theme} className="title" component="div">
                {addOnDetail.product_name}
              </Typography>
              <Typography theme={theme} className="price" component="div">
                ${addOnDetail.price.toLocaleString()}
              </Typography>
              <Link
                className="link"
                onClick={() => {
                  setSelectedOption(addOnDetail)
                  openOptionModal()
                }}
              >
                View Details
              </Link>
              {attachedOption.find((item) => {
                return item == addOnDetail.id
              }) ? (
                <Button
                  theme={theme}
                  fullWidth
                  secondary
                  text={'Remove'}
                  onClick={() => {
                    removeItem(addOnDetail.id)
                    setlistChanged(true)
                  }}
                />
              ) : (
                <Button
                  theme={theme}
                  fullWidth
                  primary
                  text={'Add'}
                  onClick={() => {
                    addItem(addOnDetail.id)
                    setlistChanged(true)
                  }}
                />
              )}
            </VehicleCardUi>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default AddNewInventoryOption
