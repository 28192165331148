import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'

import { Box, Button, Dialog, Grid, Icon, Skeleton, Typography } from '@ntpkunity/controls'

import { DetailCard, ImgCard, NoPreviewImage } from '@components'

import { useStoreContext } from '@store/storeContext'
import { utcToLocal } from '@helpers/methods'
import { useDonwnloadDocument } from '@apis/dealer-workqueue.service'
import { Link } from 'react-router-dom'
import { ImageRotate } from '@app/in-progress/schedule-pickup/license'
import DisableLoader from '@public/assets/images/loader-disabled.gif'

const OrderLicenseInformation: FC<{ licenseLoading: any; dataLoading: any }> = ({
  licenseLoading,
  dataLoading
}) => {
  const {
    states: { licenseInformation }
  } = useStoreContext()
  const { mutate: getDocURL } = useDonwnloadDocument()
  const theme = useTheme()
  const [selfieImage, setSelfieImage] = useState<string | undefined>(undefined)
  const [backImage, setBackImage] = useState<string | undefined>(undefined)
  const [frontImage, setFrontImage] = useState<string | undefined>(undefined)
  const [image, setImage] = useState<any>()
  const [image_url, setImageUrl] = useState<any>()
  const [image_type, setImageType] = useState<any>()
  const [rotateImage, setRotateImage] = useState<any>(0)
  const [dialogueOpen, setDialogOpened] = useState<any>(false)
  const [isImageDownloading, setIsImageDownloading] = useState(false)

  const [toggleImageClass, setToggleImageClass] = useState<boolean>(false)
  useEffect(() => {
    const licenseInfo = [licenseInformation]
    if (licenseInformation != (undefined || null)) {
      licenseInfo?.map(async (element) => {
        setFrontImage(await downloadDocumentByArtifactId(element?.front_image))
        setSelfieImage(await downloadDocumentByArtifactId(element?.selfie_image))
        setBackImage(await downloadDocumentByArtifactId(element?.back_image))
      })
    }
  }, [licenseInformation])
  const downloadDocumentByArtifactId = (artifactId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      getDocURL(
        { artifact_id: artifactId },
        {
          onSuccess: (data: any) => {
            resolve(data?.document_url)
          },
          onError: (error: any) => {
            reject(error)
          }
        }
      )
    })
  }
  const HANDLE_LICENCE_IMAGE_DOWNLOAD = async (image_url: any, image_type: any) => {
    setIsImageDownloading(true)
    downloadImage(await downloadDocumentByArtifactId(image_url), `${image_type}.png`)
  }
  const downloadImage = async (signedUrl: RequestInfo, filename: string) => {
    // Fetch the image data
    const response = await fetch(signedUrl)
    const blob = await response.blob()

    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
    setIsImageDownloading(false)
  }
  return (
    <>
      <DetailCard className="detail-card" mb={2}>
        <Typography theme={theme} mb={2} component="h4" variant="h4">
          License Details
        </Typography>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Name"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {licenseLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={licenseInformation?.first_name ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="License Number"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {licenseLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={licenseInformation?.license_number ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="License Issue State"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {licenseLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="span"
                children={licenseInformation?.address?.state_name ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Expiry Date"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {licenseLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={utcToLocal(licenseInformation?.expiry_date, 'MM-DD-YYYY') ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <Grid className="container" theme={theme} container>
          <Grid theme={theme} item sm={3} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children="Date Of Birth"
            />
          </Grid>
          <Grid theme={theme} item sm={9} xs={12}>
            {licenseLoading || !dataLoading ? (
              <Skeleton theme={theme} width={'10%'} height={'20px'} />
            ) : (
              <Typography
                theme={theme}
                variant="body2"
                component="h3"
                className="label-detail"
                children={utcToLocal(licenseInformation?.date_of_birth, 'MM-DD-YYYY') ?? '-'}
              />
            )}
          </Grid>
        </Grid>
        <NoPreviewImage>
          <Grid theme={theme} container columnSpacing={3} rowSpacing={4}>
            {licenseInformation?.front_image && (
              <Grid theme={theme} item md={4} sm={6} xs={12}>
                <Box theme={theme} className="image-card">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    children=""
                    sx={{ textAlign: 'center', mt: 3, mb: 1 }}
                  />
                  <Box
                    className={
                      licenseInformation?.front_image
                        ? 'image-box image-show'
                        : 'image-box image-hide'
                    }
                    theme={theme}
                  >
                    {!licenseInformation ? (
                      <Skeleton width={'100%'} height={274} theme={theme}></Skeleton>
                    ) : (
                      <ImgCard>
                        {licenseInformation?.front_image ? (
                          <Link
                            onClick={() => {
                              setDialogOpened(true)
                              setImage(frontImage)
                              setImageUrl(licenseInformation?.front_image)
                              setImageType("Driver's License Front")
                              setRotateImage(0)
                            }}
                            to=""
                          >
                            <img width={'100%'} height={'100%'} src={frontImage} alt="" />
                          </Link>
                        ) : (
                          <Box theme={theme} className="notImage">
                            <Icon name="DoubleImageIcon" />
                          </Box>
                        )}
                      </ImgCard>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
            {licenseInformation?.selfie_image && (
              <Grid theme={theme} item md={4} sm={6} xs={12}>
                <Box theme={theme} className="image-card">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    children=""
                    sx={{ textAlign: 'center', mt: 3, mb: 1 }}
                  />
                  <Box
                    className={
                      licenseInformation?.selfie_image
                        ? 'image-box image-show'
                        : 'image-box image-hide'
                    }
                    theme={theme}
                  >
                    {!licenseInformation ? (
                      <Skeleton width={'100%'} height={274} theme={theme}></Skeleton>
                    ) : (
                      <ImgCard>
                        {licenseInformation?.selfie_image ? (
                          <Link
                            onClick={() => {
                              setDialogOpened(true)
                              setImage(selfieImage)
                              setImageUrl(licenseInformation?.selfie_image)
                              setImageType('Selfie Image')
                              setRotateImage(0)
                            }}
                            to=""
                          >
                            <img width="100%" height="100%" src={selfieImage} alt="" />
                          </Link>
                        ) : (
                          <Box theme={theme} className="notImage">
                            <Icon name="DoubleImageIcon" />
                          </Box>
                        )}
                      </ImgCard>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
            {licenseInformation?.back_image && (
              <Grid theme={theme} item md={4} sm={6} xs={12}>
                <Box theme={theme} className="image-card">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    children={''}
                    sx={{ textAlign: 'center', mt: 3, mb: 1 }}
                  />
                  <Box
                    className={
                      licenseInformation?.back_image
                        ? 'image-box image-show'
                        : 'image-box image-hide'
                    }
                    theme={theme}
                  >
                    {!licenseInformation ? (
                      <Skeleton width={'100%'} height={274} theme={theme}></Skeleton>
                    ) : (
                      <ImgCard>
                        {licenseInformation?.back_image ? (
                          <Link
                            onClick={() => {
                              setDialogOpened(true)
                              setImage(backImage)
                              setImageUrl(licenseInformation?.back_image)
                              setImageType("Driver's License Back")
                              setRotateImage(0)
                            }}
                            to=""
                          >
                            <img width="100%" height="100%" src={backImage} alt="" />
                          </Link>
                        ) : (
                          <Box theme={theme} className="notImage">
                            <Icon name="DoubleImageIcon" />
                          </Box>
                        )}
                      </ImgCard>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </NoPreviewImage>
      </DetailCard>
      <Dialog
        disablePortal={false}
        theme={theme}
        size="lg"
        title={image_type}
        open={dialogueOpen}
        onCloseDialog={() => setDialogOpened(false)}
        customFooter={
          <>
            <Grid theme={theme} container spacing={2}>
              <Grid theme={theme} item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  theme={theme}
                  className="btn-rotate"
                  text="Rotate"
                  secondary
                  onClick={() => {
                    setRotateImage(-90 + rotateImage)
                    setToggleImageClass(!toggleImageClass)
                  }}
                  startIcon={<Icon name="ResetIcon" />}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  text="Download"
                  onClick={() => HANDLE_LICENCE_IMAGE_DOWNLOAD(image_url, image_type)}
                  startIcon={<Icon name="SaveIcon" />}
                  disabled={isImageDownloading}
                  endIcon={isImageDownloading && <img src={DisableLoader} alt="Loader" />}
                />
              </Grid>
            </Grid>
          </>
        }
      >
        <ImageRotate theme={theme}>
          <Box theme={theme} className={`thumbnail ${toggleImageClass && 'max'}`}>
            <img
              className={'image-response'}
              style={{ rotate: `${rotateImage}deg` }}
              src={image}
              alt=""
            />
          </Box>
        </ImageRotate>
      </Dialog>
    </>
  )
}
export default OrderLicenseInformation
