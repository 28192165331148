import { DMS_GET_LEADS } from '@helpers/endpoints'
import Http from '@helpers/http-api'
import { useQuery } from 'react-query'

export const useGetLeadDetails = (refId: string) => {
  return useQuery<any, Error>(
    ['leadDetails', refId],
    () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${DMS_GET_LEADS}/${refId}`)
    },
    {
      enabled: !!refId
    }
  )
}
