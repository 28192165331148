import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const TableListWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.table-list-wrap': {
    ul: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      li: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        borderBottom: '1px solid' + theme.palette.grey[50],
        padding: '8px 0',
        whiteSpace: 'nowrap',
        ':last-child': {
          borderBottom: 0
        },
        '.MuiTypography-root': {
          maxWidth: '100%',
          overflow: 'hidden',
          ':first-child': {
            flex: '1',
            overflow: 'visible',
            textAlign: 'start'
          }
        }
      }
    },
    '&.with-bg': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      padding: '0 12px'
    }
  }
}))
