import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ScrollableTabsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.scrollable-tabs-wrap': {
    '&.sm': {
      '.u-scrollable-tabs': {
        '.u-tab-btn': {
          marginRight: '20px',
          fontSize: theme.typography.subtitle2.fontSize,
          padding: '8px 0'
        },
        '.u-tab-panel': {
          padding: '8px 0'
        }
      }
    }
  }
}))
