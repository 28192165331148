import { FC, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  Grid,
  Input,
  Select,
  FileDragDrop,
  Textarea,
  Tooltip,
  Dialog,
  Button,
  FilePreview,
  CircleLoader,
  ImageViewer
} from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { validation, getTenantIdFromLocalStorage } from '@helpers/methods'
import {
  UseQuery_GetAllOptionCategorys,
  UseMutation_AddAssetCondition,
  useSaveAddOn
} from '@apis/configurations.service'
import {
  useMutation_CheckS3Credentials,
  UseMutation_uploadS3Image,
  UseMutation_deleteS3Image
} from '@apis/image-upload.service'
import { InstallationMode, ActionType, ToastMessages } from '@helpers/enums'
import { WEBSITE_REGEX } from '@helpers/constants'
import { useStoreContext } from '@store/storeContext'
import { IImageResponse } from '@models'
import { Divider } from '@components'

const ImgLoader = styled(Box)(() => ({
  '.loader-wrap': {
    '.loader': {
      position: 'relative !important',
      transform: 'initial !important'
    }
  }
}))

const AddOnPopup: FC<{
  defaultCurrency?: string
  setOpenDialog?: any
  setOpenAddOnDialog?: any
  callBack: any
  setAddedItems: any
  addedItems: any
  setFinalSumArray: any
  totalSumArray: any
  setTotalSumArray: any
  setPopupAddedItems: any
}> = ({
  defaultCurrency,
  setOpenAddOnDialog,
  setOpenDialog,
  callBack,
  setAddedItems,
  addedItems,
  setFinalSumArray,
  totalSumArray,
  setTotalSumArray,
  setPopupAddedItems
}) => {
  const { actions, states } = useStoreContext()
  const theme = useTheme()
  const tenant_id = getTenantIdFromLocalStorage()
  const [images, setImages] = useState<Array<IImageResponse>>([])
  const [imageLoader, setImageLoader] = useState<boolean>(false)
  const [sliderImages, setSilderImages] = useState<any>([])
  const [visibility, setVisibility] = useState(false)
  const [index, setIndex] = useState(0)

  const { data: optionCategories } = UseQuery_GetAllOptionCategorys()
  const { data: AssetCondition } = UseMutation_AddAssetCondition()
  const { mutate: checkS3Credentials, data: checkS3 } = useMutation_CheckS3Credentials()
  const { mutate: uploadImage } = UseMutation_uploadS3Image()
  const { mutate: deleteImage } = UseMutation_deleteS3Image()
  const { mutate: SaveAddOn } = useSaveAddOn()

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors }
  } = useForm()

  const handleCloseImageView = () => setVisibility(false)
  const handleImageUpload = (file: File) => {
    setImageLoader(true)
    const obj: any = {
      tenant_id: tenant_id
    }
    const body = new FormData()
    body.append('files', file)
    obj.files = body
    uploadImage(obj, {
      onSuccess(response: any) {
        setImageLoader(false)
        clearErrors('image')
        response?.map((img: IImageResponse) => {
          img.size = file.size.toString()
          img.name = file.name
          setImages([...images, img])
        })
      }
    })
  }

  const onImageUpload = (e: any) => {
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      handleImageUpload(file)
    }
  }

  const onImageDrop = (e: any) => {
    e.preventDefault()
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0]
      handleImageUpload(file)
    }
  }

  const onRemoveFile = (fileKey: string, index: number) => {
    if (fileKey) {
      const obj: any = {
        fileKey: fileKey,
        tenant_id: tenant_id
      }
      setImageLoader(true)
      deleteImage(obj, {
        onSuccess() {
          const imageArray = images
          imageArray.splice(index, 1)
          setImages([...imageArray])
          setImageLoader(false)
        }
      })
    }
  }

  const onClickIcon = (indexNumber: number) => {
    const image_url =
      images?.map((image) => {
        return image?.location
      }) || []
    setIndex(indexNumber)
    setSilderImages(image_url)
    setVisibility(true)
  }

  useEffect(() => {
    checkS3Credentials(tenant_id)
  }, [])

  const handleClose = () => {
    setOpenAddOnDialog(false)
    setOpenDialog(false)
  }

  const onSubmit = (data: any) => {
    SaveAddOn(
      {
        ...data,
        offered_by: 'Dealer',
        rv_adder: 0.0,
        image: images,
        company_id: states?.dealerAndLenderInfo?.company_id,
        compatible_models: [states?.makeModelTrim?.asset_trim_id],
        dealer_code: states?.dealerAndLenderInfo?.dealer_code
      },
      {
        onSuccess(response: any) {
          actions.setToast({
            toastMessage: ToastMessages.Record_Added_Success,
            toastState: true
          })
          setPopupAddedItems([...addedItems, response.id])
          setFinalSumArray([...totalSumArray, response])
          setTotalSumArray([...totalSumArray, response])
          setOpenAddOnDialog(false)
          setOpenDialog(false)
          if (!addedItems.includes(response.id)) {
            setAddedItems([...addedItems, response.id])
            callBack('add', response)
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  return (
    <>
      <Dialog
        theme={theme}
        disablePortal
        title="Create New Option"
        open={true}
        onCloseDialog={handleClose}
        children={
          <>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
              <Grid item theme={theme} xs={12} sm={12}>
                <Controller
                  name="product_name"
                  control={control}
                  defaultValue={''}
                  rules={validation('Product Name', true)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Product Name'}
                      type="text"
                      id="drawerInputProductName"
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                      error={errors?.product_name?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={validation('Description', true)}
                  render={({ field }) => (
                    <Textarea
                      theme={theme}
                      label={'Description'}
                      type="text"
                      id="drawerTextDescription"
                      rows={3}
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                      error={errors?.description?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="category_id"
                  control={control}
                  rules={validation('Category', true)}
                  render={({ field }) => (
                    <Select
                      id="drawerSelectCategoryId"
                      theme={theme}
                      label={'Category'}
                      items={optionCategories
                        ?.filter((x: any) => x.is_active || x.id == field.value)
                        ?.map((category: any) => {
                          return {
                            value: category.id,
                            text: category.description
                          }
                        })}
                      {...field}
                      value={field.value}
                      sxProps={''}
                      selectError={errors?.category_id?.message as never}
                      onChange={field.onChange}
                    ></Select>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="vehicle_type"
                  control={control}
                  defaultValue={''}
                  rules={validation('Asset Condition', true)}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      id="drawerSelectVehicleType"
                      theme={theme}
                      label={'Asset Condition'}
                      value={value}
                      items={AssetCondition?.filter((x: any) => x.is_active || x.id == value)?.map(
                        (asset: any) => ({
                          value: asset.id,
                          text: `${asset.description}`
                        })
                      )}
                      sxProps={''}
                      onChange={onChange}
                      selectError={errors?.vehicle_type?.message as never}
                    ></Select>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="part_no"
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Part No (Optional)'}
                      type="text"
                      id="drawerInputPartNo"
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="supplier"
                  control={control}
                  defaultValue={''}
                  rules={validation('supplier', true)}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Supplier'}
                      type="text"
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                      error={errors?.supplier?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="installation_mode"
                  control={control}
                  defaultValue={'Optional'}
                  rules={validation('Installed Mode', true)}
                  render={({ field }) => (
                    <Select
                      disabled={true}
                      id="drawerSelectInstallationMode"
                      theme={theme}
                      label={'Installed Mode'}
                      items={Object.entries(InstallationMode).map((item) => ({
                        text: item[1],
                        value: item[1]
                      }))}
                      {...field}
                      value={field.value}
                      sxProps={''}
                      onChange={field.onChange}
                    ></Select>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="price"
                  control={control}
                  rules={validation('Price', true)}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      label={'Price'}
                      type="decimal"
                      startAdornment={
                        <>
                          <span className="adornment-text">{defaultCurrency}</span>
                        </>
                      }
                      maskDecimalScale={2}
                      maskNumeric
                      masking
                      {...field}
                      error={errors?.price?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name={'compatible_models'}
                  defaultValue={states?.orderData?.selected_vehicle?.trim_description}
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      placeholder={'Compatible Assets'}
                      label={'Compatible Assets'}
                      type="text"
                      {...field}
                      disabled={true}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="brochure_url"
                  control={control}
                  rules={validation(
                    'Brochure Url',
                    false,
                    true,
                    WEBSITE_REGEX,
                    'Valid URL is required.'
                  )}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Brochure URL (Optional)'}
                      type="text"
                      id="drawerBrochureUrl"
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                      error={errors?.brochure_url?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="video_url"
                  control={control}
                  rules={validation(
                    'Video Url',
                    false,
                    true,
                    WEBSITE_REGEX,
                    'Valid URL is required.'
                  )}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      label={'Video URL (Optional)'}
                      type="text"
                      id="drawerVideoUrl"
                      fullWidth
                      {...field}
                      value={field.value?.trimStart()}
                      error={errors?.video_url?.message?.toString()}
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="is_active"
                  control={control}
                  defaultValue={true}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      id="drawerSelect"
                      theme={theme}
                      label={'Status'}
                      defaultValue={true}
                      items={[
                        { text: 'Enabled', value: true },
                        { text: 'Disabled', value: false }
                      ]}
                      value={value}
                      sxProps={''}
                      onChange={onChange}
                    ></Select>
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => (
                    <Tooltip
                      theme={theme}
                      title={!checkS3 ? ActionType.STORAGE_PATH_NOT_CONFIGURED : ''}
                    >
                      <FileDragDrop
                        {...field}
                        theme={theme}
                        width="300"
                        height="200px"
                        backgroundColor="white"
                        onDrop={onImageDrop}
                        onChange={onImageUpload}
                        allowMultiple={true}
                        disabled={!checkS3}
                      />
                    </Tooltip>
                  )}
                />
                <FilePreview
                  files={images?.length > 0 ? images : []}
                  onRemoveFile={onRemoveFile}
                  viewIcon={true}
                  theme={theme}
                  onClickIcon={onClickIcon}
                  preview="image"
                />
                {imageLoader && (
                  <ImgLoader theme={theme} sx={{ mb: 1, mt: 3 }}>
                    <CircleLoader theme={theme} size="xs" />
                  </ImgLoader>
                )}
              </Grid>
              <ImageViewer
                theme={theme}
                sliderImagesWithVisible={true}
                sliderImages={sliderImages}
                isLoading={false}
                visible={visibility}
                onClose={handleCloseImageView}
                overlayCheck={true}
                index={index}
                setIndex={setIndex}
              />
            </Grid>
            <Divider sx={{ mt: 4 }} />
          </>
        }
        customFooter={
          <>
            <Button theme={theme} primary text="Save Option" onClick={handleSubmit(onSubmit)} />
          </>
        }
      />
    </>
  )
}

export default AddOnPopup
