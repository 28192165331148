import React, { FC } from 'react'
import { Link, useTheme } from '@mui/material'
import { Dialog, Box, Grid, Button, Icon, Typography, Input, Select } from '@ntpkunity/controls'
import { BtnAddWrap, VehicleList } from '@components'

const AddVtc: FC = () => {
  const theme = useTheme()

  const [openVehicleDialog, setVehicleOpenDialog] = React.useState(false)
  const handleVehicleOpen = () => {
    setVehicleOpenDialog(true)
  }
  const handleVehicleClose = () => {
    setVehicleOpenDialog(false)
  }

  const [openTradeInDialog, setTradeInOpenDialog] = React.useState(false)
  const handleTradeInOpen = () => {
    setTradeInOpenDialog(true)
  }
  const handleTradeInClose = () => {
    setTradeInOpenDialog(false)
  }

  const [openCustomerDialog, setCustomerOpenDialog] = React.useState(false)
  const handleCustomerOpen = () => {
    setCustomerOpenDialog(true)
  }
  const handleCustomerClose = () => {
    setCustomerOpenDialog(false)
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <VehicleList theme={theme} className="vehicle-list">
          <Grid theme={theme} container columnSpacing={1}>
            <Grid theme={theme} item xs={4}>
              <Button
                theme={theme}
                fullWidth
                startIcon={<Icon name="AddCircleIcon" />}
                text="Add Vehicle"
                size="large"
                onClick={handleVehicleOpen}
              />
              <Box theme={theme} className="vl-item">
                <Box theme={theme} className="vl-img-wrap">
                  <img src={require('../../public/assets/images/vc-img.png')} alt="Car" />
                </Box>
                <Box theme={theme} className="vl-details">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className="text-primary text-overflow vl-main-title"
                    children={<b>Vehicle</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className=" text-overflow"
                    children={<b>2024 BMW 4 Series i4 eDrive 35</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="p"
                    className=" text-overflow vl-title-ul"
                    children={
                      <>
                        VIN: <b>3478288875124567,</b> Stock: <b>283459353</b>
                      </>
                    }
                  />
                </Box>
              </Box>
              <Dialog
                variant={undefined}
                size="md"
                title="Add Vehicle"
                open={openVehicleDialog}
                onCloseDialog={handleVehicleClose}
                customFooter={
                  <Button theme={theme} primary text="Add Vehicle" onClick={handleVehicleClose} />
                }
                theme={theme}
                children={'Vehicle List'}
              />
            </Grid>
            {/* ********************************* */}
            <Grid theme={theme} item xs={4}>
              <Button
                theme={theme}
                fullWidth
                startIcon={<Icon name="AddCircleIcon" />}
                text="Add Trade-In"
                size="large"
                onClick={handleTradeInOpen}
              />
              <Box theme={theme} className="vl-item">
                <Box theme={theme} className="vl-img-wrap">
                  <img src={require('../../public/assets/images/vc-img.png')} alt="Car" />
                </Box>
                <Box theme={theme} className="vl-details">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className="text-primary text-overflow vl-main-title"
                    children={<b>Trade-In</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className="text-overflow"
                    children={<b>2024 BMW 4 Series i4 eDrive 35</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="p"
                    className=" text-overflow vl-title-ul"
                    children={
                      <>
                        Trade Value: <b>$00,000,</b> Payoff: <b>$0,000,</b> Miles: <b>0.5</b>
                      </>
                    }
                  />
                </Box>
              </Box>
              <Dialog
                variant={undefined}
                size="md"
                title="Add Trade-In"
                open={openTradeInDialog}
                onCloseDialog={handleTradeInClose}
                customFooter={
                  <Button theme={theme} primary text="Add Trade-In" onClick={handleTradeInClose} />
                }
                theme={theme}
                children={'Trade-In Form'}
              />
            </Grid>
            {/* ********************************* */}
            <Grid theme={theme} item xs={4}>
              <Button
                theme={theme}
                fullWidth
                startIcon={<Icon name="AddCircleIcon" />}
                text="Add Customer"
                size="large"
                onClick={handleCustomerOpen}
              />
              <Box theme={theme} className="vl-item">
                <Box theme={theme} className="vl-details">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className="text-primary text-overflow vl-main-title"
                    children={<b>Customer</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className="text-overflow"
                    children={<b>sample@sample.com</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="p"
                    className=" text-overflow vl-title-ul"
                    children={
                      <>
                        Mobile: <b>000-000-0000,</b>
                        Credit: <b>000-000</b>
                      </>
                    }
                  />
                </Box>
              </Box>
              <Dialog
                variant={undefined}
                size="sm"
                title="Enter Customer’s Details"
                open={openCustomerDialog}
                onCloseDialog={handleCustomerClose}
                theme={theme}
                children={
                  <>
                    <Input type={'email'} fullWidth theme={theme} label="Email Address" />
                    <Select
                      theme={theme}
                      fullWidth
                      disablePortal={false}
                      label="Preferred Communication"
                      items={[
                        { text: 'Option 1', value: '1', disabled: true },
                        { text: 'Option 2', value: '2' },
                        { text: 'Option 3', value: '3' }
                      ]}
                    />
                    <Input type={'number'} fullWidth theme={theme} label="Mobile Phone Number" />
                    <Select
                      theme={theme}
                      fullWidth
                      disablePortal={false}
                      label="Credit Score"
                      items={[
                        { text: 'Option 1', value: '1', disabled: true },
                        { text: 'Option 2', value: '2' },
                        { text: 'Option 3', value: '3' }
                      ]}
                    />
                    <Box theme={theme}>
                      <Typography
                        theme={theme}
                        variant="caption"
                        component="span"
                        display={'block'}
                        className="text-muted"
                      >
                        <span>
                          By continuing, you agree to the{' '}
                          <Link href="javascript:void(0)" className="link hover-underline">
                            {' '}
                            terms and conditions
                          </Link>{' '}
                          to the{' '}
                          <Link href="javascript:void(0)" className="link hover-underline">
                            TCPA disclosure
                          </Link>{' '}
                          and to receive marketing material related to product(s).
                        </span>
                      </Typography>
                    </Box>
                  </>
                }
                customFooter={
                  <>
                    <Button theme={theme} secondary text="Cancel" onClick={handleCustomerClose} />
                    <Button
                      theme={theme}
                      primary
                      text="Save Changes"
                      onClick={handleCustomerClose}
                    />
                  </>
                }
              />
            </Grid>
          </Grid>
        </VehicleList>
      </BtnAddWrap>
    </>
  )
}

export default AddVtc
