import { IBoxProps, Box } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import { lightTheme } from '@styles/theme'

export const BtnAddWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.btn-add-wrap': {
    '.btn': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      fontSize: theme.typography.caption.fontSize,

      '&:before': {
        backgroundColor: lightTheme.palette.primary.main,
        display: 'inline-block',
        content: "''",
        position: 'absolute',
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        opacity: 0.08
      },

      '&.MuiButton-sizeLarge': {
        height: 80,
        '&:before': {
          height: 80
        }
      },

      '&.MuiButton-sizeMedium': {
        height: 56,
        '&:before': {
          height: 56
        }
      },

      svg: {
        height: 24,

        path: {
          stroke: theme.palette.primary.main
        }
      },

      '&.btn-vertical': {
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
        height: 111,
        '&:before': {
          left: 0,
          top: 0,
          height: 111
        },
        '.MuiButton-startIcon': {
          display: 'block',
          margin: 0
        }
      }
    }
  }
}))
