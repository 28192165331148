import React, { FC, useEffect, useState } from 'react'
import { InputAdornment, useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Button, Grid, Box, Input, Typography, DuoTab, Icon, Dialog } from '@ntpkunity/controls'
import { Divider, PriceList } from 'components'
import { useStoreContext } from '@store/storeContext'
import { styled } from '@mui/material/styles'
import { OptionsListPopup } from '../popup-screens'
import { useGetTrimCode } from '@apis/dealer-addon.service'
import AddOnPopup from './../popup-screens/add-on-popup'

const StickyDuoTab = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  '.tab-list-underline': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    left: 0,
    zIndex: 1,
    width: '75%'
  },
  '.TabPanelUnstyled-root': {
    overflow: 'hidden',
    paddingTop: 16,
    marginTop: -16
  },
  '.search': {
    width: '25%',
    display: 'inline-block',
    borderBottom: '1px solid' + theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: -60,
    right: 0,
    zIndex: 1,
    float: 'right',
    paddingLeft: 24,
    paddingTop: 8,
    '.MuiOutlinedInput-notchedOutline': {
      border: '0 solid transparent',
      borderRadius: 0
    },
    '.Mui-focused fieldset': {
      border: '0 solid transparent'
    },
    '.MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
      zIndex: -1,
      opacity: 0.75
    },
    '.MuiOutlinedInput-input': {
      paddingRight: '15px !important'
    }
  }
}))
const Total = styled(Box)(({ theme }) => ({
  '&.total': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    left: 0,
    bottom: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    zIndex: 100,
    '.primary': {
      color: theme.palette.primary.main
    },
    hr: {
      opacity: 0.4,
      marginBottom: 0
    }
  }
}))
const OrderOptions: FC<{
  selectedObjectsArray?: any
  defaultOptions: any
  isDisable?: boolean
  defaultCurrency?: string
  setOpenAddOnDialog: any
  openAddOnDialog: any
}> = ({
  selectedObjectsArray,
  defaultOptions,
  isDisable,
  defaultCurrency,
  setOpenAddOnDialog,
  openAddOnDialog
}: any) => {
  const theme = useTheme()
  const [openDialog, setOpenDialog] = useState(false)
  const [totalSumArray, setTotalSumArray] = useState<any>([])
  const [addedItems, setAddedItems] = useState<any>([])
  const [finalSumArray, setFinalSumArray] = useState<any>([])
  const [popupAddedItems, setPopupAddedItems] = useState<any>([])
  const { states } = useStoreContext()
  const { data: dealerOptions } = useGetTrimCode(
    states?.dealerAndLenderInfo?.dealer_code,
    states?.orderData?.selected_vehicle?.model_code
  )

  const handleOpen = () => {
    setOpenDialog(true)
  }
  useEffect(() => {
    if (defaultOptions) {
      const data = defaultOptions?.filter(
        (f: any) => !addedItems.find((e: any) => e === (f.option_id ?? f.id))
      )
      setAddedItems([...data.map((e: any) => e.option_id ?? e.id)])
      setPopupAddedItems([...data.map((e: any) => e.option_id ?? e.id)])
      setTotalSumArray([...defaultOptions])
      setFinalSumArray([...defaultOptions])
      selectedObjectsArray([...defaultOptions])
    }
  }, [defaultOptions])

  const handleClose = () => {
    setPopupAddedItems(addedItems)
    setTotalSumArray(finalSumArray)
    setOpenDialog(false)
    setOpenAddOnDialog(false)
  }
  interface IFormInputs {
    search: string
    text: string
  }
  const { control } = useForm<IFormInputs>()
  const preInstalledOptions = dealerOptions?.filter((item: any) => {
    return item.category_name
      ? item.installation_mode === 'Pre-Installed' && item.is_active === true
      : null
  })

  const handleDone = () => {
    setAddedItems(popupAddedItems)
    setFinalSumArray(totalSumArray)
    setOpenDialog(false)
  }

  const handleNewAddOn = () => {
    setOpenDialog(false)
    setOpenAddOnDialog(true)
  }

  const callBack = (type: string, data: any) => {
    if (type === 'add') {
      setTotalSumArray([...totalSumArray, data])
      selectedObjectsArray([...totalSumArray, data])
    } else if (type === 'remove') {
      const newArray = totalSumArray?.filter(
        (obj: any) => (obj.option_id ?? obj.id) !== (data.option_id ?? data.id)
      )
      setTotalSumArray([...newArray])
      selectedObjectsArray([...newArray])
    }
  }

  let totalFinalSum =
    finalSumArray && finalSumArray.length > 0
      ? finalSumArray.reduce(function (lastSum: any, obj: any) {
          if (preInstalledOptions && preInstalledOptions.length > 0) {
            const matchingOption = preInstalledOptions.find(
              (preInstalledOption: any) => preInstalledOption.id === obj.option_id
            )
            const matchingOption2 = preInstalledOptions.find(
              (preInstalledOption: any) => preInstalledOption.id === obj.id
            )
            if (matchingOption) {
              if (obj.is_price_inclusive === true) {
                obj.applied_price = 0
              }
            }
            if (matchingOption2) {
              obj.applied_price = matchingOption2?.price
            }
          }
          return lastSum + (obj.applied_price ?? obj.price)
        }, 0)
      : 0

  let totalSum =
    totalSumArray && totalSumArray.length > 0
      ? totalSumArray.reduce(function (lastSum: any, obj: any) {
          if (preInstalledOptions && preInstalledOptions.length > 0) {
            const matchingOption = preInstalledOptions.find(
              (preInstalledOption: any) => preInstalledOption.id === obj.id
            )
            if (matchingOption) {
              if (obj.is_price_inclusive === true) {
                obj.applied_price = 0
              } else {
                obj.applied_price = matchingOption?.applied_price
              }
            }
          }
          return lastSum + (obj?.applied_price ?? obj?.price)
        }, 0)
      : 0

  const selectedArray = [
    ...Array.from(
      new Set(
        dealerOptions
          ?.filter((x: any) => x.is_active == true)
          ?.map((item: any) => item.category_name)
      )
    )
  ]
  const preinstalled = [
    {
      title: 'Pre-Installed',
      content: (
        <OptionsListPopup
          callBack={callBack}
          data={preInstalledOptions}
          addedItems={popupAddedItems}
          setAddedItems={setPopupAddedItems}
          preInstalledOptions={preInstalledOptions}
        />
      )
    }
  ]
  const categories_data = selectedArray?.map((result: any) => {
    {
      return {
        title: result,
        content: (
          <OptionsListPopup
            callBack={callBack}
            data={dealerOptions?.filter((item: any) => {
              return item.category_name
                ? item.category_name == result &&
                    item.installation_mode !== 'Pre-Installed' &&
                    item.is_active === true
                : null
            })}
            addedItems={popupAddedItems}
            setAddedItems={setPopupAddedItems}
          />
        )
      }
    }
  })
  const tabs = preinstalled.concat(categories_data)
  return (
    <>
      {(dealerOptions ?? []).length > 0 ? (
        <>
          <Typography theme={theme} variant="h4" component="h4" sx={{ mb: 2.5 }}>
            Options
          </Typography>
          <PriceList className="price-list">
            {finalSumArray?.map((obj: any, index: any) => (
              <Box theme={theme} key={index}>
                <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                  <Grid theme={theme} item xs={8}>
                    {obj?.product_name}
                  </Grid>
                  <Grid theme={theme} item xs={4} textAlign="right">
                    {obj
                      ? `${defaultCurrency}${parseFloat(
                          obj.price || obj.applied_price || 0
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : ''}
                  </Grid>
                </Grid>
                {obj?.description && (
                  <Box theme={theme} sx={{ mb: 2 }}>
                    <small>{obj?.description}</small>
                  </Box>
                )}
              </Box>
            ))}
            {totalFinalSum === 0.0 ? (
              ''
            ) : (
              <>
                <Box theme={theme} sx={{ mb: 2 }}>
                  <Divider />
                </Box>
                <Grid theme={theme} container columnSpacing={1} sx={{ mb: 2 }}>
                  <Grid theme={theme} item xs={8}>
                    <b>Total</b>
                  </Grid>
                  <Grid theme={theme} item xs={4} textAlign="right">
                    <b className="total">
                      {totalFinalSum && totalFinalSum
                        ? `${defaultCurrency}${parseFloat(totalFinalSum).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}`
                        : `${defaultCurrency}0.00`}
                    </b>
                  </Grid>
                </Grid>
              </>
            )}
          </PriceList>
          <Box theme={theme} sx={{ mb: 4 }}>
            <Button
              theme={theme}
              secondary
              text="Add Options"
              fullWidth
              onClick={handleOpen}
              disabled={isDisable}
            />
          </Box>
          <Box theme={theme} sx={{ mb: 4 }}>
            <Divider />
          </Box>
          {openAddOnDialog ? (
            <>
              <AddOnPopup
                defaultCurrency={defaultCurrency}
                callBack={callBack}
                setOpenAddOnDialog={setOpenAddOnDialog}
                setOpenDialog={setOpenDialog}
                addedItems={popupAddedItems}
                setAddedItems={setAddedItems}
                totalSumArray={totalSumArray}
                setFinalSumArray={setFinalSumArray}
                setTotalSumArray={setTotalSumArray}
                setPopupAddedItems={setPopupAddedItems}
              />
            </>
          ) : (
            <Dialog
              disablePortal
              variant={undefined}
              size="xl"
              title="Options"
              open={openDialog}
              onCloseDialog={handleClose}
              customFooter={
                <>
                  <Grid theme={theme} container columnSpacing={2}>
                    <Grid theme={theme} item xs={6} textAlign="right">
                      <Button
                        theme={theme}
                        secondary
                        text="Create New Option "
                        onClick={handleNewAddOn}
                      />
                    </Grid>
                    <Grid theme={theme} item xs={6}>
                      <Button theme={theme} primary text="Continue" onClick={handleDone} />
                    </Grid>
                  </Grid>
                </>
              }
              theme={theme}
              children={
                <>
                  <StickyDuoTab theme={theme}>
                    <Box theme={theme} className="search">
                      <Controller
                        name="search"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <Input
                            theme={theme}
                            fullWidth
                            type="text"
                            placeholder="Search.."
                            endAdornment={
                              <InputAdornment position="end">
                                <Icon name="SearchIcon" />
                              </InputAdornment>
                            }
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <DuoTab theme={theme} varient={'underline'} items={tabs} defaultTabIndex={0} />
                  </StickyDuoTab>
                  <Total theme={theme} className="total" sx={{ pt: 2 }}>
                    <Grid theme={theme} spacing={1} container>
                      <Grid theme={theme} item xs={6}>
                        Total
                      </Grid>
                      <Grid theme={theme} item xs={6} className="primary" textAlign="right">
                        {totalSum && totalSum
                          ? `${defaultCurrency}${parseFloat(totalSum).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}`
                          : `${defaultCurrency}0.00`}
                      </Grid>
                      <Grid theme={theme} item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Total>
                </>
              }
            />
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default React.memo(OrderOptions)
