import { FC, useEffect, useState } from 'react'

import { useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { useGetRating } from '@apis/configurations.service'
import { Status } from '@helpers/enums'

const OrderDetails: FC<{ isDraftOrder?: Boolean; order_detail: any }> = ({
  isDraftOrder = false,
  order_detail
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()

  const { mutate: getCreditRating } = useGetRating()
  const [creditRatingData, setCreditRatingData] = useState<any>({})

  useEffect(() => {
    if (
      !(states?.creditRatingData.length > 0) &&
      states?.dealerAndLenderInfo?.company_id &&
      order_detail
    ) {
      getCreditRating(
        { company_id: states?.dealerAndLenderInfo?.company_id },
        {
          onSuccess(response: any) {
            setCreditRatingData(
              response?.filter(
                (rating: any) => rating.description == order_detail?.credit_rating
              )[0]
            )
          }
        }
      )
    } else {
      setCreditRatingData(
        states?.creditRatingData?.filter(
          (rating: any) => rating.description == order_detail?.credit_rating
        )[0]
      )
    }
  }, [states?.dealerAndLenderInfo?.company_id, order_detail])

  return (
    <DetailCard className="detail-card" mb={2}>
      <Typography theme={theme} component="h4" variant="h4" mb={2}>
        Order Details
      </Typography>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Contract Terms
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="span">
            {order_detail?.contract_term ? order_detail?.contract_term : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Product Type
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {isDraftOrder ? '-' : order_detail?.finance_type ? order_detail?.finance_type : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Credit Score
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {creditRatingData
              ? creditRatingData?.description +
                ' (' +
                creditRatingData?.score_from +
                ' - ' +
                creditRatingData?.score_to +
                ')'
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Submitted To
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {order_detail?.meta_data?.integration_type ?? '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            DMS
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {order_detail?.meta_data?.provider_name
              ? `${order_detail?.meta_data?.provider_name} ${
                  order_detail?.meta_data?.success ? '(Successful)' : '(Unsuccessful)'
                }`
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      {(order_detail?.status == Status.Cancelled ||
        order_detail?.status == Status.Declined ||
        order_detail?.status == Status.Withdrawn) && (
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label">
              Cancellation Reason
            </Typography>
          </Grid>
          <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
            <Typography theme={theme} variant="body2" component="h3" className="label-detail">
              {order_detail?.reason}
            </Typography>
          </Grid>
        </Grid>
      )}
    </DetailCard>
  )
}

export default OrderDetails
