import { FC, useState, useEffect } from 'react'
import { TableCell, TableRow, ToggleButton, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
import { Box, Button, DataTable, Icon, Input, Typography, CircleLoader } from '@ntpkunity/controls'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
} from 'chart.js'
import { DataTableWrap } from '@components'
import { ToggleCollapse } from '@styles/components'
import { useGetLeadDetails } from '@apis/lead-management.service'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'
import { LeadData } from 'models/lead-data.interface'
import { useStoreContext } from '@store/storeContext'

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Title,
  Legend
)

const ChildRow: FC<{ data: LeadData; vehicleIndex: number }> = ({ data }) => {
  const vehicleDetails = data?.data?.vehicle_details[0]
  const customerDetails = data?.data?.customer_details
  const navigate = useNavigate()
  const handleViewDetails = (id: number) => {
    navigate(`${APP_ROUTES.LEAD_SUMMARY}/${id}`)
  }
  const getStatusClass = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'green'
      case 'abandoned':
        return 'red'
      case 'active':
        return 'blue'
      default:
        return ''
    }
  }

  return (
    <TableRow className="child-tr">
      <TableCell></TableCell>
      <TableCell>
        {vehicleDetails?.year &&
        vehicleDetails?.make &&
        vehicleDetails?.model &&
        vehicleDetails?.trim_description
          ? `${vehicleDetails.year} ${vehicleDetails?.make} ${vehicleDetails?.model} ${vehicleDetails?.trim_description}`
          : '-'}
      </TableCell>
      <TableCell>
        <span className={`status ${getStatusClass(vehicleDetails?.status)}`}></span>
        {vehicleDetails?.status || '-'}
      </TableCell>
      <TableCell>{vehicleDetails?.id || '-'}</TableCell>
      <TableCell>{customerDetails?.first_name || '-'}</TableCell>
      <TableCell>{customerDetails?.last_name || '-'}</TableCell>
      <TableCell>{customerDetails?.mobile_number || '-'}</TableCell>
      <TableCell>{customerDetails?.preferred_contact || '-'}</TableCell>
      <TableCell>{vehicleDetails?.vin || '-'}</TableCell>
      <TableCell>{vehicleDetails?.stock_number || '-'}</TableCell>
      <TableCell>{data?.created_at || '-'}</TableCell>
      <TableCell>{data?.updated_at || '-'}</TableCell>
      <TableCell>{data?.last_sync_date || '-'}</TableCell>
      <TableCell>{vehicleDetails?.lead_source || '-'}</TableCell>
      <TableCell>
        <span
          onClick={() => handleViewDetails(vehicleDetails?.id)}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          View Details
        </span>
      </TableCell>
    </TableRow>
  )
}

const MainRow: FC<{ data: LeadData }> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()
  const customerDetails = data?.data?.customer_details
  const vehicleDetails = data?.data?.vehicle_details || []

  return (
    <>
      {!data || Object.keys(data).length === 0 ? (
        <div>No data available</div>
      ) : (
        <>
          {customerDetails?.email && (
            <TableRow className="child-tr">
              <TableCell className="indent-cell" colSpan={15}>
                <Box theme={theme} display={'flex'}>
                  <ToggleCollapse theme={theme} className="toggle-collapse right-bottom" mr={1}>
                    <ToggleButton
                      value="check"
                      selected={isOpen}
                      onChange={() => setIsOpen(!isOpen)}
                    >
                      <Icon name="ChevronDown" />
                    </ToggleButton>
                  </ToggleCollapse>
                  {customerDetails?.email}
                </Box>
              </TableCell>
            </TableRow>
          )}
          {isOpen &&
            customerDetails &&
            Object.keys(customerDetails).length > 0 &&
            vehicleDetails.length > 0 && (
              <>
                {vehicleDetails.map((vehicle, index) => (
                  <ChildRow
                    key={index}
                    data={{ ...data, data: { ...data.data, vehicle_details: [vehicle] } }}
                    vehicleIndex={index}
                  />
                ))}
              </>
            )}
        </>
      )}
    </>
  )
}

const CustomerJourneyAnalytics: FC = () => {
  const theme = useTheme()
  const [apiData, setApiData] = useState<LeadData[] | null>(null)
  const { states } = useStoreContext()
  const { data, isLoading, error } = useGetLeadDetails(states?.dealerAndLenderInfo?.id)

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setApiData(data)
    } else {
      setApiData(null)
    }
  }, [data])
  var renderDataTable = false
  if (isLoading && renderDataTable) return <CircleLoader theme={theme} />
  if (error && renderDataTable) return <div>An error occurred: {error?.message}</div>

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Typography theme={theme} component="h2" variant="h2" sx={{ mt: 3 }}>
          Customer Journey Analytics
        </Typography>
        <Box theme={theme} sx={{ opacity: 0.3, mt: 3, mb: 3 }}>
          <hr />
        </Box>
        {renderDataTable && (
          <DataTableWrap className="min-h" mb={2}>
            <Box theme={theme} className="scroll">
              <Box className="scroll-hide spr-border" theme={theme} />
            </Box>
            <DataTable
              theme={theme}
              theadChildren={
                <>
                  <TableRow>
                    <TableCell className="indent-cell">Email Address</TableCell>
                    <TableCell className="indent-cell">Year/Make/Model/Trim</TableCell>
                    <TableCell>Lead Status</TableCell>
                    <TableCell>Lead ID </TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Contact Number</TableCell>
                    <TableCell>Preferred Communication</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Stock</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Last Updated At</TableCell>
                    <TableCell>Last Synced Status</TableCell>
                    <TableCell>Lead Source</TableCell>
                    <TableCell>Details</TableCell>
                  </TableRow>
                  <TableRow className="filters-row">
                    {[...Array(15)].map((_, index) => (
                      <TableCell key={index}>
                        <Box theme={theme} className="table-filter">
                          <Input
                            theme={theme}
                            fullWidth={true}
                            type={''}
                            startAdornment={''}
                            endAdornment={''}
                            id={''}
                            placeholder="Search"
                          />
                          <Button defaultBtn iconText={<Icon name="IconFilter" />} />
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </>
              }
              tbodyChildren={
                isLoading ? (
                  <TableRow>
                    <TableCell colSpan={14}>Loading...</TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={14}>Error loading data. Please try again.</TableCell>
                  </TableRow>
                ) : apiData ? (
                  apiData.map((item, index) => <MainRow key={index} data={item} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={14}>No data available.</TableCell>
                  </TableRow>
                )
              }
            />
          </DataTableWrap>
        )}
        <iframe
          width="100%"
          height="2480"
          title="dashboard"
          style={{ border: '1px solid #EBECF2', borderRadius: '8px' }}
          src="https://lookerstudio.google.com/embed/reporting/3b0f9d66-4bbf-4316-82e0-c42d3388170f/page/a0jcD"
        ></iframe>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default CustomerJourneyAnalytics
