import { FC } from 'react'
import { styled, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, Grid, IBoxProps } from '@ntpkunity/controls'
import AddVtc from '@app/desking/add-vtc'
import OrderDetails from '@app/desking/order-details'
import CalculationSection from '@app/desking/calculation-section'

export const DeskingWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.desking-wrap': {
    '.dsk-disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
      userSelect: 'none'
    }
  }
}))

const Desking: FC = () => {
  const theme = useTheme()

  return (
    <LayoutWithSideNav theme={theme}>
      <DeskingWrap theme={theme} className="desking-wrap">
        <Grid theme={theme} container columnSpacing={2} rowSpacing={1} mt={2}>
          <Grid theme={theme} item xs={12}>
            <AddVtc />
          </Grid>
          <Grid theme={theme} item lg={2} md={3} sm={4}>
            <OrderDetails />
          </Grid>
          <Grid theme={theme} item lg={10} md={9} sm={8}>
            <CalculationSection />
          </Grid>
        </Grid>
      </DeskingWrap>
    </LayoutWithSideNav>
  )
}

export default Desking
