import { FC, memo } from 'react'
import { Box, HeaderHiddenSearch, PageHeader, Typography } from 'components'
import { InputAdornment, useTheme } from '@mui/material'
import ReturnsCardGrid from '@app/order-management/order-view/returns-card-grid'
import { IDealerWorkQueueOrderReturn } from '@models'
import { Icon, Input } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { useGetVehicleReturnsRequest } from '@apis/dealer-workqueue.service'
import { RETURNS } from '@helpers/enums'

const DealerWorkQueueOrderReturn: FC<IDealerWorkQueueOrderReturn> = ({
  heading,
  subheading,
  data,
  ordersLoading
}) => {
  const theme = useTheme()
  const { control, getValues } = useForm()
  const { mutate: getVehicleReturns, isLoading: vehicleReturnsLoading } =
    useGetVehicleReturnsRequest()

  const fetchSearchedReturns = (vin: any) => {
    let params: any = { return_status: RETURNS.PENDING_RETURN }
    if (vin?.length > 0) {
      params.vin = vin.toUpperCase()
    }
    getVehicleReturns({ params })
  }

  const callFetchResults = () => {
    if (getValues()?.vin?.length > 0) {
      fetchSearchedReturns(getValues()?.vin.toUpperCase())
    } else if (getValues()?.vin == '') {
      fetchSearchedReturns('')
    }
  }

  return (
    <>
      <PageHeader className="main-page-header">
        <Box theme={theme} flexGrow={'1'}>
          <Typography theme={theme} variant="h2" component="h2" className="title">
            {heading}
          </Typography>
          {data?.length > 0 && (
            <Typography theme={theme} variant="subtitle1" component="p" className="counter">
              {data?.length}
            </Typography>
          )}
          <Typography theme={theme} variant="body2" component="span" className="caption">
            {subheading}
          </Typography>
        </Box>
        <Box
          theme={theme}
          className="action-area"
          flexShrink={0}
          display={'flex'}
          alignItems={'center'}
        >
          <Box theme={theme} sx={{ mr: 1 }}>
            <HeaderHiddenSearch>
              <Controller
                name={'vin'}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                  <Input
                    theme={theme}
                    fullWidth
                    type="text"
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        callFetchResults()
                      }
                    }}
                    value={value}
                    placeholder="Search by vin"
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon
                          name="SearchIcon"
                          onClick={() => {
                            callFetchResults()
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                )}
              />
            </HeaderHiddenSearch>
          </Box>
        </Box>
      </PageHeader>
      <Box theme={theme} sx={{ mb: 4 }}>
        <ReturnsCardGrid
          data={data}
          ordersLoading={ordersLoading || vehicleReturnsLoading}
          componentType={heading}
        />
      </Box>
      <Box theme={theme} sx={{ mb: 2 }}>
        <hr className="spr" />
      </Box>
    </>
  )
}

export default memo(DealerWorkQueueOrderReturn)
