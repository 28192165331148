import { IStipStoreState } from '@models'
import IActions from '@store/configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_ORDER_STIPULATIONS = 'SET ORDER STIPULATIONS'

export const initialState: IStipStoreState = {
  stipulations: [],
  net_finance_amount: 0,
  monthly_payment: 0,
  terms: 0
}

const StipulationReducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_ORDER_STIPULATIONS:
      return { ...state, ...(action.payload as typeof initialState) }
  }
  return state
}

export const setStipulationsInitialData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof StipulationReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_ORDER_STIPULATIONS, payload: data })
  }
}
export default StipulationReducer
