import { FC } from 'react'

import { useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid, Accordion, Box } from '@ntpkunity/controls'
import { ICustomer } from '@models'

const CustomerDetails: FC<{ customer_info: ICustomer }> = ({ customer_info }) => {
  const theme = useTheme()

  const CoApplicant = () => {
    return (
      <Grid theme={theme} className="container" container rowSpacing={0} columnSpacing={1}>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography
            theme={theme}
            variant="body2"
            component="h3"
            className="label"
            children="Name"
          />
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
          <Typography theme={theme} variant="body2" component="span" children="Partially Paid" />
        </Grid>
        {/* **************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography
            theme={theme}
            variant="body2"
            component="h3"
            className="label"
            children="Address"
          />
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
          <Typography theme={theme} variant="body2" component="span" children="Alexander J." />
        </Grid>
        {/* **************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography
            theme={theme}
            variant="body2"
            component="h3"
            className="label"
            children="2570 24th St. Sacramento, CA 95818"
          />
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
          <Typography theme={theme} variant="body2" component="span" children="sample@sample.com" />
        </Grid>
        {/* **************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography
            theme={theme}
            variant="body2"
            component="h3"
            className="label"
            children="License Number"
          />
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={6}>
          <Typography theme={theme} variant="body2" component="span" children="L1234567" />
        </Grid>
      </Grid>
    )
  }

  const mailing_address = customer_info?.customer_addresses?.find(
    (address: any) => address.address_type === 'Mailing'
  )

  return (
    <DetailCard className="detail-card" mb={2}>
      <Typography theme={theme} component="h4" variant="h4" mb={2}>
        Customer Details
      </Typography>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Name
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          {customer_info?.first_name || customer_info?.last_name ? (
            <Typography theme={theme} variant="body2" component="span">
              {customer_info?.first_name} {customer_info?.last_name}
            </Typography>
          ) : (
            <Typography theme={theme} variant="body2" component="h3" className="label-detail">
              {'-'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid className="container" theme={theme} container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Address
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          {mailing_address?.address_line_1 ||
          mailing_address?.city ||
          mailing_address?.state_name ||
          mailing_address?.zip_code ? (
            <Typography theme={theme} variant="body2" component="h3" className="label-detail">
              {mailing_address?.address_line_1} {mailing_address?.city},{' '}
              {mailing_address?.state_name} {mailing_address?.zip_code}
            </Typography>
          ) : (
            <Typography theme={theme} variant="body2" component="h3" className="label-detail">
              {'-'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Email
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {customer_info?.email ? customer_info?.email : '-'}
          </Typography>
        </Grid>
      </Grid>
      {/* Code are Hide because of mapping are not correct */}
      {/* <Grid className="container" theme={theme} container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            License Number
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {(customer_info as any)?.customer_license?.[0]?.license_number
              ? (customer_info as any)?.customer_license?.[0]?.license_number
              : '-'}
          </Typography>
        </Grid>
      </Grid> */}
      <Box theme={theme} className="accordion-c" pt={2} display={'none'}>
        <Accordion
          theme={theme}
          items={[
            {
              isExpanded: false,
              key: '0',
              title: <>Co-Applicant 1</>,
              content: <CoApplicant />
            },
            {
              isExpanded: false,
              key: '1',
              title: <>Co-Applicant 2</>,
              content: <CoApplicant />
            }
          ]}
        />
      </Box>
    </DetailCard>
  )
}

export default CustomerDetails
