import Http from '@helpers/http-api'
import { useMutation } from 'react-query'
import { EP_PEN_GET_PRODUCT, EP_PEN_GET_PROVIDER, EP_PEN_SET_DEALER } from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'

export const usePenSetDealer = (): any => {
  const { states } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<
    {
      action: string
      address1: string
      address2: string
      city: string
      contactName: string
      penDealerId: 0
      dealershipName: string
      email: string
      fax: string
      isTestDealer: true
      observesDayLightSaving: boolean
      phone: string
      state: string
      timeZone: string
      webSite: string
      zipCode: string
    },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      const headers = {
        'x-dealer-code': states?.dealerAndLenderInfo?.dealer_code
      }
      return apiService.post<any>(`${EP_PEN_SET_DEALER}`, body, { headers })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useGetProvider = (): any => {
  const { states } = useStoreContext()
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      const headers = {
        'x-dealer-code': states?.dealerAndLenderInfo?.dealer_code
      }
      let promiseResult: any
      await apiService.get<any>(`${EP_PEN_GET_PROVIDER}`, {}, { headers }).then((response) => {
        promiseResult = response
      })
      return promiseResult.Envelope.Body.GetProvidersResponse.GetProvidersResult.Providers.Provider
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetProductByProviderId = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: string }, any>(
    async (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      let promiseResult: any
      await apiService.get<any>(`${EP_PEN_GET_PRODUCT}/${body.id}`).then((response) => {
        promiseResult = response
      })
      return promiseResult.Envelope.Body.GetProductsResponse.GetProductsResult.Products.Product
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
