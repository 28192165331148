import Http from '@helpers/http-api'
import { useMutation, useQuery } from 'react-query'
import {
  EP_CREATE_CUSTOMER_CONSENT,
  // EP_CUSTOMER_STATUS_BY_EMAIL,
  EP_CUSTOMER_CHECK_CREATE_BY_EMAIL,
  EP_FRAUD_CHECK,
  EP_SAVE_CUSTOMER_CONSENT,
  CUSTOMER_MANDATORY_DISCLAIMERS,
  GET_VEHICLE_DETAILS,
  DISCLAIMER_AGREEMENT
} from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'
import { getMarketPlaceToken } from '@helpers/methods'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}

export const useCheckCustomerStatus = (): any => {
  const { actions, states } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ email: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      const reqObj = {
        email: body?.email,
        dealer_id: states?.dealerAndLenderInfo?.id
      }
      return apiService.patch<any>(
        EP_CUSTOMER_CHECK_CREATE_BY_EMAIL,
        {
          ...reqObj
        },
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setCustomerData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useCreateCustomerConsent = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { email: string; password: string; dealer_id: string; zip_code: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_CREATE_CUSTOMER_CONSENT, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useSaveCustomerConsent = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { dealer_id: string; lender_id: string; customer_id: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_SAVE_CUSTOMER_CONSENT, body, { headers })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateCustomerConsent = (): any => {
  const { data, isLoading, mutate } = useMutation<{ reference_id: string }, any>(
    (body: any) => {
      let newbody: any = { ...body, address_type: 'MAILING' }
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_CREATE_CUSTOMER_CONSENT}`, newbody, {
        headers
      })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useCustomerFraudCheck = (): any => {
  const { data, isLoading, mutate } = useMutation<
    { user_id: number; reference_number: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_FRAUD_CHECK, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetVehicleAgainstVin = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${GET_VEHICLE_DETAILS}/${body.vin}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetTenantConsent = () => {
  return useQuery(CUSTOMER_MANDATORY_DISCLAIMERS, async () => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return await apiService.get<any>(`${CUSTOMER_MANDATORY_DISCLAIMERS}`)
  })
}

export const useAddCustomerConsent = (): any => {
  return useMutation<any>((body: any) => {
    const apiService = Http.createConnection({
      baseUrl: process.env.API_GATEWAY
    })
    return apiService.post<any>(`${DISCLAIMER_AGREEMENT}`, body)
  })
}
