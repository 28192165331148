import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const BtnTabs = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.btn-tabs': {
    padding: 2,
    borderRadius: 8,
    backgroundColor: '#F6F7F9',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    },
    '.btn': {
      '&.btn-default': {
        padding: '4px 12px',
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.grey[600],
        position: 'relative',
        flexWrap: 'nowrap',
        flexShrink: 0,
        flexGrow: 1,
        '&::before': {
          display: 'block',
          content: '" "',
          width: 1,
          height: 20,
          backgroundColor: theme.palette.grey[200],
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '&:first-child, &.selected': {
          '&::before': {
            display: 'none'
          }
        },
        '&.selected': {
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.common.white,
          boxShadow:
            '0px 44px 12px 0px rgba(0, 0, 0, 0.00), 0px 28px 11px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
          '+': {
            '.btn': {
              '&.btn-default': {
                '&::before': {
                  display: 'none'
                }
              }
            }
          }
        }
      }
    }
  }
}))
