import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { IconButton, Link, Theme, ToggleButton } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import {
  Icon,
  ISideBarLayoutProps,
  IDrawerProps,
  IBoxProps,
  Menu,
  Autocomplete
} from '@ntpkunity/controls'
import {
  Grid,
  Typography,
  Avatar,
  Box,
  Drawer,
  Divider,
  List
  // Switch as CustomizedSwitches
} from 'components'
import { useGetProfile } from '@apis/user-profile.service'
import { LayoutWithBreadCrumbs } from '@styles/layout'
import Logo from '@public/assets/images/Lane-Logo.svg'
import LogoIcon from '@public/assets/images/Lane-LogoIcon.svg'
import { useStoreContext } from '@store/storeContext'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { APP_ROUTES } from '@helpers/links'
import {
  getInitials,
  getAllSettingsFromLocalStorage,
  addConditionToRequestBody,
  initialDealerWorkQueueState
} from '@helpers/methods'
// import { BrowsingIcon } from '../../../components/Icons'
//@ts-ignore
import { useHasPermissions, logout } from '@ntpkunity/controls-ums'
import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'
import { Controller, useForm } from 'react-hook-form'
import { AutoSearch } from 'components'
import { TextField } from '@mui/material'
import clsx from 'clsx'
import {
  useGetANDealers,
  useGetReadyCheckList,
  useGetSchedulePickUpRequest,
  useGetVehiclesAvailableConfirmation
} from '@apis/dealer-workqueue.service'
import { QueryKeys } from '@helpers/enums'
import { useQueryClient } from 'react-query'
import { CustomerColumns } from '@helpers/constants'
// import { externalAutoNationBaseUrl } from '@helpers/http-api'
const drawerWidth = 320

type ICustomizedBox = IBoxProps & { open?: boolean }

const CustomizedContainer = styled(Box)<ICustomizedBox>(({ theme, open }) => ({
  width: 'calc(100% - 80px)',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  padding: '0 40px',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  header: {
    borderBottom: '1px solid' + theme.palette.grey[100],
    padding: '22px 0',
    '.MuiGrid-container': {
      alignItems: 'center'
    },
    '.tenant-title': {
      color: theme.palette.grey[600]
    },
    '.tenant-name': {
      fontWeight: theme.typography.fontWeightMedium,
      padding: '0 4px',
      position: 'relative',
      color: theme.palette.grey[900]
    },
    '.header-nav-right': {
      '.header-link-item': {
        padding: '0 12px',
        position: 'relative',
        textDecoration: 'none',
        '&:last-child': {
          paddingRight: 0
        }
      },
      '.MuiLink-root': {
        textDecoration: 'none'
      }
    },
    '.status-icon': {
      backgroundColor: theme.palette.primary.main,
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      border: '2px solid' + theme.palette.common.white,
      position: 'absolute',
      right: 0,
      bottom: 0
    }
  }
}))

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  '&.u-side-nav': {
    '.aside-content-wrap': {
      padding: '12px'
    },
    '.u-list-item': {
      '.u-list-item-text': {
        opacity: 1
      }
    }
  }
})

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(80px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(80px)`
  },
  '&.u-side-nav': {
    '.aside-content-wrap': {
      padding: '12px'
    },
    '.u-list-item': {
      justifyContent: 'center',
      '.u-list-item-icon': {
        marginRight: 0,
        '&.collapse-icon': {
          display: 'none'
        }
      },
      '.u-list-item-text': {
        opacity: 0
      },
      '&:focus': {
        backgroundColor: '#F2F2F2 !important',
        '.u-list-item-icon': {
          svg: {
            path: {
              stroke: theme.palette.primary.main + '!important'
            }
          }
        }
      },
      '&:active': {
        backgroundColor: '#F2F2F2 !important',
        '.u-list-item-icon': {
          svg: {
            path: {
              stroke: theme.palette.primary.main + '!important'
            }
          }
        }
      },
      '&.active': {
        backgroundColor: '#F2F2F2 !important',
        '.u-list-item-icon': {
          svg: {
            path: {
              stroke: theme.palette.primary.main + '!important'
            }
          }
        }
      }
    },
    '.nav-group-title': {
      textAlign: 'center'
    },
    '.side-nav-actions': {
      '.toggle-nav-btn': {
        padding: 0,
        width: 40,
        height: 40,
        transform: 'rotate(0deg)'
      },
      '.MuiFormGroup-root': {
        display: 'none'
      }
    },
    '.u-nested-list': {
      display: 'none'
    }
  }
})

const CustomizedDrawer = styled(Drawer)<IDrawerProps>(({ theme, open }): any => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const LayoutWrap = styled(Box)<IBoxProps>(({ theme }): any => ({
  display: 'flex',
  '.u-aside-wrap': {
    zIndex: 2048,
    display: 'flex',
    '.side-nav-area-wrap': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      '@supports (-moz-appearance:none)': {
        scrollbarWidth: 'thin'
      },
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 1px',
        backgroundColor: '#f0f0f0'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: '#cdcdcd'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a6a6a6'
      }
    },
    '.aside-content-wrap': {
      '&.content-top': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      },
      '&.action-content': {
        padding: '0px 12px 12px 12px',
        marginTop: '-12px'
      },
      '.MuiDivider-root': {
        margin: '12px 0'
      }
    },
    '.logo-wrap': {
      width: '100%',
      display: 'inline-block',
      borderBottom: '1px solid' + theme.palette.divider,
      '.logo-area': {
        padding: 12,
        width: '100%',
        display: 'flex',
        height: 75,
        verticalAlign: 'middle',
        img: {
          maxHeight: 50,
          minHeight: 50,
          height: 'auto',
          maxWidth: '100%',
          width: 'auto',
          margin: '0 0 0 15px',
          '&.logo-icon': {
            display: 'table',
            margin: 'auto',
            maxWidth: 50,
            minHeight: 'auto',
            width: 'auto'
          }
        }
      },
      '.MuiDivider-root': {
        marginTop: 8
      }
    },
    'open-drawer-logo': {
      width: '100%'
    },
    '.nav-group-title': {
      fontSize: 10,
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[600],
      lineHeight: theme.typography.body1.lineHeight,
      display: 'block'
    },
    '.side-nav-actions': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '4px 0',
      '.toggle-nav-btn': {
        padding: 0,
        width: 40,
        height: 40,
        borderRadius: theme.shape.borderRadius,
        transition: 'all 0.3s ease-in-ou 0s',
        transform: 'rotate(180deg)',
        '.MuiTouchRipple-root': {
          display: 'none'
        }
      },
      '.MuiFormGroup-root': {
        label: {
          marginLeft: 0,
          marginRight: 0
        }
      }
    }
  }
}))

const ActionOptions = {
  PROFILE: 'Profile',
  LOGOUT: 'Logout'
}

const CustomSideBarLayout: React.FC<ISideBarLayoutProps & { isDisplay?: boolean }> = ({
  children,
  theme,
  isDisplay = true
}) => {
  const [open, setOpen] = React.useState(false)
  const [openChildren, setOpenChildren] = React.useState({ dashboard: false })
  const [selected, setSelected] = React.useState(false)
  let navigate = useNavigate()
  const { pathname } = useLocation()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const settings: any = localStorage?.getItem('settings')
  const parsedSettingsObject = JSON.parse(settings)
  const { instance } = useMsal()

  const {
    actions: {
      setUserProfile,
      setSelectedANDealer,
      setDealerVehicleAvailableConfirmationOrders,
      setDealerGetReadyCheckList,
      setDealerSchedulePickups
    },
    states
  } = useStoreContext()
  const is_an_user = getAllSettingsFromLocalStorage()?.is_ad_user || false
  const userEmail =
    parsedSettingsObject?.role?.name == 'Admin'
      ? states?.lenderInfo?.email
      : parsedSettingsObject.email
  const { data: userProfile } = useGetProfile(!is_an_user)
  const { mutate: getANDealers } = useGetANDealers()
  const ThemeBuilderData: any = queryClient.getQueryData([QueryKeys.GET_THEME_BUILDER, userEmail])
  useEffect(() => {
    if (!is_an_user && userProfile) {
      setUserProfile({
        ...userProfile
      })
    }
  }, [userProfile])

  useEffect(() => {
    if (is_an_user) {
      getANDealers(
        {},
        {
          onSuccess(res: any) {
            setANDealersData([
              {
                id: '',
                storeName: 'All',
                hyperionId: '',
                address: {
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  zipCode: ''
                }
              },
              ...res
            ])
          }
        }
      )
    }
  }, [])

  const MenuOptions: any = [
    ...(!parsedSettingsObject?.is_ad_user
      ? [
          {
            optionText: 'Profile',
            optionkey: 'Profile',
            optionValue: 'Profile'
          }
        ]
      : []),
    { optionText: 'Logout', optionkey: 'Logout', optionValue: 'Logout' }
  ]
  const pageHeading = pathname && pathname == '/dms/dealer/work-queue' ? 'Pending Actions' : ''
  const currentPage = pathname.includes('vehicle-check')
    ? 'Availability Check'
    : pathname.includes('checklist')
    ? 'Get Ready Checklist'
    : pathname.includes('schedule-pickup')
    ? 'Schedule Pickup'
    : pathname.includes('vehicle-return')
    ? 'Vehicle Return'
    : pathname.includes('completed')
    ? 'Completed Order'
    : pathname.includes('cancelled')
    ? 'Canceled Order'
    : ''
  //@ts-ignore
  const handleSelection = (event, key, value) => {
    switch (key) {
      case ActionOptions.PROFILE:
        navigate(APP_ROUTES.USER_PROFILE)
        return
      case ActionOptions.LOGOUT:
        const logoutRequest = { account: instance.getAllAccounts()[0] }
        if (!is_an_user) {
          logout()
          localStorage.clear()
          navigate('/market-place/signup')
        } else {
          instance.logoutRedirect(logoutRequest)
        }
    }
  }

  // const dashboardPermissions = useHasPermissions(dealexPermissions?.MENUS?.DASHBOARD)
  const ordersPermissions = useHasPermissions(dealexPermissions?.MENUS?.ORDERS)
  const inventoryLinkPermissions = useHasPermissions(dealexPermissions?.MENUS?.INVENTORY_LINK)
  const userMgmtPermissions = useHasPermissions(dealexPermissions?.MENUS?.USER_MANAGEMENT)
  const dealerConfigPermissions = useHasPermissions(dealexPermissions?.MENUS?.DEALER_CONFIGURATIONS)
  const dealerAdminDashboardPermissions = useHasPermissions(dealexPermissions?.MENUS?.DASHBOARD)
  const viewCustomerListPermissions = useHasPermissions(
    dealexPermissions?.MENUS?.VIEW_CUSTOMER_LIST
  )
  const dealerWorkqueuePermissions = useHasPermissions(dealexPermissions?.MENUS?.DEALER_WORKQUEUE)
  const ccpWorkqueuePermission = useHasPermissions(dealexPermissions?.MENUS?.CCP_WORKQUEUE_LINK)

  const { control } = useForm()

  const sideBarUpperSectionOptionsArray = [
    ...(!is_an_user
      ? [
          {
            icon: <Icon name="DashboardIcon" />,
            label: 'Dashboard',
            hideLabel: false,
            hideIcon: false,
            expanded: openChildren?.dashboard ? true : false,
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined,
            onClick: () => {
              setOpen(true)
              setOpenChildren({ dashboard: !openChildren?.dashboard })
            },
            subOptions: [
              {
                label: 'Business Analytics ',
                onClick: () => {
                  navigate(APP_ROUTES.DASHBOARDIMAGE)
                }
              },
              {
                label: 'Product Analytics',
                onClick: () => {
                  navigate(APP_ROUTES.DASHBOARD)
                }
              },
              {
                label: 'Dealership Performance',
                onClick: () => {
                  navigate(
                    dealerAdminDashboardPermissions
                      ? APP_ROUTES.BMW_DASHBOARD_OF_MANHATTAN
                      : APP_ROUTES.DEALERSHIP_PERFORMANCE
                  )
                }
              },
              ...(dealerAdminDashboardPermissions
                ? [
                    {
                      label: 'Customer Journey Analytics',
                      onClick: () => {
                        navigate(APP_ROUTES.Customer_Journey_Analytics)
                      }
                    }
                  ]
                : [])
            ]
          }
        ]
      : []),
    ...(ordersPermissions
      ? [
          {
            icon: <Icon name="OrdersIcon" />,
            label: 'Orders',
            hideLabel: false,
            hideIcon: false,
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined,
            onClick: () => {
              navigate(APP_ROUTES.WORKQUEUE)
            }
          }
        ]
      : []),
    ...(inventoryLinkPermissions
      ? [
          {
            icon: <Icon name="InvenroryManagementIcon" />,
            label: 'Inventory Management',
            hideLabel: false,
            hideIcon: false,
            onClick: () => {
              navigate(APP_ROUTES.INVENTORY_MANAGEMENT)
            },
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined
          }
        ]
      : []),
    ...(userMgmtPermissions
      ? [
          {
            icon: <Icon name="UserManagementIcon" />,
            label: 'User Management',
            hideLabel: false,
            hideIcon: false,
            onClick: () => {
              navigate(APP_ROUTES.MANAGE_USERS)
            },
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined
          }
        ]
      : []),
    ...(dealerConfigPermissions
      ? [
          {
            icon: <Icon name="DealerConfigIcon" />,
            label: 'Configurations',
            hideLabel: false,
            hideIcon: false,
            onClick: () => {
              const settings = getAllSettingsFromLocalStorage()
              settings.isDealex = true
              localStorage.setItem('settings', JSON.stringify(settings))
              if (parsedSettingsObject.role.name == 'Admin')
                window.open(getNextScreenUrl() + '/configuration')
              else window.open(getNextScreenUrl() + '/configuration/dealer-configurations')
            },
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined
          }
        ]
      : []),
    ...(parsedSettingsObject && !parsedSettingsObject?.is_ad_user
      ? [
          // {
          //   icon: <BrowsingIcon />,
          //   label: 'Co-browsing',
          //   hideLabel: false,
          //   hideIcon: false,
          //   listItemButtonSx: undefined,
          //   listItemIconSx: undefined,
          //   listItemTextSx: undefined,
          //   onClick: () => {
          //     navigate(APP_ROUTES.COBROWSING)
          //   }
          // }
        ]
      : []),
    // {
    //   icon: <Icon name="DealerConfigIcon" />,
    //   label: "Dealer Configurations",
    //   hideLabel: false,
    //   hideIcon: false,
    //   onClick: () => {
    //     // setOpen(!open);
    //   },
    //   listItemButtonSx: undefined,
    //   listItemIconSx: undefined,
    //   listItemTextSx: undefined,
    //   subOptions: [
    //     {
    //       label: "Dealer Profile ",
    //       onClick: () => {
    //         navigate("");
    //         // APP_ROUTES.DEALER_CONFIGURATIONS
    //       },
    //     },
    //     {
    //       label: "Dealer Options",
    //       onClick: () => {
    //         navigate("");
    //       },
    //     },
    //     {
    //       label: "F&I Product",
    //       onClick: () => {
    //         navigate("");
    //       },
    //     },
    //   ],
    // },
    // {
    //   icon: <Icon name="LenderConfigurations" />,
    //   label: "Lender Configurations",
    //   hideLabel: false,
    //   hideIcon: false,
    //   onClick: () => {
    //     // setOpen(!open);
    //   },
    //   listItemButtonSx: undefined,
    //   listItemIconSx: undefined,
    //   listItemTextSx: undefined,
    //   subOptions: [
    //     {
    //       label: "Lender Profile ",
    //       onClick: () => {
    //         navigate("");
    //         // APP_ROUTES.DEALER_CONFIGURATIONS
    //       },
    //     },
    //     {
    //       label: "Asset Setups",
    //       onClick: () => {
    //         navigate("");
    //       },
    //     },
    //     {
    //       label: "General Setups",
    //       onClick: () => {
    //         navigate("");
    //       },
    //     },
    //   ],
    // }
    // ] : [
    // ...(true?

    ...(viewCustomerListPermissions
      ? [
          {
            icon: <Icon name="CustomerListIcon" />,
            label: 'Customers List',
            hideLabel: false,
            hideIcon: false,
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined,
            onClick: () => {
              window.location.href = `${process.env.CCP_CUSTOMERS_LIST_SCREEN}`
            }
          }
        ]
      : []),
    ...(dealerWorkqueuePermissions
      ? [
          {
            icon: <Icon name="DealerPWorkQueue" />,
            label: 'Dealer Workqueue',
            hideLabel: false,
            hideIcon: false,
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined,
            onClick: () => {
              navigate(APP_ROUTES.DEALER_WORK_QUEUE)
            }
          }
        ]
      : []),
    ...(ccpWorkqueuePermission
      ? [
          {
            icon: <Icon name="CCPWorkQueue" />,
            label: 'CCP Workqueue',
            hideLabel: false,
            hideIcon: false,
            listItemButtonSx: undefined,
            listItemIconSx: undefined,
            listItemTextSx: undefined,
            onClick: () => {
              window.location.href = `${process.env.CCP_WORKQUEUE}`
            }
          }
        ]
      : [])
  ]
  const getNextScreenUrl = (): string => {
    let nextScreenUrl: any = process.env.NEXT_SCREEN_BASE_URL as string
    if (nextScreenUrl !== window?.location?.origin?.toString() && nextScreenUrl?.includes('www.')) {
      nextScreenUrl = nextScreenUrl?.replaceAll('www.', '')
    }
    return nextScreenUrl
  }
  const sideBarLowerSectionOptionsArray: any = [
    {
      icon: <Icon name="OrdersIcon" />,
      label: 'Documentation',
      hideLabel: false,
      hideIcon: false,
      onClick: () => {
        // setOpen(!open);
      },
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined,
      subOptions: [
        {
          label: 'User Guide',
          onClick: () => {
            window.open(process.env.LANE_USER_GUIDE + '')
          }
        },
        {
          label: 'API Documentation',
          onClick: () => {
            window.open(process.env.DEALEX_API_DOCUMENTATION + '')
          }
        }
      ]
    },
    {
      icon: <Icon name="SubmitFeedbackIcon" />,
      label: 'Submit Feedback',
      hideLabel: false,
      hideIcon: false,
      toolTipItem: 'Submit Feedback',
      onClick: () => {
        navigate(`${APP_ROUTES.FEEDBACK}`)
      },
      listItemButtonSx: undefined,
      listItemIconSx: undefined,
      listItemTextSx: undefined
    }
    // {
    //   icon: <Icon name="GetHelpIcon" />,
    //   label: 'Get Help',
    //   hideLabel: false,
    //   hideIcon: false,
    //   onClick: () => {},
    //   listItemButtonSx: undefined,
    //   listItemIconSx: undefined,
    //   listItemTextSx: undefined
    // }
  ]

  const [anDealers, setANDealersData] = React.useState<any>([])

  const { mutate: getVehicleConfirmation } = useGetVehiclesAvailableConfirmation()
  const { mutate: getReadyCheckList } = useGetReadyCheckList()
  const { mutate: getSchedulePickups } = useGetSchedulePickUpRequest()

  const callANOrdersEndpoints = (value: any) => {
    if (Object.keys(value).length > 0) {
      setHyperionIdInOrdersReq(value)
    }
  }
  const setHyperionIdInOrdersReq = (data: any) => {
    setDealerVehicleAvailableConfirmationOrders(initialDealerWorkQueueState)
    setDealerGetReadyCheckList(initialDealerWorkQueueState)
    setDealerSchedulePickups(initialDealerWorkQueueState)
    let additionalParams: any[] = []
    if (data?.id !== '') {
      addConditionToRequestBody(CustomerColumns.DEALER_ID, data?.id, 'Equals', additionalParams)

      getVehicleConfirmation({ additionalParams })
      getReadyCheckList({ additionalParams })
      getSchedulePickups({ additionalParams })
    } else {
      getVehicleConfirmation({ additionalParams })
      getReadyCheckList({ additionalParams })
      getSchedulePickups({ additionalParams })
    }
  }
  const checkAdmin = useHasPermissions(dealexPermissions?.MENUS?.ADMIN_DEALER_DROPDOWN)

  return (
    <LayoutWrap theme={theme} component="div" className={!isDisplay ? 'inner-layout-wrap' : ''}>
      {isDisplay ? (
        <>
          <Box theme={theme} component="aside" className="u-aside-wrap">
            <CustomizedDrawer theme={theme} variant="permanent" open={open} className="u-side-nav">
              <div className="logo-wrap">
                <div className={'logo-area'}>
                  {open ? (
                    <img
                      src={ThemeBuilderData ? ThemeBuilderData?.logo?.location : Logo}
                      className={is_an_user ? 'open-drawer-logo' : ''}
                      alt="Logo"
                      id="unity_logo"
                    />
                  ) : (
                    <img
                      src={ThemeBuilderData ? ThemeBuilderData?.logo?.location : LogoIcon}
                      alt="Logo Icon"
                      className="logo-icon"
                      id="unity_logo"
                    />
                  )}
                </div>
                {parsedSettingsObject && !parsedSettingsObject?.is_ad_user}
              </div>
              <div className="side-nav-area-wrap">
                <div className="aside-content-wrap content-top">
                  <List theme={theme} options={sideBarUpperSectionOptionsArray} />
                </div>
                {parsedSettingsObject && !parsedSettingsObject?.is_ad_user && (
                  <div className="aside-content-wrap">
                    <Divider />
                    <Typography
                      theme={theme}
                      variant="caption"
                      component="small"
                      className="nav-group-title"
                    >
                      Support
                    </Typography>
                    <List theme={theme} options={sideBarLowerSectionOptionsArray} />
                  </div>
                )}
              </div>
              <div className="aside-content-wrap action-content">
                {parsedSettingsObject && !parsedSettingsObject?.is_ad_user && <Divider />}
                <div className="side-nav-actions">
                  {/* {parsedSettingsObject && !parsedSettingsObject?.is_ad_user && (
    <CustomizedSwitches
                          onChange={(e) => {
                            actions.setTheme(e.target.checked ? 'dark' : 'default')
                          }}
                          theme={theme}
    ></CustomizedSwitches>
                      )} */}
                  <IconButton className="toggle-nav-btn" onClick={() => setOpen(!open)}>
                    <Icon name="ChevronRight" />
                  </IconButton>
                </div>
              </div>
            </CustomizedDrawer>
          </Box>
          <CustomizedContainer theme={theme} open={open} component="main">
            <Box theme={theme} component="header" sx={{ display: 'flex' }}>
              <Grid theme={theme} direction="row" container>
                <Grid theme={theme} item xs={6} md={6}>
                  {pageHeading !== '' && (
                    <Controller
                      name="dealership"
                      control={control}
                      defaultValue={''}
                      render={({}) => (
                        <AutoSearch theme={theme} className="auto-search">
                          {checkAdmin && (
                            <>
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{ mr: 1 }}
                                theme={theme}
                              >
                                Dealer:
                              </Typography>
                              <ToggleButton
                                value="check"
                                selected={selected}
                                onChange={() => {
                                  setSelected(!selected)
                                }}
                              >
                                {states?.selectedANDealer?.label ?? 'All'}
                                <Icon name="ChevronDown" />
                              </ToggleButton>
                            </>
                          )}
                          {selected && (
                            <>
                              <Link className="overlay" onClick={() => setSelected(!selected)} />
                              <Box theme={theme} className="list-wrap">
                                <Autocomplete
                                  theme={theme}
                                  id="select_dealers"
                                  open={selected}
                                  disablePortal={true}
                                  capitalizeLabel={true}
                                  items={anDealers?.map((option: any) => ({
                                    label: option.storeName,
                                    id: option.hyperionId
                                  }))}
                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      placeholder="Search"
                                      className={clsx({
                                        'u-form-group': true
                                      })}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                  renderOption={(props: any, option: any) => (
                                    <li {...props} key={option.id}>
                                      <span>{option.label}</span>
                                    </li>
                                  )}
                                  getOptionLabel={(option: any) => option.label}
                                  onChange={(_e: any, value: any) => {
                                    setSelectedANDealer({ label: value?.label, id: value?.id })
                                    callANOrdersEndpoints(value)
                                    setSelected(!selected)
                                  }}
                                />
                              </Box>
                            </>
                          )}
                        </AutoSearch>
                      )}
                    />
                  )}
                  <LayoutWithBreadCrumbs
                    theme={theme}
                    breadcrumbs={[
                      ...(id
                        ? [
                            {
                              item: 'Dealer Workqueue',
                              link: `${APP_ROUTES.DEALER_WORK_QUEUE}`
                            }
                          ]
                        : []),
                      ...(id ? [{ item: ` / ${currentPage} `, link: `` }] : [])
                    ]}
                  />
                </Grid>
                <Grid
                  theme={theme}
                  item
                  display="flex"
                  justifyContent="right"
                  alignItems={'center'}
                  xs={6}
                  md={6}
                  className="header-nav-right"
                >
                  {/* <a href="#link" className="header-link-item">
    <Icon name="SearchIcon" />
    </a> */}
                  {/* {!parsedSettingsObject?.is_ad_user && (
    <a href="#link" className="header-link-item">
    <Icon name="BellIcon" />
    </a>
                    )} */}
                  <Menu
                    theme={theme}
                    open={false}
                    options={MenuOptions}
                    render={(cb) => (
                      <>
                        <Link className="nav-link" href="javascript:void(0)" onClick={cb}>
                          {(userProfile?.first_name ?? '').length > 0 ? (
                            <Avatar theme={theme}>
                              {getInitials(
                                `${userProfile?.first_name as string}`,
                                `${userProfile?.last_name as string}`
                              )}
                            </Avatar>
                          ) : (
                            <Icon name="PersonIcon" />
                          )}
                        </Link>
                      </>
                    )}
                    handleOptionClick={handleSelection}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  />
                  {/* <a className="header-link-item user-link">
    <Avatar theme={theme}></Avatar>
    <span className="status-icon"></span>
    </a> */}
                </Grid>
                <Divider />
              </Grid>
            </Box>
            {children}
          </CustomizedContainer>
        </>
      ) : (
        <CustomizedContainer theme={theme} className="inner-customized-container">
          {children}
        </CustomizedContainer>
      )}
    </LayoutWrap>
  )
}
export default CustomSideBarLayout
