import { FC, ReactNode } from 'react'
import cls from 'clsx'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { TableListWrap } from './table-list.style'

interface ListItemProps {
  listTitle?: string | ReactNode
  withBg?: boolean
  items: Array<{
    title?: string | ReactNode
    value?: string | ReactNode
    hasPrimaryText?: boolean
    hasBoldText?: boolean
  }>
}

export const TableList: FC<ListItemProps> = ({ listTitle, items, withBg }) => {
  const theme = useTheme()
  return (
    <TableListWrap theme={theme} className={cls({ 'table-list-wrap': true, 'with-bg': withBg })}>
      <Typography
        theme={theme}
        variant="body2"
        component="p"
        className=" text-overflow"
        mb={1}
        children={<b>{listTitle}</b>}
      />
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Typography theme={theme} component="span" variant="caption">
              <Box theme={theme} className="list-title">
                {item.title}
              </Box>
            </Typography>
            <Typography theme={theme} component="span" variant="caption">
              <Box
                theme={theme}
                className={`text-overflow list-value ${item.hasPrimaryText ? 'text-primary' : ''} ${
                  item.hasBoldText ? 'fw-700' : ''
                }`}
              >
                {item.value}
              </Box>
            </Typography>
          </li>
        ))}
      </ul>
    </TableListWrap>
  )
}
