import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { Button, Icon, Box, Input, Tooltip, Select } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import {
  InstallationMode,
  QueryKeys,
  Status,
  StipulationType,
  FeeHandlingType
} from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { IQuotationParameters } from 'types/quote-parameters'
import { FinancialStipType } from '@helpers/enums/stipulation-type.enum'

const PackageLease: FC<{
  orderStatus?: string
  leaseObj: any
  calculationData: any
  form: any
  selectedAccordion: any
  sortedOptionsArray: any
  tradeInEquityValue: any
  optionsSum: any
  allowedMileageData: any
  quotationParameterData: IQuotationParameters
  setQuotationParameterData: any
  setQuotationChanges: any
}> = ({
  leaseObj,
  calculationData,
  form,
  selectedAccordion,
  sortedOptionsArray,
  optionsSum,
  tradeInEquityValue,
  quotationParameterData,
  setQuotationParameterData,
  setQuotationChanges,
  orderStatus,
  allowedMileageData
}) => {
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { states, actions } = useStoreContext()
  const isOrderConditioned = orderStatus === Status.Conditioned
  const isPendingConfirmation = orderStatus === Status.PendingConfirmation
  const financialStips = (states.stipulationsData as any).stipulations?.filter(
    (stip: any) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL
  )
  const [leaseFees, setLeaseFees] = useState<any>([])
  useEffect(() => {
    if (quotationParameterData) {
      const lease = quotationParameterData?.lease_fees ?? []
      const finance = quotationParameterData?.finance_fees ?? []

      const activeLeaseFees = lease.filter((item) => item.is_active === true)
      const activeFinanceFees = finance.filter((item) => item.is_active === true)
      const combinedFees = [...activeLeaseFees, ...activeFinanceFees]
      const uniqueFees = combinedFees.reduce((acc, current) => {
        if (!acc.some((feeItem: any) => feeItem.fee_name === current.fee_name)) {
          acc.push(current)
        }
        return acc
      }, [])
      setLeaseFees(uniqueFees)
    }
  }, [quotationParameterData?.finance_fees, quotationParameterData?.lease_fees])
  useEffect(() => {
    const sellingPrice = quotationParameterData?.selling_price || 0
    const rvRate =
      leaseObj?.finance_type === 'Finance'
        ? quotationParameterData?.finance_rv_rate ?? 0 // Default to 0 if finance_rv_rate is null or undefined
        : quotationParameterData?.lease_rv_rate ?? 0 // Default to 0 if lease_rv_rate is null or undefined

    const result = ((sellingPrice * rvRate) / 100).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
    form.setValue(
      leaseObj?.finance_type == 'Finance' ? 'finance_rv_value' : 'lease_rv_value',
      result
    )
  }, [
    quotationParameterData?.finance_rv_rate,
    quotationParameterData?.lease_rv_rate,
    quotationParameterData?.selling_price
  ])

  useEffect(() => {
    const sellingPrice = quotationParameterData?.selling_price || 0
    const financeDownPayment = quotationParameterData?.finance_down_payment || 0
    const leaseDownPayment = quotationParameterData?.lease_down_payment || 0

    const result =
      leaseObj?.finance_type === 'Finance' && financeDownPayment
        ? ((sellingPrice * financeDownPayment) / 100).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        : leaseObj?.finance_type === 'Lease' && leaseDownPayment
        ? ((sellingPrice * leaseDownPayment) / 100).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        : '-'
    // Store result in a variable
    const finalResult = result
    form.setValue(
      leaseObj?.finance_type == 'Finance'
        ? 'finance_down_payment_value'
        : 'lease_down_payment_value',
      finalResult
    )
  }, [
    quotationParameterData?.finance_down_payment,
    quotationParameterData?.lease_down_payment,
    quotationParameterData?.selling_price
  ])

  const minimum_margin =
    leaseObj?.base_rate_chart?.base_rate + leaseObj?.interest_chart[0].minimum_margin
  const maximum_margin =
    leaseObj?.base_rate_chart?.base_rate + leaseObj?.interest_chart[0].maximum_margin
  // const condition = minimum_margin <= 0 ? true : false
  // const isDisplayFinanceError = financialStips?.find(
  //   (stip: any) => stip.parameter === FinancialStipType.FINANCED_AMOUNT
  // )?.fulfilled
  const isDisplayPeriodicError = financialStips?.find(
    (stip: any) => stip.parameter === FinancialStipType.PERIODIC_PAYMENT
  )?.fulfilled
  const isDisplayTermsError = financialStips?.find(
    (stip: any) => stip.parameter === FinancialStipType.TERMS
  )?.fulfilled
  const isOnlyFinanceAmount = financialStips?.some(
    (stip: any) => stip.parameter === FinancialStipType.FINANCED_AMOUNT
  )
  const isStipTerms = financialStips?.some(
    (stip: any) => stip.parameter === FinancialStipType.TERMS
  )
  const isOnlyMonthlyPayment = financialStips?.some(
    (stip: any) => stip.parameter === FinancialStipType.PERIODIC_PAYMENT
  )

  const dealerFee =
    leaseObj.finance_type == 'Finance'
      ? quotationParameterData?.finance_fees
      : quotationParameterData?.lease_fees

  const isOnlyTerms =
    isOrderConditioned && isStipTerms && !isOnlyFinanceAmount && !isOnlyMonthlyPayment
  const fni =
    leaseObj.finance_type == 'Finance'
      ? quotationParameterData?.finance_fni ?? []
      : quotationParameterData?.lease_fni ?? []
  const queryClient = useQueryClient()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const financedAmount =
    (calculationData?.selling_price ? calculationData?.selling_price : 0) -
    (calculationData?.down_payment ? calculationData?.down_payment : 0) +
    (leaseFees?.reduce(
      (total: number, currentItem: any) =>
        currentItem?.applicable_finance_type?.includes(leaseObj.finance_type) &&
        currentItem?.fee_handling.toLowerCase() === FeeHandlingType.FINANCE
          ? total + currentItem.applied_price
          : total + 0,
      0
    ) || 0) +
    (fni?.reduce((total: number, currentItem: any) => total + currentItem.applied_price, 0) || 0) +
    (sortedOptionsArray?.reduce(
      (total: number, currentItem: any) =>
        total +
        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
          !currentItem.is_price_inclusive) ||
        currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
          ? currentItem.applied_price || currentItem.price
          : 0),
      0
    ) || 0) -
    (tradeInEquityValue || 0)
  const totalRV =
    (((quotationParameterData?.selling_price || 0) *
      (leaseObj?.finance_type == 'Finance'
        ? quotationParameterData?.finance_rv_rate || 0
        : quotationParameterData?.lease_rv_rate || 0)) /
      100 || 0) +
    (sortedOptionsArray?.reduce(
      (total: number, currentItem: any) =>
        total +
        (leaseObj?.finance_type !== 'Finance' &&
        !(
          currentItem.rv_amount_on_option > 0 &&
          currentItem.installation_mode === InstallationMode.OPTIONAL
        ) &&
        ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
          !currentItem.is_price_inclusive) ||
          currentItem.installation_mode !== InstallationMode.PRE_INSTALLED)
          ? currentItem.rv_balloon_value
            ? parseFloat(currentItem.rv_balloon_value)
            : currentItem.rv_balloon_percentage || 0
          : 0),
      0
    ) || 0)
  useEffect(() => {
    actions.setStipulationsInitialData({
      ...states.stipulationsData,
      monthly_payment: calculationData?.estimated_monthly_payment,
      net_finance_amount: financedAmount
    })
  }, [calculationData?.estimated_monthly_payment, financedAmount])
  const { control } = form
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  useEffect(() => {
    const taxAmount = quotationParameterData?.tax_amount ?? 0
    if (taxAmount) {
      setQuotationParameterData({
        ...quotationParameterData,
        tax_amount: quotationParameterData?.tax_amount
      })
    }

    if (taxAmount) {
      setQuotationParameterData({
        ...quotationParameterData,
        finance_amount: calculationData?.gross_capitalized_cost
      })
    }
  }, [])
  const theme = useTheme()
  const [open, setOpen] = useState({
    selling_price: false,
    finance_down_payment: false,
    lease_down_payment: false,
    lease_rv_rate: false,
    finance_rv_rate: false,
    fees: dealerFee?.map(() => false) ?? [],
    fni: fni && fni?.length > 0 ? fni?.map(() => false) : [],
    options: sortedOptionsArray?.map(() => false) ?? [],
    trade_in_equity: false,
    annual_usage: false,
    finance_rate: false,
    lease_rate: false,
    total_rv_value: false,
    contract_term_id: false
  })
  const tooltipMsg = () => {
    return (
      <>
        Please update the following financial parameters to fulfill this financial stipulation:
        <br />
        -Selling Price <br /> -Down Payment <br /> -RV/Balloon <br /> -Options <br /> -Fees and F&I
        Products <br /> -Trade-In
      </>
    )
  }

  const getTotalOptionPrice = (sortedOptionsArray: any, optionsSum: any) => {
    if (sortedOptionsArray && sortedOptionsArray.length > 0 && optionsSum !== 0) {
      const totalPrice = sortedOptionsArray.reduce(
        (total: any, currentItem: any) =>
          total +
          ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
            !currentItem.is_price_inclusive) ||
          currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
            ? currentItem.applied_price ?? currentItem.price
            : 0),
        0
      )

      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {totalPrice.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </>
      )
    } else {
      return '-'
    }
  }

  const getTotalFniPrice = (fni: any) => {
    if (fni && fni.length > 0) {
      const totalFniPrice = fni.reduce(
        (total: any, currentItem: any) => total + currentItem.applied_price,
        0
      )
      const formattedTotal = totalFniPrice.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })

      if (formattedTotal !== '0.00') {
        return (
          <>
            <span className="symbol">{defaultCurrency}</span>
            <span>{formattedTotal}</span>
          </>
        )
      } else {
        return '-'
      }
    } else {
      return '-'
    }
  }

  const calculateDealerFee = (dealerFee: any) => {
    if (dealerFee && dealerFee.length > 0) {
      const totalAppliedPrice = dealerFee
        .filter(
          (dealerFeeItem: { fee_handling: string }) => dealerFeeItem.fee_handling !== 'Upfront'
        ) // Exclude "Upfront" fee_handling
        .reduce((sum: any, fee: any) => sum + (fee.applied_price || 0), 0) // Sum up the applied_price
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {totalAppliedPrice.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </>
      )
    }
  }
  const getTotalDealerFee = (dealerFee: any) => {
    if (dealerFee && dealerFee.length > 0) {
      const totalDealerFee = dealerFee.reduce(
        (total: any, currentItem: any) => total + currentItem.applied_price,
        0
      )

      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {totalDealerFee.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </>
      )
    }
  }

  const formatTradeInEquity = (tradeInEquityValue: any) => {
    if (tradeInEquityValue && tradeInEquityValue > 0) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </>
      )
    } else {
      return '-'
    }
  }

  const formatTradeInInEquity = (tradeInEquityValue: any) => {
    if (tradeInEquityValue && tradeInEquityValue < 0) {
      return (
        <>
          {'-'}
          <span className="symbol">{defaultCurrency}</span>
          {Math.abs(tradeInEquityValue).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}
        </>
      )
    } else {
      return '-'
    }
  }

  const totalTaxCapitalized = () => {
    if (calculationData?.tax_amount) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {calculationData?.tax_amount
            ? calculationData?.tax_amount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : '-'}
        </>
      )
    }
    if (quotationParameterData?.tax_amount) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {quotationParameterData?.tax_amount
            ? quotationParameterData?.tax_amount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : '-'}
        </>
      )
    } else {
      return '-'
    }
  }

  const capitalizedCostReduction = () => {
    if (calculationData?.capitalized_cost_reduction) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {calculationData?.capitalized_cost_reduction ? (
            <>
              {calculationData?.capitalized_cost_reduction.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </>
      )
    }
    if (quotationParameterData?.capitalized_cost_reduction) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {quotationParameterData?.capitalized_cost_reduction ? (
            <>
              {quotationParameterData?.capitalized_cost_reduction.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </>
      )
    } else {
      return '-'
    }
  }

  const totalGrossCapitalizedCost = (
    dealerFee: any,
    fni: any,
    tradeInEquityValue: any,
    sortedOptionsArray: any,
    optionsSum: any
  ) => {
    let totalAppliedPrice: number = 0
    if (dealerFee) {
      totalAppliedPrice = dealerFee
        .filter(
          (dealerFeeItem: { fee_handling: string }) => dealerFeeItem.fee_handling !== 'Upfront'
        ) // Exclude "Upfront" fee_handling
        .reduce((sum: any, fee: any) => sum + (fee.applied_price || 0), 0)
    }

    const totalFniPrice = fni.reduce(
      (total: any, currentItem: any) => total + currentItem.applied_price,
      0
    )
    quotationParameterData?.selling_price ? quotationParameterData?.selling_price : 0
    calculationData?.tax_amount ? calculationData?.tax_amount : 0

    let totalPrice: number = 0
    if (sortedOptionsArray && sortedOptionsArray.length > 0 && optionsSum !== 0) {
      totalPrice = sortedOptionsArray.reduce(
        (total: number, currentItem: any) =>
          total +
          ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
            !currentItem.is_price_inclusive) ||
          currentItem.installation_mode !== InstallationMode.PRE_INSTALLED
            ? currentItem.applied_price ?? currentItem.price
            : 0),
        0
      )
    }
    const tradeInValue = tradeInEquityValue < 0 ? tradeInEquityValue : 0
    const totalGrossCapitalizedCostValue =
      totalAppliedPrice +
      totalFniPrice -
      tradeInValue +
      calculationData?.tax_amount +
      Number(quotationParameterData?.selling_price || 0) +
      totalPrice
    const totalAmount = !calculationData?.gross_capitalized_cost
      ? totalGrossCapitalizedCostValue
      : calculationData?.gross_capitalized_cost
    const totalCost =
      orderStatus !== 'Draft'
        ? quotationParameterData?.gross_capitalized_cost
        : calculationData?.gross_capitalized_cost
    if (totalCost) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {totalCost
            ? totalCost.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : '-'}
        </>
      )
    }
    if (totalAmount) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {totalAmount
            ? totalAmount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : '-'}
        </>
      )
    } else {
      return '-'
    }
  }

  const totalBaseMonthlyPayment = () => {
    if (calculationData?.estimated_monthly_payment) {
      return (
        <>
          <span className="symbol">{defaultCurrency}</span>
          {calculationData?.monthly_payment
            ? (calculationData?.monthly_payment).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : '-'}
        </>
      )
    } else {
      return '-'
    }
  }

  const totalUpfrontAmount = () => {
    const totalUpfrontSum =
      leaseFees?.reduce(
        (sum: number, current: any) =>
          current?.applicable_finance_type?.includes(leaseObj.finance_type) &&
          current?.fee_handling.toLowerCase() === FeeHandlingType.UPFRONT
            ? sum + current.applied_price
            : sum,
        0
      ) || 0

    return totalUpfrontSum === 0 ? (
      '-'
    ) : (
      <>
        <span className="symbol">{defaultCurrency}</span>
        {totalUpfrontSum.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}
      </>
    )
  }

  return (
    <Box theme={theme} className="package-body">
      <Box theme={theme} className="package-row editable">
        <Box
          theme={theme}
          className={
            isOrderConditioned &&
            isDisplayPeriodicError === false &&
            isDisplayPeriodicError !== undefined
              ? 'alert alert-error'
              : ''
          }
        >
          {isOrderConditioned &&
          isDisplayPeriodicError === false &&
          isDisplayPeriodicError !== undefined ? (
            <Tooltip title={tooltipMsg()} theme={theme} placement="left">
              <Icon name="InfoIcon" />
            </Tooltip>
          ) : (
            <></>
          )}
          <b>{totalBaseMonthlyPayment()}</b>
        </Box>
      </Box>
      {selectedAccordion.periodicPayment && (
        <>
          <Box theme={theme} className="package-row editable">
            <span className="symbol">{defaultCurrency}</span>
            {calculationData?.estimated_monthly_payment
              ? calculationData?.estimated_monthly_payment.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
              : '-'}
          </Box>
          <Box theme={theme} className="package-row editable">
            {calculationData?.monthly_sales_use_tax ? (
              <>
                <span className="symbol">{defaultCurrency}</span>
                {calculationData?.monthly_sales_use_tax}
              </>
            ) : (
              '-'
            )}
          </Box>
        </>
      )}

      <Box theme={theme} className="package-row editable">
        <b>
          {calculationData?.due_at_signing ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {calculationData?.due_at_signing?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </b>
      </Box>
      {selectedAccordion.dueAtSigning && (
        <>
          <Box theme={theme} className="package-row editable">
            <span className="symbol">{defaultCurrency}</span>
            {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
              ? '(' +
                (quotationParameterData?.finance_down_payment
                  ? quotationParameterData?.finance_down_payment
                  : 0
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                }) +
                '%) '
              : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
              ? '(' +
                (quotationParameterData?.lease_down_payment
                  ? quotationParameterData?.lease_down_payment
                  : 0
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                }) +
                '%) '
              : ''}
            <span className="symbol">{defaultCurrency}</span>
            {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
              ? (
                  ((quotationParameterData?.selling_price || 0) *
                    quotationParameterData?.finance_down_payment) /
                  100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
              : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
              ? (
                  ((quotationParameterData?.selling_price || 0) *
                    quotationParameterData?.lease_down_payment) /
                  100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })
              : '-'}
          </Box>
          <Box theme={theme} className="package-row editable">
            {calculationData?.estimated_monthly_payment && leaseObj?.rental_mode == 'Advance' ? (
              <>
                <span className="symbol">{defaultCurrency}</span>
                {(
                  calculationData?.estimated_monthly_payment +
                  calculationData?.monthly_sales_use_tax
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </>
            ) : (
              '-'
            )}
          </Box>
          <Box theme={theme} className="package-row editable">
            {totalUpfrontAmount()}
          </Box>
        </>
      )}
      <Box theme={theme} className="package-row editable right-align">
        <Box
          theme={theme}
          className={
            isOrderConditioned && isDisplayTermsError === false && isDisplayTermsError !== undefined
              ? `${isOrderConditioned ? '' : 'package-row'} editable alert alert-error`
              : ''
          }
        >
          {!open.contract_term_id && quotationParameterData?.contract_term_id && (
            <>
              {quotationParameterData?.contract_term_id
                ? states?.contractTermsData?.filter(
                    (term: any) => term.id == quotationParameterData?.contract_term_id
                  )[0].term
                : '-'}
              {isOnlyTerms ? (
                <></>
              ) : (
                !isOrderConditioned && (
                  <Button
                    theme={theme}
                    defaultBtn
                    className="btn-edit"
                    iconText={
                      <Icon
                        name="EditIcon"
                        onClick={() => {
                          setOpen({ ...open, contract_term_id: !open.contract_term_id })
                        }}
                      />
                    }
                  />
                )
              )}
            </>
          )}
          {open.contract_term_id && (
            <Box theme={theme} className="editable-content">
              <Controller
                name="contract_term_id"
                control={control}
                render={({ field }) => (
                  <Select
                    theme={theme}
                    items={states?.contractTermsData?.map((item) => ({
                      text: item.term,
                      value: item.id
                    }))}
                    disablePortal={false}
                    value={
                      quotationParameterData?.contract_term_id
                        ? states?.contractTermsData?.filter(
                            (term: any) => term.id == quotationParameterData?.contract_term_id
                          )[0].id
                        : '-'
                    }
                    onChange={(e: any) => {
                      field.onChange(e.target.value)
                      setQuotationParameterData({
                        ...quotationParameterData,
                        contract_term_id: e.target.value
                      })
                    }}
                  />
                )}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="TickIcon" />}
                onClick={() => {
                  setOpen({ ...open, contract_term_id: !open.contract_term_id })
                  setQuotationParameterData({
                    ...quotationParameterData,
                    contract_term_id: parseFloat(form.getValues()?.contract_term_id)
                  })
                  setQuotationChanges(true)
                }}
              />
              <Button
                theme={theme}
                defaultBtn
                iconText={<Icon name="CloseBlack" />}
                onClick={() => {
                  setOpen({ ...open, contract_term_id: !open.contract_term_id })
                  form.setValue('contract_term_id', quotationParameterData?.contract_term_id)
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box theme={theme} className="package-row editable right-align">
        {!(leaseObj?.finance_type == 'Finance' ? open.finance_rate : open.lease_rate) && (
          <>
            {(leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_rate) ||
            (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rate)
              ? leaseObj?.finance_type == 'Finance'
                ? ''
                : quotationParameterData?.lease_rate != null
                ? `Money Factor: (${(quotationParameterData?.lease_rate / 2400).toFixed(4)})`
                : '-'
              : ''}
            {(leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_rate) ||
            (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rate) ? (
              <>
                {(
                  leaseObj?.finance_type == 'Finance'
                    ? quotationParameterData?.finance_rate
                    : quotationParameterData?.lease_rate
                )
                  ? (
                      (leaseObj?.finance_type == 'Finance'
                        ? quotationParameterData?.finance_rate
                        : quotationParameterData?.lease_rate) || 0
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4
                    })
                  : '-'}
                <span className="symbol">{'%'}</span>
              </>
            ) : (
              '-'
            )}
            {isOnlyTerms ? (
              <></>
            ) : (
              !isOrderConditioned &&
              !isPendingConfirmation && (
                <Button
                  theme={theme}
                  defaultBtn
                  className="btn-edit"
                  iconText={
                    <Icon
                      name="EditIcon"
                      onClick={() => {
                        setOpen({
                          ...open,
                          [leaseObj?.finance_type == 'Finance' ? 'finance_rate' : 'lease_rate']:
                            !(leaseObj?.finance_type == 'Finance'
                              ? open.finance_rate
                              : open.lease_rate)
                        })
                        form.setValue(
                          leaseObj?.finance_type == 'Finance' ? 'finance_rate' : 'lease_rate',
                          leaseObj?.finance_type == 'Finance'
                            ? quotationParameterData?.finance_rate
                            : quotationParameterData?.lease_rate
                        )
                      }}
                    />
                  }
                />
              )
            )}
          </>
        )}
        {(leaseObj?.finance_type == 'Finance' ? open.finance_rate : open.lease_rate) && (
          <Box theme={theme} className="editable-content">
            <Tooltip
              theme={theme}
              placement="left"
              title={
                leaseObj?.finance_type && (
                  <>
                    Value should be in between{' '}
                    {leaseObj?.interest_chart.length > 0 && minimum_margin}% to{' '}
                    {leaseObj?.interest_chart.length > 0 && maximum_margin}%
                  </>
                )
              }
            >
              <Controller
                name={leaseObj?.finance_type == 'Finance' ? 'finance_rate' : 'lease_rate'}
                control={control}
                defaultValue={
                  leaseObj?.finance_type == 'Finance'
                    ? quotationParameterData?.finance_rate
                    : quotationParameterData?.lease_rate
                }
                render={({ field }) => (
                  <Input
                    theme={theme}
                    placeholder={'0.00'}
                    type="decimal"
                    endAdornment={<span className="symbol">%</span>}
                    onChange={field.onChange}
                    value={field.value}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                  />
                )}
              />
            </Tooltip>
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="TickIcon" />}
              onClick={() => {
                const is_apr_valid =
                  (leaseObj?.interest_chart && maximum_margin) >=
                    (leaseObj?.finance_type == 'Finance'
                      ? form.getValues().finance_rate
                      : form.getValues().lease_rate) &&
                  (leaseObj?.interest_chart && minimum_margin) <=
                    (leaseObj?.finance_type == 'Finance'
                      ? form.getValues().finance_rate
                      : form.getValues().lease_rate)
                if (is_apr_valid) {
                  setOpen({
                    ...open,
                    [leaseObj?.finance_type == 'Finance' ? 'finance_rate' : 'lease_rate']:
                      !(leaseObj?.finance_type == 'Finance' ? open.finance_rate : open.lease_rate)
                  })
                  setQuotationParameterData({
                    ...quotationParameterData,
                    [leaseObj?.finance_type == 'Finance' ? 'finance_rate' : 'lease_rate']:
                      leaseObj?.finance_type == 'Finance'
                        ? form.getValues().finance_rate
                        : form.getValues().lease_rate
                  })
                  setQuotationChanges(true)
                }
              }}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                setOpen({
                  ...open,
                  [leaseObj?.finance_type == 'Finance' ? 'finance_rate' : 'lease_rate']:
                    !(leaseObj?.finance_type == 'Finance' ? open.finance_rate : open.lease_rate)
                })
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        theme={theme}
        className={`package-row ${
          leaseObj?.finance_type == 'Finance' ? '' : 'editable'
        } right-align`}
      >
        {!open.annual_usage && (
          <>
            {leaseObj?.finance_type == 'Finance'
              ? '-'
              : quotationParameterData?.annual_mileage || '-'}
            {isOnlyTerms ? (
              <></>
            ) : (
              !isOrderConditioned && (
                <Button
                  theme={theme}
                  defaultBtn
                  className="btn-edit"
                  iconText={
                    <>
                      {leaseObj?.finance_type == 'Lease' ? (
                        <Icon
                          name="EditIcon"
                          onClick={() => {
                            setOpen({ ...open, annual_usage: !open.annual_usage })
                            form.setValue(
                              'annual_mileage',
                              leaseObj?.finance_type == 'Finance'
                                ? '-'
                                : quotationParameterData?.annual_mileage
                            )
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  }
                />
              )
            )}
          </>
        )}
        {open.annual_usage && (
          <Box theme={theme} className="editable-content">
            <Controller
              name="annual_mileage"
              control={control}
              defaultValue={
                leaseObj?.finance_type == 'Finance' ? '-' : quotationParameterData?.annual_mileage
              }
              render={({ field }) => (
                <Select
                  theme={theme}
                  items={allowedMileageData?.map((item: any) => ({
                    text: item.value,
                    value: item.value
                  }))}
                  value={quotationParameterData?.annual_mileage || '-'}
                  disablePortal={false}
                  onChange={(e: any) => {
                    field.onChange(e.target.value)
                    setQuotationParameterData({
                      ...quotationParameterData,
                      annual_mileage: e.target.value
                    })
                  }}
                />
              )}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="TickIcon" />}
              onClick={() => {
                setOpen({ ...open, annual_usage: !open.annual_usage })
                setQuotationParameterData({
                  ...quotationParameterData,
                  annual_mileage: parseFloat(form.getValues()?.annual_mileage)
                })
                setQuotationChanges(true)
              }}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                setOpen({ ...open, annual_usage: !open.annual_usage })
                form.setValue('annual_mileage', quotationParameterData?.annual_mileage)
              }}
            />
          </Box>
        )}
      </Box>
      <Box theme={theme} className="package-row divider-row">
        <hr />
      </Box>
      <Box theme={theme} className="package-row editable">
        {!open.selling_price && quotationParameterData?.selling_price && (
          <>
            <span className="symbol">{defaultCurrency}</span>
            {quotationParameterData?.selling_price
              ? parseFloat(quotationParameterData?.selling_price.toString()).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }
                )
              : '-'}

            {isOnlyTerms ? (
              <></>
            ) : (
              !isOrderConditioned && (
                <Button
                  theme={theme}
                  defaultBtn
                  className="btn-edit"
                  iconText={
                    <Icon
                      name="EditIcon"
                      onClick={() => {
                        setOpen({ ...open, selling_price: !open.selling_price })
                        form.setValue('selling_price', quotationParameterData?.selling_price)
                      }}
                    />
                  }
                />
              )
            )}
          </>
        )}
        {open.selling_price && (
          <Box theme={theme} className="editable-content input-left-align">
            <Controller
              name="selling_price"
              control={control}
              defaultValue={
                quotationParameterData?.selling_price ? quotationParameterData?.selling_price : '-'
              }
              render={({ field }) => (
                <Input
                  theme={theme}
                  placeholder={'0.00'}
                  type="decimal"
                  startAdornment={<span className="start-adornment">{defaultCurrency}</span>}
                  onChange={field.onChange}
                  value={field.value}
                  masking
                  maskDecimalScale={2}
                  maskNumeric
                />
              )}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="TickIcon" />}
              onClick={() => {
                setOpen({ ...open, selling_price: !open.selling_price })
                setQuotationParameterData({
                  ...quotationParameterData,
                  selling_price: parseFloat(form.getValues()?.selling_price)
                })
                setQuotationChanges(true)
              }}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                setOpen({ ...open, selling_price: !open.selling_price })
                form.setValue('selling_price', quotationParameterData?.selling_price)
              }}
            />
          </Box>
        )}
      </Box>
      <Box theme={theme} className="package-row editable right-align">
        {!(leaseObj?.finance_type == 'Finance'
          ? open.finance_down_payment
          : open.lease_down_payment) &&
          (leaseObj?.finance_type == 'Finance'
            ? quotationParameterData?.finance_down_payment
            : quotationParameterData?.lease_down_payment) && (
            <>
              {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                ? '(' +
                  (quotationParameterData?.finance_down_payment
                    ? quotationParameterData?.finance_down_payment
                    : 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }) +
                  '%) '
                : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                ? '(' +
                  (quotationParameterData?.lease_down_payment
                    ? quotationParameterData?.lease_down_payment
                    : 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  }) +
                  '%) '
                : ''}
              <span className="symbol">{defaultCurrency}</span>
              {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                ? (
                    ((quotationParameterData?.selling_price || 0) *
                      quotationParameterData?.finance_down_payment) /
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                ? (
                    ((quotationParameterData?.selling_price || 0) *
                      quotationParameterData?.lease_down_payment) /
                    100
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : '-'}
              {isOnlyTerms ? (
                <></>
              ) : (
                !isOrderConditioned &&
                !isPendingConfirmation && (
                  <Button
                    theme={theme}
                    defaultBtn
                    className="btn-edit"
                    iconText={
                      <Icon
                        name="EditIcon"
                        onClick={() => {
                          setOpen({
                            ...open,
                            [leaseObj?.finance_type == 'Finance'
                              ? 'finance_down_payment'
                              : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                              ? open.finance_down_payment
                              : open.lease_down_payment)
                          })
                        }}
                      />
                    }
                  />
                )
              )}
            </>
          )}
        {(leaseObj?.finance_type == 'Finance'
          ? open.finance_down_payment
          : open.lease_down_payment) && (
          <Box theme={theme} className="editable-content">
            <Tooltip
              theme={theme}
              placement="left"
              title={
                leaseObj?.downpayment_chart && (
                  <>
                    Value should be in between{' '}
                    {leaseObj?.downpayment_chart?.length > 0 &&
                      leaseObj?.downpayment_chart?.[0]?.minimum_down_payment}
                    % to{' '}
                    {leaseObj?.downpayment_chart?.length > 0 &&
                      leaseObj?.downpayment_chart?.[0]?.maximum_down_payment}
                    %
                  </>
                )
              }
            >
              <Controller
                name={
                  leaseObj?.finance_type == 'Finance'
                    ? 'finance_down_payment'
                    : 'lease_down_payment'
                }
                defaultValue={
                  leaseObj?.finance_type == 'Finance'
                    ? quotationParameterData?.finance_down_payment
                    : quotationParameterData?.lease_down_payment
                }
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    placeholder={'0.00'}
                    type="decimal"
                    endAdornment={<span className="symbol">%</span>}
                    onChange={field.onChange}
                    value={field.value}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    disabled={true}
                  />
                )}
              />
            </Tooltip>
            <Box theme={theme} className="input-left-align">
              <Controller
                name={
                  leaseObj?.finance_type == 'Finance'
                    ? 'finance_down_payment_value'
                    : 'lease_down_payment_value'
                }
                control={control}
                render={({ field }) => (
                  <Input
                    theme={theme}
                    placeholder={'0.00'}
                    type="decimal"
                    startAdornment={<span className="symbol">{defaultCurrency}</span>}
                    {...field}
                    masking
                    maskDecimalScale={2}
                    maskNumeric
                    onChange={(e: any) => {
                      const totalDownPayment =
                        (e * 100) / (quotationParameterData?.selling_price || 0)
                      form.setValue(
                        leaseObj?.finance_type == 'Finance'
                          ? 'finance_down_payment'
                          : 'lease_down_payment',
                        totalDownPayment.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      )
                      field.onChange(e)
                    }}
                  />
                )}
              />
            </Box>
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="TickIcon" />}
              onClick={() => {
                const is_down_payment_valid =
                  (leaseObj?.downpayment_chart &&
                    leaseObj?.downpayment_chart[0].maximum_down_payment) >=
                    (leaseObj?.finance_type == 'Finance'
                      ? form.getValues().finance_down_payment
                      : form.getValues().lease_down_payment) &&
                  (leaseObj?.downpayment_chart &&
                    leaseObj?.downpayment_chart[0].minimum_down_payment) <=
                    (leaseObj?.finance_type == 'Finance'
                      ? form.getValues().finance_down_payment
                      : form.getValues().lease_down_payment)
                if (is_down_payment_valid || !leaseObj?.downpayment_chart) {
                  setOpen({
                    ...open,
                    [leaseObj?.finance_type == 'Finance'
                      ? 'finance_down_payment'
                      : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                      ? open.finance_down_payment
                      : open.lease_down_payment)
                  })
                  setQuotationParameterData({
                    ...quotationParameterData,
                    [leaseObj?.finance_type == 'Finance'
                      ? 'finance_down_payment'
                      : 'lease_down_payment']:
                      leaseObj?.finance_type == 'Finance'
                        ? form.getValues().finance_down_payment
                        : form.getValues().lease_down_payment
                  })

                  setQuotationChanges(true)
                }
              }}
            />
            <Button
              theme={theme}
              defaultBtn
              iconText={<Icon name="CloseBlack" />}
              onClick={() => {
                setOpen({
                  ...open,
                  [leaseObj?.finance_type == 'Finance'
                    ? 'finance_down_payment'
                    : 'lease_down_payment']: !(leaseObj?.finance_type == 'Finance'
                    ? open.finance_down_payment
                    : open.lease_down_payment)
                })
                leaseObj?.finance_type == 'Lease'
                  ? form.setValue('lease_down_payment', quotationParameterData?.lease_down_payment)
                  : form.setValue(
                      'finance_down_payment',
                      quotationParameterData?.finance_down_payment
                    )
              }}
            />
          </Box>
        )}
      </Box>
      <Box theme={theme} className="package-row editable">
        {totalRV > 0 || leaseObj?.rv_balloon_applicable ? (
          <>
            <span className="symbol">{defaultCurrency}</span>
            {totalRV.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          </>
        ) : (
          '-'
        )}
      </Box>
      {selectedAccordion.rvBalloon && (
        <>
          <Box theme={theme} className="package-row editable right-align">
            {orderStatus !== 'Draft'
              ? !(leaseObj?.finance_type == 'Finance'
                  ? open.finance_rv_rate
                  : open.lease_rv_rate) && (
                  <>
                    {(leaseObj?.finance_type == 'Finance' &&
                      quotationParameterData?.finance_rv_rate) ||
                    (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate)
                      ? leaseObj?.finance_type == 'Finance'
                        ? '(' + quotationParameterData?.finance_rv_rate + '%) '
                        : '(' + quotationParameterData?.lease_rv_rate + '%) '
                      : ''}
                    {(leaseObj?.finance_type == 'Finance' &&
                      quotationParameterData?.finance_rv_rate) ||
                    (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate) ? (
                      <>
                        <span className="symbol">{defaultCurrency}</span>
                        {(
                          ((quotationParameterData?.selling_price || 0) *
                            ((leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_rv_rate
                              : quotationParameterData?.lease_rv_rate) || 0)) /
                          100
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </>
                    ) : (
                      '-'
                    )}
                    {isOnlyTerms ? (
                      <></>
                    ) : (
                      !isOrderConditioned && (
                        <Button
                          theme={theme}
                          defaultBtn
                          className="btn-edit"
                          iconText={
                            <Icon
                              name="EditIcon"
                              onClick={() => {
                                setOpen({
                                  ...open,
                                  [leaseObj?.finance_type == 'Finance'
                                    ? 'finance_rv_rate'
                                    : 'lease_rv_rate']: !(leaseObj?.finance_type == 'Finance'
                                    ? open.finance_rv_rate
                                    : open.lease_rv_rate)
                                })
                              }}
                            />
                          }
                        />
                      )
                    )}
                  </>
                )
              : !leaseObj?.rv_balloon_applicable
              ? '-'
              : !(leaseObj?.finance_type == 'Finance'
                  ? open.finance_rv_rate
                  : open.lease_rv_rate) && (
                  <>
                    {(leaseObj?.finance_type == 'Finance' &&
                      quotationParameterData?.finance_rv_rate) ||
                    (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate)
                      ? leaseObj?.finance_type == 'Finance'
                        ? '(' + quotationParameterData?.finance_rv_rate + '%) '
                        : '(' + quotationParameterData?.lease_rv_rate + '%) '
                      : ''}
                    {(leaseObj?.finance_type == 'Finance' &&
                      quotationParameterData?.finance_rv_rate) ||
                    (leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_rv_rate) ? (
                      <>
                        <span className="symbol">{defaultCurrency}</span>
                        {(
                          ((quotationParameterData?.selling_price || 0) *
                            ((leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_rv_rate
                              : quotationParameterData?.lease_rv_rate) || 0)) /
                          100
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </>
                    ) : (
                      '-'
                    )}
                    {isOnlyTerms ? (
                      <></>
                    ) : (
                      <Button
                        theme={theme}
                        defaultBtn
                        className="btn-edit"
                        iconText={
                          <Icon
                            name="EditIcon"
                            onClick={() => {
                              setOpen({
                                ...open,
                                [leaseObj?.finance_type == 'Finance'
                                  ? 'finance_rv_rate'
                                  : 'lease_rv_rate']: !(leaseObj?.finance_type == 'Finance'
                                  ? open.finance_rv_rate
                                  : open.lease_rv_rate)
                              })
                            }}
                          />
                        }
                      />
                    )}
                  </>
                )}
            {(leaseObj?.finance_type == 'Finance' ? open.finance_rv_rate : open.lease_rv_rate) && (
              <Box theme={theme} className="editable-content">
                <Tooltip
                  theme={theme}
                  placement="left"
                  title={
                    leaseObj?.finance_type && (
                      <>
                        Value should be in between{' '}
                        {leaseObj?.rv_chart?.length > 0 && leaseObj?.rv_chart[0].minimum_rv}% to{' '}
                        {leaseObj?.rv_chart?.length > 0 && leaseObj?.rv_chart[0].maximum_rv}%
                      </>
                    )
                  }
                >
                  <Controller
                    name={leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate'}
                    control={control}
                    defaultValue={
                      leaseObj?.finance_type == 'Finance'
                        ? quotationParameterData?.finance_rv_rate
                        : quotationParameterData?.lease_rv_rate
                    }
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={'0.00'}
                        type="decimal"
                        endAdornment={<span className="symbol">%</span>}
                        onChange={field.onChange}
                        value={field.value}
                        masking
                        maskDecimalScale={2}
                        maskNumeric
                        disabled={true}
                      />
                    )}
                  />
                </Tooltip>
                <Box theme={theme} className="input-left-align">
                  <Controller
                    name={
                      leaseObj?.finance_type == 'Finance' ? 'finance_rv_value' : 'lease_rv_value'
                    }
                    control={control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={'0.00'}
                        type="decimal"
                        startAdornment={<span className="symbol">{defaultCurrency}</span>}
                        {...field}
                        masking
                        maskDecimalScale={2}
                        maskNumeric
                        onChange={(e: any) => {
                          const totalRv = (e * 100) / (quotationParameterData?.selling_price || 0)
                          form.setValue(
                            leaseObj?.finance_type == 'Finance'
                              ? 'finance_rv_rate'
                              : 'lease_rv_rate',
                            totalRv.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })
                          )
                          field.onChange(e)
                          setQuotationParameterData({
                            ...quotationParameterData,
                            total_rv_value: totalRv
                          })
                        }}
                      />
                    )}
                  />
                </Box>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="TickIcon" />}
                  onClick={() => {
                    const is_rv_rate_valid =
                      (leaseObj?.rv_chart && leaseObj?.rv_chart[0].maximum_rv) >=
                        (leaseObj?.finance_type == 'Finance'
                          ? Number(form.getValues().finance_rv_rate)
                          : Number(form.getValues().lease_rv_rate)) &&
                      (leaseObj?.rv_chart && leaseObj?.rv_chart[0].minimum_rv) <=
                        (leaseObj?.finance_type == 'Finance'
                          ? Number(form.getValues().finance_rv_rate)
                          : Number(form.getValues().lease_rv_rate))
                    if (is_rv_rate_valid || !leaseObj?.rv_chart) {
                      setOpen({
                        ...open,
                        [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                          !(leaseObj?.finance_type == 'Finance'
                            ? open.finance_rv_rate
                            : open.lease_rv_rate)
                      })
                      setQuotationParameterData({
                        ...quotationParameterData,
                        [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                          leaseObj?.finance_type == 'Finance'
                            ? form.getValues().finance_rv_rate
                            : form.getValues().lease_rv_rate
                      })
                      setQuotationChanges(true)
                    }
                  }}
                />
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="CloseBlack" />}
                  onClick={() => {
                    setOpen({
                      ...open,
                      [leaseObj?.finance_type == 'Finance' ? 'finance_rv_rate' : 'lease_rv_rate']:
                        !(leaseObj?.finance_type == 'Finance'
                          ? open.finance_rv_rate
                          : open.lease_rv_rate)
                    })
                  }}
                />
              </Box>
            )}
          </Box>
          <Box theme={theme} className="package-row editable">
            {!leaseObj?.rv_balloon_applicable ? (
              '-'
            ) : (
              <>
                <span className="symbol">
                  {leaseObj?.finance_type !== 'Finance' ? defaultCurrency : null}
                </span>
                {leaseObj?.finance_type === 'Finance'
                  ? '-'
                  : sortedOptionsArray
                      ?.reduce(
                        (total: number, currentItem: any) =>
                          total +
                          (leaseObj?.finance_type !== 'Finance' &&
                          !(currentItem.rv_amount_on_option > 0) &&
                          ((currentItem.installation_mode === InstallationMode.PRE_INSTALLED &&
                            !currentItem.is_price_inclusive) ||
                            currentItem.installation_mode !== InstallationMode.PRE_INSTALLED)
                            ? currentItem.rv_balloon_value
                              ? parseFloat(currentItem.rv_balloon_value)
                              : currentItem.rv_balloon_percentage || 0
                            : 0),
                        0
                      )
                      .toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
              </>
            )}
          </Box>
        </>
      )}
      <Box theme={theme} className="package-row editable">
        {getTotalFniPrice(fni)}
      </Box>
      {selectedAccordion.fIProducts &&
        (quotationParameterData?.lease_fni || [])
          .concat(
            (quotationParameterData?.finance_fni || [])?.filter(
              (item) =>
                (quotationParameterData?.lease_fni || [])?.findIndex(
                  (fni: any) => fni.financial_insurance_id == item.financial_insurance_id
                ) < 0
            )
          )
          .map((obj: any, index: any) => (
            <div key={index}>
              <Box theme={theme} className="package-row editable">
                {!open.fni[index] && (
                  <>
                    {fni?.some(
                      (fni: any) => fni.financial_insurance_id === obj.financial_insurance_id
                    ) ? (
                      <>
                        {fni?.filter(
                          (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                        )[0]?.applied_price === 0 ? (
                          '-'
                        ) : (
                          <>
                            <span className="symbol">{defaultCurrency}</span>
                            <span>
                              {fni
                                ?.filter(
                                  (fni: any) =>
                                    fni.financial_insurance_id == obj.financial_insurance_id
                                )[0]
                                ?.applied_price?.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                            </span>
                          </>
                        )}

                        {!isOrderConditioned && (
                          <Button
                            theme={theme}
                            defaultBtn
                            className="btn-edit"
                            iconText={
                              <Icon
                                name="EditIcon"
                                onClick={() => {
                                  const openFNI = open.fni
                                  openFNI[index] = !openFNI[index]
                                  setOpen({ ...open, fni: openFNI })
                                }}
                              />
                            }
                          />
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </>
                )}
                {open.fni[index] && (
                  <Box theme={theme} className="editable-content input-left-align">
                    <Controller
                      name={
                        leaseObj.finance_type == 'Finance'
                          ? `finance_fni[${quotationParameterData.finance_fni?.findIndex(
                              (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                            )}].applied_price`
                          : `lease_fni[${quotationParameterData.lease_fni?.findIndex(
                              (fni: any) => fni.financial_insurance_id == obj.financial_insurance_id
                            )}].applied_price`
                      }
                      control={control}
                      render={({ field }) => (
                        <Input
                          theme={theme}
                          placeholder={'0.00'}
                          type="decimal"
                          startAdornment={
                            <span className="start-adornment">{defaultCurrency}</span>
                          }
                          onChange={field.onChange}
                          value={field.value}
                          masking
                          maskDecimalScale={2}
                          maskNumeric
                        />
                      )}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="TickIcon" />}
                      onClick={() => {
                        const openFNI = open.fni
                        openFNI[index] = !openFNI[index]
                        setOpen({ ...open, fni: openFNI })
                        setQuotationParameterData({
                          ...quotationParameterData,
                          [leaseObj?.finance_type == 'Finance' ? 'finance_fni' : 'lease_fni']:
                            leaseObj?.finance_type == 'Finance'
                              ? quotationParameterData?.finance_fni?.map((fni: any) =>
                                  fni.financial_insurance_id == obj.financial_insurance_id
                                    ? {
                                        ...fni,
                                        applied_price: parseFloat(
                                          form.getValues().finance_fni[
                                            quotationParameterData.finance_fni?.findIndex(
                                              (fni: any) =>
                                                fni.financial_insurance_id ==
                                                obj.financial_insurance_id
                                            ) || 0
                                          ].applied_price
                                        )
                                      }
                                    : fni
                                )
                              : quotationParameterData?.lease_fni?.map((fni: any) =>
                                  fni.financial_insurance_id == obj.financial_insurance_id
                                    ? {
                                        ...fni,
                                        applied_price: parseFloat(
                                          form.getValues().lease_fni[
                                            quotationParameterData.lease_fni?.findIndex(
                                              (fni: any) =>
                                                fni.financial_insurance_id ==
                                                obj.financial_insurance_id
                                            ) || 0
                                          ].applied_price
                                        )
                                      }
                                    : fni
                                )
                        })
                        setQuotationChanges(true)
                      }}
                    />
                    <Button
                      theme={theme}
                      defaultBtn
                      iconText={<Icon name="CloseBlack" />}
                      onClick={() => {
                        const openFNI = open.fni
                        openFNI[index] = !openFNI[index]
                        setOpen({ ...open, fni: openFNI })
                        leaseObj?.finance_type == 'Lease'
                          ? form.setValue(
                              `lease_fni[${index}].applied_price`,
                              quotationParameterData?.lease_fni &&
                                quotationParameterData?.lease_fni[
                                  quotationParameterData.lease_fni?.findIndex(
                                    (fni: any) =>
                                      fni.financial_insurance_id == obj.financial_insurance_id
                                  ) || 0
                                ]?.applied_price
                            )
                          : form.setValue(
                              `finance_fni[${index}].applied_price`,
                              quotationParameterData?.finance_fni &&
                                quotationParameterData?.finance_fni[
                                  quotationParameterData.finance_fni?.findIndex(
                                    (fni: any) =>
                                      fni.financial_insurance_id == obj.financial_insurance_id
                                  ) || 0
                                ]?.applied_price
                            )
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div>
          ))}
      <Box theme={theme} className="package-row editable">
        {getTotalOptionPrice(sortedOptionsArray, optionsSum)}
      </Box>
      {selectedAccordion.options &&
        sortedOptionsArray?.length > 0 &&
        sortedOptionsArray?.map((obj: any, index: any) => (
          <div key={index}>
            {sortedOptionsArray[index - 1]?.installation_mode !== obj?.installation_mode && (
              <Box theme={theme} className="package-row blank"></Box>
            )}
            <Box theme={theme} className="package-row editable">
              {!open.options[index] && (
                <>
                  {(obj.installation_mode === InstallationMode.PRE_INSTALLED &&
                    !obj.is_price_inclusive) ||
                  obj.installation_mode !== InstallationMode.PRE_INSTALLED ? (
                    <>
                      {(obj?.price || obj?.applied_price) === 0 ? (
                        '-'
                      ) : (
                        <>
                          <span className="symbol">{defaultCurrency}</span>
                          {(obj?.price || obj?.applied_price).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                        </>
                      )}
                      {!isOrderConditioned && (
                        <Button
                          theme={theme}
                          defaultBtn
                          className="btn-edit"
                          iconText={
                            <Icon
                              name="EditIcon"
                              onClick={() => {
                                const openOptions = open.options
                                openOptions[index] = !openOptions[index]
                                setOpen({ ...open, options: openOptions })
                                form.setValue(
                                  `options[${index}].applied_price`,
                                  obj?.price
                                    ? obj?.price?.toString()
                                    : obj?.applied_price?.toString()
                                )
                              }}
                            />
                          }
                        />
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </>
              )}
              {open.options[index] && (
                <Box theme={theme} className="editable-content input-left-align">
                  <Controller
                    name={`options[${index}].applied_price`}
                    defaultValue={
                      obj.price != null
                        ? obj?.price?.toString()
                        : obj?.applied_price != null
                        ? obj?.applied_price?.toString()
                        : ''
                    }
                    control={control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        placeholder={'0.00'}
                        type="decimal"
                        startAdornment={<span className="start-adornment">{defaultCurrency}</span>}
                        onChange={field.onChange}
                        value={field.value}
                        masking
                        maskDecimalScale={2}
                        maskNumeric
                      />
                    )}
                  />
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="TickIcon" />}
                    onClick={() => {
                      const openOptions = open.options
                      openOptions[index] = !openOptions[index]
                      setOpen({ ...open, options: openOptions })
                      setQuotationParameterData({
                        ...quotationParameterData,
                        options: quotationParameterData?.options?.map((option: any) =>
                          option.option_id == obj.option_id
                            ? {
                                ...option,
                                applied_price: parseFloat(
                                  form.getValues().options[index].applied_price
                                )
                              }
                            : option
                        )
                      })
                      setQuotationChanges(true)
                    }}
                  />
                  <Button
                    theme={theme}
                    defaultBtn
                    iconText={<Icon name="CloseBlack" />}
                    onClick={() => {
                      const openOptions = open.options
                      openOptions[index] = !openOptions[index]
                      setOpen({ ...open, options: openOptions })
                      form.setValue(
                        `options[${index}].applied_price`,
                        quotationParameterData?.options &&
                          quotationParameterData?.options[index]?.applied_price
                      )
                    }}
                  />
                </Box>
              )}
            </Box>
          </div>
        ))}
      <Box theme={theme} className="package-row editable">
        {getTotalDealerFee(dealerFee) ?? '-'}
      </Box>
      {selectedAccordion.fees &&
        leaseFees &&
        leaseFees
          ?.sort((a: any, b: any) => a?.fee_handling.localeCompare(b?.fee_handling))
          ?.map((obj: any, index: any) => {
            const isPriceZero = (obj?.price || obj?.applied_price) === 0
            const appliedPrice = (obj?.price || obj?.applied_price)?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
            return (
              <div key={index}>
                {leaseFees[index - 1]?.fee_handling !== obj?.fee_handling && (
                  <Box theme={theme} className="package-row blank"></Box>
                )}
                <Box theme={theme} className="package-row editable">
                  {!open.fees[index] && (
                    <>
                      {isPriceZero ? (
                        '-'
                      ) : obj?.applicable_finance_type?.includes(leaseObj.finance_type) ? (
                        <>
                          <span className="symbol">{defaultCurrency}</span>
                          {appliedPrice}
                          {!isOrderConditioned && (
                            <Button
                              theme={theme}
                              defaultBtn
                              className="btn-edit"
                              iconText={
                                <Icon
                                  name="EditIcon"
                                  onClick={() => {
                                    const dealerFree = open.fees
                                    dealerFree[index] = !dealerFree[index]
                                    setOpen({ ...open, fees: dealerFree })
                                    form.setValue(
                                      leaseObj.finance_type == 'Finance'
                                        ? `finance_fees[${quotationParameterData.finance_fees?.findIndex(
                                            (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                          )}].applied_price`
                                        : `lease_fees[${quotationParameterData.lease_fees?.findIndex(
                                            (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                          )}].applied_price`,
                                      obj?.price
                                        ? obj?.price?.toString()
                                        : obj?.applied_price?.toString()
                                    )
                                    form.setValue(
                                      leaseObj.finance_type == 'Finance'
                                        ? `lease_fees[${quotationParameterData.finance_fees?.findIndex(
                                            (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                          )}].applied_price`
                                        : `finance_fees[${quotationParameterData.lease_fees?.findIndex(
                                            (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                          )}].applied_price`,
                                      obj?.price
                                        ? obj?.price?.toString()
                                        : obj?.applied_price?.toString()
                                    )
                                  }}
                                />
                              }
                            />
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </>
                  )}
                  {open.fees[index] && (
                    <Box theme={theme} className="editable-content input-left-align">
                      <Controller
                        name={
                          leaseObj.finance_type == 'Finance'
                            ? `finance_fees[${quotationParameterData.finance_fees?.findIndex(
                                (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                              )}].applied_price`
                            : `lease_fees[${quotationParameterData.lease_fees?.findIndex(
                                (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                              )}].applied_price`
                        }
                        control={control}
                        defaultValue={
                          obj.price != null
                            ? obj?.price?.toString()
                            : obj?.applied_price != null
                            ? obj?.applied_price?.toString()
                            : '-'
                        }
                        render={({ field }) => (
                          <Input
                            theme={theme}
                            placeholder={'0.00'}
                            type="decimal"
                            startAdornment={
                              <span className="start-adornment">{defaultCurrency}</span>
                            }
                            onChange={field.onChange}
                            value={field.value}
                            masking
                            maskDecimalScale={2}
                            maskNumeric
                          />
                        )}
                      />
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="TickIcon" />}
                        onClick={() => {
                          const openFees = open.fees
                          openFees[index] = !openFees[index]
                          setOpen({ ...open, fees: openFees })
                          leaseObj?.finance_type == 'Finance'
                            ? setQuotationParameterData({
                                ...quotationParameterData,
                                finance_fees: quotationParameterData?.finance_fees?.map(
                                  (fee: any) =>
                                    fee.dealer_fee_id == obj.dealer_fee_id
                                      ? {
                                          ...fee,
                                          applied_price: parseFloat(
                                            form.getValues().finance_fees[
                                              quotationParameterData.finance_fees?.findIndex(
                                                (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                              ) || 0
                                            ]?.applied_price
                                          )
                                        }
                                      : fee
                                ),
                                lease_fees: quotationParameterData?.lease_fees?.map((fee: any) =>
                                  fee.dealer_fee_id == obj.dealer_fee_id
                                    ? {
                                        ...fee,
                                        applied_price: parseFloat(
                                          form.getValues()?.finance_fees[
                                            quotationParameterData.lease_fees?.findIndex(
                                              (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                            ) || 0
                                          ]?.applied_price
                                        )
                                      }
                                    : fee
                                )
                              })
                            : setQuotationParameterData({
                                ...quotationParameterData,
                                finance_fees: quotationParameterData?.finance_fees?.map(
                                  (fee: any) =>
                                    fee.dealer_fee_id == obj.dealer_fee_id
                                      ? {
                                          ...fee,
                                          applied_price: parseFloat(
                                            form.getValues().lease_fees[
                                              quotationParameterData.finance_fees?.findIndex(
                                                (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                              ) || 0
                                            ]?.applied_price
                                          )
                                        }
                                      : fee
                                ),
                                lease_fees: quotationParameterData?.lease_fees?.map((fee: any) =>
                                  fee.dealer_fee_id == obj.dealer_fee_id
                                    ? {
                                        ...fee,
                                        applied_price: parseFloat(
                                          form.getValues()?.lease_fees[
                                            quotationParameterData.lease_fees?.findIndex(
                                              (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                            ) || 0
                                          ]?.applied_price
                                        )
                                      }
                                    : fee
                                )
                              })
                          setQuotationChanges(true)
                        }}
                      />
                      <Button
                        theme={theme}
                        defaultBtn
                        iconText={<Icon name="CloseBlack" />}
                        onClick={() => {
                          const openFees = open.fees
                          openFees[index] = !openFees[index]
                          setOpen({ ...open, fees: openFees })
                          leaseObj?.finance_type == 'Lease'
                            ? form.setValue(
                                `lease_fees[${quotationParameterData.lease_fees?.findIndex(
                                  (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                )}].applied_price`,
                                quotationParameterData?.lease_fees &&
                                  quotationParameterData?.lease_fees.filter(
                                    (fee: any) => fee.dealer_fee_id == obj.dealer_fee_id
                                  )[0]?.applied_price
                              )
                            : form.setValue(
                                `finance_fees[${quotationParameterData.finance_fees?.findIndex(
                                  (fni: any) => fni.dealer_fee_id == obj.dealer_fee_id
                                )}].applied_price`,
                                quotationParameterData?.finance_fees &&
                                  quotationParameterData?.finance_fees.filter(
                                    (fee: any) => fee.dealer_fee_id == obj.dealer_fee_id
                                  )[0]?.applied_price
                              )
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </div>
            )
          })}

      <Box theme={theme} className="package-row editable">
        {tradeInEquityValue >= 0
          ? formatTradeInEquity(tradeInEquityValue)
          : formatTradeInInEquity(tradeInEquityValue)}
      </Box>
      {selectedAccordion.tradeInEquityStatus && (
        <>
          <Box theme={theme} className="package-row editable">
            {quotationParameterData?.trade_in?.trade_in_amount ? (
              <>
                <span className="symbol">{defaultCurrency}</span>
                {quotationParameterData?.trade_in?.trade_in_amount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </>
            ) : (
              '-'
            )}
          </Box>
          <Box theme={theme} className="package-row editable">
            {quotationParameterData?.trade_in?.payoff_amount ? (
              <>
                <span className="symbol">{defaultCurrency}</span>
                {quotationParameterData?.trade_in?.payoff_amount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </>
            ) : (
              '-'
            )}
          </Box>
        </>
      )}
      <Box theme={theme} className="package-row editable">
        {totalTaxCapitalized()}
      </Box>
      <Box theme={theme} className="package-row divider-row">
        <hr />
      </Box>
      <Box theme={theme} className="package-row">
        <b>
          {calculationData?.adjusted_capitalized_cost ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {calculationData?.adjusted_capitalized_cost.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : quotationParameterData?.adjusted_capitalized_cost ? (
            <>
              <span className="symbol">{defaultCurrency}</span>
              {quotationParameterData?.adjusted_capitalized_cost.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </>
          ) : (
            '-'
          )}
        </b>
      </Box>

      {selectedAccordion.nfa && (
        <>
          <Box theme={theme} className="package-row">
            {totalGrossCapitalizedCost(
              dealerFee,
              fni,
              tradeInEquityValue,
              sortedOptionsArray,
              optionsSum
            )}
          </Box>

          {selectedAccordion.nfaList && (
            <>
              <Box theme={theme} className="package-row">
                <span className="symbol">$</span>
                {quotationParameterData?.selling_price
                  ? parseFloat(quotationParameterData?.selling_price.toString()).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }
                    )
                  : '-'}
              </Box>

              <Box theme={theme} className="package-row">
                {getTotalOptionPrice(sortedOptionsArray, optionsSum)}
              </Box>

              <Box theme={theme} className="package-row">
                {getTotalFniPrice(fni)}
              </Box>

              <Box theme={theme} className="package-row">
                {calculateDealerFee(dealerFee)}
              </Box>

              <Box theme={theme} className="package-row">
                {formatTradeInInEquity(tradeInEquityValue)}
              </Box>

              <Box theme={theme} className="package-row">
                {totalTaxCapitalized()}
              </Box>
            </>
          )}

          <Box theme={theme} className="package-row">
            {capitalizedCostReduction()}
          </Box>
          {selectedAccordion.ccr && (
            <>
              <Box theme={theme} className="package-row">
                <span className="symbol">{defaultCurrency}</span>
                {leaseObj?.finance_type == 'Finance' && quotationParameterData?.finance_down_payment
                  ? (
                      ((quotationParameterData?.selling_price || 0) *
                        quotationParameterData?.finance_down_payment) /
                      100
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : leaseObj?.finance_type == 'Lease' && quotationParameterData?.lease_down_payment
                  ? (
                      ((quotationParameterData?.selling_price || 0) *
                        quotationParameterData?.lease_down_payment) /
                      100
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  : '-'}
              </Box>

              <Box theme={theme} className="package-row">
                {formatTradeInEquity(tradeInEquityValue)}
              </Box>

              <Box theme={theme} className="package-row">
                {calculationData?.rebate_promotions ? (
                  <>
                    <span className="symbol">{defaultCurrency}</span>
                    {calculationData?.rebate_promotions}
                  </>
                ) : quotationParameterData?.rebate ? (
                  <>
                    <span className="symbol">{defaultCurrency}</span>
                    {quotationParameterData?.rebate}
                  </>
                ) : (
                  '-'
                )}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default PackageLease
