import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  VehicleSelection,
  OrderSummary,
  Quotation,
  Login,
  ChangePassword,
  Workqueue,
  InventoryManagement,
  AddOns,
  DealerConfigurations,
  FinancialInsurance,
  DealerWorkQueue,
  DealerVehicleAvilableCheck,
  DealerGetReadyCheckList,
  DealerVehicleSchedulePickup,
  DealerVehicleReturn,
  Feedback,
  OrderApproved,
  OrderCancelled,
  ManageUser,
  Userprofile,
  Dashboard,
  NotFound,
  DashboardImage,
  DealershipPerformance,
  BMWDashboardOfManhattan,
  CustomerJourneyAnalytics,
  MultipleQuotes,
  // CoBrowsing,
  LeadSummary,
  TestingPage,
  Desking
} from '@pages'
import { APP_ROUTES } from '@helpers/links'
//@ts-ignore
import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'
//@ts-ignore
import { Validate } from '@ntpkunity/controls-ums'
import { SSOLogin } from '@app/sso/components/sso-login'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import OrderStipulation from '@app/order-management/order-stipulations/order-stipulation'
import { OrderLayout } from '@app/order-management'
import OrderDetailsSection from 'pages/order-details-sections'

const OrderRoutes: FC = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route
            path={APP_ROUTES.DEALER_WORK_QUEUE}
            element={
              <Validate
                permission={dealexPermissions.ROUTING.WORK_QUEUE}
                url={APP_ROUTES.NEW_HOME_PAGE}
              >
                <DealerWorkQueue />
              </Validate>
            }
          />
          <Route path={APP_ROUTES.CANCELLED_ORDERS} element={<OrderDetailsSection />} />
          <Route path={APP_ROUTES.COMPLETED_ORDERS} element={<OrderDetailsSection />} />

          <Route
            path={APP_ROUTES.DEALER_WORK_QUEUE_VEHICLE_AVAILABLE}
            element={
              <Validate
                permission={dealexPermissions.ROUTING.VEHICLE_CHECK}
                url={APP_ROUTES.NEW_HOME_PAGE}
              >
                <DealerVehicleAvilableCheck />
              </Validate>
            }
          />
          <Route
            path={APP_ROUTES.DEALER_GET_READY_CHECKLIST}
            element={
              <Validate
                permission={dealexPermissions.ROUTING.GET_READY_CHECKLIST}
                url={APP_ROUTES.NEW_HOME_PAGE}
              >
                <DealerGetReadyCheckList />
              </Validate>
            }
          />
          <Route
            path={APP_ROUTES.DEALER_VEHICLE_SCHEDULE_PICKUP}
            element={
              <Validate
                permission={dealexPermissions.ROUTING.SCHEDULE_PICKUP}
                url={APP_ROUTES.NEW_HOME_PAGE}
              >
                <DealerVehicleSchedulePickup />
              </Validate>
            }
          />
          <Route path={APP_ROUTES.DEALER_VEHICLE_RETURN} element={<DealerVehicleReturn />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path={`${APP_ROUTES.BASE_URL}*`} element={<SSOLogin />} />
          <Route path={APP_ROUTES.SSO_LOGIN} element={<SSOLogin />} />
          <Route path={APP_ROUTES.CREATE_ORDER} element={<VehicleSelection />} />
          <Route path={APP_ROUTES.QUOTATION} element={<Quotation />} />
          <Route path={APP_ROUTES.ORDER_SUMMARY} element={<OrderSummary />} />
          <Route path={APP_ROUTES.LOGIN} element={<Login />} />
          <Route path={APP_ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route path={APP_ROUTES.WORKQUEUE} element={<Workqueue />} />
          <Route path={APP_ROUTES.MANAGE_USERS} element={<ManageUser />} />
          <Route path={APP_ROUTES.USER_PROFILE} element={<Userprofile />} />
          <Route path={APP_ROUTES.ORDER_STIPULATIONS} element={<OrderStipulation />} />
          {/* <Route path={APP_ROUTES.DEALER_WORK_QUEUE} element={<DealerWorkQueue />} /> */}
          <Route path={APP_ROUTES.INVENTORY_MANAGEMENT} element={<InventoryManagement />} />
          <Route path={APP_ROUTES.AddOns} element={<AddOns />} />
          <Route path={APP_ROUTES.DEALER_CONFIGURATIONS} element={<DealerConfigurations />} />
          <Route path={APP_ROUTES.FINANCIAL_INSURANCE} element={<FinancialInsurance />} />
          <Route path={APP_ROUTES.CREATE_ORDER_QUOTATION} element={<OrderLayout />} />
          <Route path={APP_ROUTES.QUOTE_COMPARISON} element={<MultipleQuotes />} />
          <Route path={APP_ROUTES.ORDER_APPROVED} element={<OrderApproved />} />
          <Route path={APP_ROUTES.ORDER_CANCELLED} element={<OrderCancelled />} />
          <Route path={APP_ROUTES.FEEDBACK} element={<Feedback />} />
          <Route path={APP_ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={APP_ROUTES.NOTFOUND} element={<NotFound />} />
          <Route path={APP_ROUTES.DASHBOARDIMAGE} element={<DashboardImage />} />
          <Route path={APP_ROUTES.TESTINGPAGE} element={<TestingPage />} />
          <Route path={APP_ROUTES.DEALERSHIP_PERFORMANCE} element={<DealershipPerformance />} />
          <Route path={`${APP_ROUTES.LEAD_SUMMARY}/:id`} element={<LeadSummary />} />
          <Route
            path={APP_ROUTES.BMW_DASHBOARD_OF_MANHATTAN}
            element={
              <Validate permission={'dealer-dashboard'} url={APP_ROUTES.NEW_HOME_PAGE}>
                <BMWDashboardOfManhattan />
              </Validate>
            }
          />
          <Route
            path={APP_ROUTES.Customer_Journey_Analytics}
            element={
              <Validate permission={'dealer-dashboard'} url={APP_ROUTES.NEW_HOME_PAGE}>
                <CustomerJourneyAnalytics />
              </Validate>
            }
          />
          {/* <Route path={APP_ROUTES.COBROWSING} element={<CoBrowsing />} /> */}
          <Route path={APP_ROUTES.DESKING} element={<Desking />} />
        </Routes>
      </UnauthenticatedTemplate>
    </>
  )
}

export default OrderRoutes
