import React, { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { Dialog, Grid, Button, Icon } from '@ntpkunity/controls'
import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
import TradeInControl from 'controls/trade-in-control'

const TestingPage: FC = () => {
  const theme = useTheme()

  const [openTradeInDialog, setTradeInOpenDialog] = React.useState(false)
  const [_, setTradeInObj] = useState<any>()

  const handleTradeInOpen = () => {
    setTradeInOpenDialog(true)
  }

  const handleTradeInClose = () => {
    setTradeInOpenDialog(false)
  }

  const selectTradeInObj = (tradeInData: any) => {
    setTradeInObj(tradeInData)
  }

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Grid theme={theme} item xs={4}>
          <Button
            theme={theme}
            fullWidth
            startIcon={<Icon name="AddCircleIcon" />}
            text="Add Trade-In Details"
            size="large"
            onClick={handleTradeInOpen}
          />
          <Dialog
            variant={undefined}
            size="sm"
            title="Trade-In"
            open={openTradeInDialog}
            onCloseDialog={handleTradeInClose}
            theme={theme}
            children={
              <>
                <TradeInControl
                  selectTradeInObj={selectTradeInObj}
                  setTradeInOpenDialog={setTradeInOpenDialog}
                  openTradeInDialog={openTradeInDialog}
                />
              </>
            }
          />
        </Grid>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default TestingPage
