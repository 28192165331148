import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function useToastFunction(isLoading: any, data: any, error: any, toastMessage: any) {
  const [toastId, setToastId] = useState('')
  const isMounted = useRef(true)
  //   const [reqStatus, setStatus] = useState("");

  useEffect(() => {
    isMounted.current = true
    return () => {
      if (isMounted.current && toastId) {
        toast.dismiss(toastId)
      }
      isMounted.current = false
    }
  }, [toastId])

  useEffect(() => {
    if (isLoading) {
      if (isMounted.current) {
        const toast_id: any = toast.loading(toastMessage.text, {
          type: toastMessage.type,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true
        })
        setToastId(toast_id)
      }
    } else if (error) {
      if (isMounted.current) {
        toast.update(toastId, {
          render: toastMessage.text,
          type: toastMessage.type,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          isLoading: false,
          autoClose: 5000
        })
      }
    } else if (data) {
      if (toastId != '' && isMounted.current) {
        toast.update(toastId, {
          render: toastMessage.text,
          type: toastMessage.type,
          hideProgressBar: true,
          position: toast.POSITION.TOP_CENTER,
          isLoading: false,
          autoClose: 5000
        })
      } else if (isMounted.current) {
        toast.success('Successfull', {
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true
        })
      }
    }
  }, [isLoading, data, error])

  return toastId
}

export default useToastFunction
