import { FC } from 'react'
import { useTheme, styled } from '@mui/material'
import { Box, IBoxProps, Button, Grid } from '@ntpkunity/controls'
import { BtnTabs } from '@components'

export const FinanceLeaseHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.fl-header-wrap': {
    paddingBottom: '8px',
    marginBottom: '16px',
    borderBottom: '1px solid' + theme.palette.grey[50],
    '.fl-action-btn': {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      '.btn': {
        padding: '12px 16px',
        fontSize: theme.typography.subtitle2.fontSize,
        lineHeight: theme.typography.subtitle2.lineHeight,
        marginRight: '8px',
        ':last-child': {
          marginRight: 0
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      '.btn-tabs': {
        width: '100%',
        '.btn': {
          flexWrap: 'nowrap',
          flexGrow: 1,
          '&:last-of-type': {
            width: 'auto !important'
          }
        }
      },
      '.fl-action-btn': {
        justifyContent: 'center'
      }
    }
  }
}))

export const FinanceLeaseHeader: FC = () => {
  const theme = useTheme()
  return (
    <FinanceLeaseHeaderWrap theme={theme} className="fl-header-wrap">
      <Grid theme={theme} container columnSpacing={1}>
        <Grid theme={theme} item lg={3} md={4} sm={12} xs={12} mb={1}>
          <BtnTabs theme={theme} className="btn-tabs">
            <Button
              theme={theme}
              type="button"
              text="Single Vehicle"
              defaultBtn
              className="selected"
              onClick={() => {}}
            />
            <Button
              theme={theme}
              type="button"
              text="Multi Vehicle"
              defaultBtn
              onClick={() => {}}
            />
          </BtnTabs>
        </Grid>
        <Grid theme={theme} item lg={9} md={8} sm={12} xs={12}>
          <Box theme={theme} className="fl-action-btn">
            <Button theme={theme} secondary text="Send to Customer" onClick={() => {}} />
            <Button theme={theme} secondary text="Save" onClick={() => {}} />
            <Button theme={theme} primary text="Continue Order" onClick={() => {}} />
          </Box>
        </Grid>
      </Grid>
    </FinanceLeaseHeaderWrap>
  )
}
