import Http from '@helpers/http-api'
import { useMutation, useQuery } from 'react-query'
import {
  EP_ADD_ON_ACCESSORIES,
  EP_GET_MASTER_LIST_ACCESSORIES,
  EP_GET_OPTIONS,
  EP_SAVE_ADD_ON,
  EP_VEHICLE_DETAIL
} from '@helpers/endpoints'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'
import { getUser, getMarketPlaceToken } from '@helpers/methods'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}

export const useSaveAddOn = (): any => {
  const dealerData = getUser()
  const { data, isLoading, mutate } = useMutation<
    {
      offeredBy: string
      description: string
      productName: string
      category: string
      specficCategory: string
      vehicleCondition: string
      partNo: number
      supplier: string
      installedMode: boolean
      price: number
      rvAdder: string
      compatibleModels: number[]
      status: string
      trim_image: string
      dealerCode: string
    },
    any
  >(
    (body: any) => {
      body.dealer_code = dealerData.dealer_code
      body.price = parseFloat(body.price)
      const apiService = Http.createConnection()
      return apiService.post<any>(`${EP_SAVE_ADD_ON}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateAddOn = (): any => {
  const dealerData = getUser()
  const { data, isLoading, mutate } = useMutation<
    {
      id: number
      offeredBy: string
      description: string
      productName: string
      category: string
      specficCategory: string
      vehicleCondition: string
      partNo: number
      supplier: string
      installedMode: boolean
      price: number
      rvAdder: string
      compatibleModels: number[]
      status: string
      trim_image: string
      dealerCode: string
    },
    any
  >(
    (body: any) => {
      body.dealerCode = dealerData.dealer_code
      body.price = parseFloat(body.price)
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_SAVE_ADD_ON}/${body.id}`, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useAddonsAccessories = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{ dealer_code: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      let params = body.data ? `?${body.data}` : ''
      return apiService.get<any>(
        params ? `${EP_ADD_ON_ACCESSORIES}/${params}` : EP_ADD_ON_ACCESSORIES
      )
    },
    {
      onSuccess(_response: any) {
        actions.setAddonInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

export const useGetMasterListAccessories = (): any => {
  const { data, isLoading, error, mutate } = useMutation(
    () => {
      const apiService = Http.createConnection()
      return apiService.get<any>(EP_GET_MASTER_LIST_ACCESSORIES)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}
export const useUpdateAddonById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number; isActive: boolean }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_SAVE_ADD_ON}/${body.id}`, {
        isActive: body.isActive
      })
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useDeleteAddonById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_SAVE_ADD_ON}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetAddonById = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_SAVE_ADD_ON}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const getTrimCode = (dealer_code: string, trim_code: string | undefined) => {
  const apiService = Http.createConnection({
    baseUrl: process.env.API_GATEWAY
  })
  return apiService
    .get(`${EP_GET_OPTIONS}?dealer_code=${dealer_code}&trim_code=${trim_code}`, {}, { headers })
    .then((res: any) => {
      return res
    })
}

export const useGetTrimCode = (dealer_code: string, trim_code: string | undefined) => {
  return useQuery(
    QueryKeys.GET_ADD_ON,
    () => {
      return getTrimCode(dealer_code, trim_code)
    },
    { enabled: !!dealer_code && !!trim_code }
  )
}

export const useGetVehicleDetails = (): any => {
  const { data, isLoading, error, mutate } = useMutation<{ vin: string }, any>(
    (body: any) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.API_GATEWAY
      })
      return apiService.get<any>(`${EP_VEHICLE_DETAIL}/${body.vin}`)
    },
    {
      onSuccess(_response: any) {}
    }
  )
  return { data, isLoading, error, mutate }
}
