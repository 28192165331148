export enum FundingType {
  CARD = 'card',
  CREDIT = 'credit',
  ACH_DEBIT = 'ach_debit',
  CHECKING = 'checking'
}

export enum PaymentMode {
  CREDIT_CARD = 'Credit Card',
  ACH = 'ACH',
  CHEQUE = 'Cheque'
}
