import { FC, useEffect } from 'react'
import { useTheme } from '@mui/material'

import { Box, Typography } from '@ntpkunity/controls'
import { DetailCard } from '@components'

import AvailableCheckResponse from './available-check-response'
import ReadyCheckResponse from './ready-checklist-responses'
import ScheduleCheckResponse from './schedule-dealer-response'
import DealerReturnResponse from './dealer-return-responses'
import { useGetContractData } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { useParams } from 'react-router-dom'

const DealerResponses: FC = () => {
  const theme = useTheme()
  const { mutate: getContractsData, data: contractsData } = useGetContractData()
  const { states } = useStoreContext()
  const { id } = useParams()

  useEffect(() => {
    // Set the state based on the result
    if (states?.dealerSelectedOrderData?.customerId && id != undefined) {
      getContractsData({
        customer_ref_id: states?.dealerSelectedOrderData?.customerId,
        external_order_id: id
      })
    }
  }, [states?.dealerSelectedOrderData?.customerId, id])

  return (
    <>
      <DetailCard className="detail-card payment-bd" mb={3}>
        <Box theme={theme} className="card-header">
          <Typography theme={theme} component="h3" variant="h3">
            Dealer Responses
          </Typography>
        </Box>
        <Box theme={theme} className="line">
          <hr />
        </Box>
        <AvailableCheckResponse />
        <ReadyCheckResponse />
        <ScheduleCheckResponse />
        {contractsData && contractsData.returns !== null ? (
          <DealerReturnResponse contractsData={contractsData} />
        ) : null}
      </DetailCard>
    </>
  )
}
export default DealerResponses
