import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import FLCalculator from './finance-lease-calculator'
import PaymentDetails from './payment-details'
import { FinanceLeaseHeader } from './finance-lease-header'

const CalculationSection: FC = () => {
  const theme = useTheme()
  return (
    <>
      <FinanceLeaseHeader />
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2}>
        <Grid theme={theme} item xs={6}>
          <FLCalculator />
        </Grid>
        <Grid theme={theme} item xs={6}>
          <PaymentDetails />
        </Grid>
      </Grid>
    </>
  )
}

export default CalculationSection
