import IActions from '@store/configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_MAKE_MODEL_TRIM = 'Get Make Model Trim'
export const initialState: any = {
  asset_make_id: undefined,
  asset_model_id: undefined,
  asset_trim_id: undefined
}

const MakeModelTrimReducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case SET_MAKE_MODEL_TRIM:
      return { ...state, ...(action.payload as typeof initialState) }
  }
  return state
}
export const setMakeModelTrimData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof MakeModelTrimReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_MAKE_MODEL_TRIM, payload: data })
  }
}
export default MakeModelTrimReducer
