import { styled } from '@mui/material/styles'

export const ProductBanner: any = styled('div')(({ theme }) => ({
  backgroundColor: theme.customVariables.tableStripedBg,
  padding: 40,
  marginBottom: 24,
  borderRadius: 8,
  fontFamily: theme.typography.fontFamily,

  '.thumbnail': {
    width: '100%',
    height: 144,
    display: 'flex',
    verticalAlign: 'middle',
    textAlign: 'center',

    img: {
      maxWidth: '100%',
      maxHeight: 144,
      width: 'auto',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    }
  },

  '.product-details': {
    paddingTop: 14,
    '.title-md': {
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: theme.typography.subtitle1.lineHeight,
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.grey[600],
      width: '100%',
      display: 'block',
      clear: 'both'
    },

    '.title-lg': {
      fontSize: theme.typography.h3.fontSize,
      lineHeight: theme.typography.h3.lineHeight,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900],
      width: '100%',
      display: 'block',
      clear: 'both',
      marginBottom: 24
    },

    '.title-sm': {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.grey[600],
      width: '100%',
      display: 'block',
      clear: 'both',

      b: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[900]
      }
    },
    '.title-xs': {
      fontSize: '10px'
    },
    '.top-hide': {
      opacity: 0,
      visibility: 'hidden'
    },

    '.bottom': {
      position: 'relative',
      top: 35
    },

    a: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },

    '.actions-wrap': {
      display: 'flex',
      width: '100%',
      justifyContent: 'end',
      gap: 40,

      '.actions': {
        width: 155,

        '.btn:first-child': {
          marginBottom: 16
        }
      }
    }
  },
  '.product-opions': {
    display: 'inline-flex',
    flexDirection: 'column',
    Button: {
      clear: 'both',
      '&:first-child': {
        marginBottom: 16
      }
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'row',
      Button: {
        flex: 1,
        '&:first-child': {
          marginBottom: 0,
          marginRight: 16
        }
      }
    }
  }
}))
