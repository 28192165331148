export enum Status {
  Inquiry = 'Inquiry',
  AvailableInInventory = 'Available In Inventory',
  InContarct = 'In Contarct',
  Available = 'Available',
  Draft = 'Draft', //Orders
  Submitted = 'Submitted', //Orders
  Approved = 'Approved', //Orders
  Conditioned = 'Conditioned', //Orders
  InReview = 'In Review', //Orders
  Reviewed = 'Reviewed', //Orders
  ReSubmitted = 'Re Submitted', //Orders
  SchedulePickup = 'Awaiting Scheduling', //Schedule Pickup/delivery
  Appointment = 'Appointment Scheduled', //Appointment
  Cancelled = 'Cancelled', //Cancelled
  Converted = 'Converted', //Completed
  Completed = 'Completed', //Completed
  PendingConfirmation = 'Pending Confirmation',
  PendingTradeInConfirmation = 'Pending Trade-In Confirmation',
  PendingTradeInInspection = 'Trade-In Inspection',
  Declined = 'Declined', // Declined
  Withdrawn = 'Withdrawn' // Withdrawn
}

export enum InspectionStatus {
  Pending = 'Pending',
  Completed = 'Completed'
}

export enum TradeInMessage {
  InspectionPending = 'Physical Inspection for trade-in has been requested',
  InspectionCompleted = 'Physical Inspection for trade-in has been completed'
}
