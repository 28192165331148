import React, { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Dialog, Icon, Typography } from '@ntpkunity/controls'
import { BtnAddWrap, VehicleList } from '@components'

const AddOpptions: FC = () => {
  const theme = useTheme()
  const [openVehicleDialog, setVehicleOpenDialog] = React.useState(false)
  const handleVehicleOpen = () => {
    setVehicleOpenDialog(true)
  }
  const handleVehicleClose = () => {
    setVehicleOpenDialog(false)
  }

  const VlItem: FC = () => {
    return (
      <>
        <Box theme={theme} className="vl-img-wrap">
          <img src={require('../../public/assets/images/vc-img.png')} alt="Car" />
        </Box>
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children="Sample Option Name"
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={<b>$000.00</b>}
          />
        </Box>
      </>
    )
  }

  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Options"
          size="medium"
          onClick={handleVehicleOpen}
        />
      </BtnAddWrap>
      <VehicleList theme={theme} className="vehicle-list" mt={1}>
        <ul className="vl-scroll">
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
          <li className="vl-item sm">
            <VlItem />
          </li>
        </ul>
      </VehicleList>
      <Dialog
        variant={undefined}
        size="md"
        title="Add Options"
        open={openVehicleDialog}
        onCloseDialog={handleVehicleClose}
        customFooter={
          <Button theme={theme} primary text="Add Option" onClick={handleVehicleClose} />
        }
        theme={theme}
        children={'Options List'}
      />
    </>
  )
}

export default AddOpptions
