let payload: any = {}

export const mapPayload = (
  dealer_code?: number,
  customerReference?: string,
  keyName?: string,
  obj?: any,
  keyName2?: string
) => {
  payload.reference_id = customerReference
  payload.dealer_id = dealer_code

  if (!payload.data) {
    payload.data = {}
  }

  if (keyName) {
    if (keyName2) {
      payload.data[keyName] = payload.data[keyName] || {}
      payload.data[keyName][keyName2] = obj
    } else {
      payload.data[keyName] = obj
    }
  }

  return payload
}

export const empty = () => {
  payload = {}
}
