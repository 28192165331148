import { useGetTenantConsent } from '@apis/customer-management.service'
import { ConsentStatus, DisclaimerNames } from '@helpers/constants'
import { useEffect, useState } from 'react'

export const useDisclaimers = (requiredDisclaimers: string | any[]) => {
  const { data: _disclaimers, isLoading: disclaimersLoading } = useGetTenantConsent()
  const [disclaimers, setDisclaimers] = useState<typeof DisclaimerNames>()

  useEffect(() => {
    if ((_disclaimers ?? []).length > 0) {
      const filteredDisclaimers = _disclaimers
        ?.filter(
          (disclaimer: any) =>
            disclaimer.status === ConsentStatus.PUBLISHED &&
            requiredDisclaimers.includes(disclaimer.name)
        )
        ?.reduce(
          (agg: any, disclaimer: any) => ({
            ...agg,
            [disclaimer.name]: disclaimer.description
          }),
          {}
        )

      if (Object.keys(filteredDisclaimers).length > 0) {
        setDisclaimers(filteredDisclaimers)
      }
    }
  }, [_disclaimers])

  return { disclaimers, disclaimersLoading }
}
