import { FC, useEffect } from 'react'
import { Link, useTheme } from '@mui/material'
import { Box, Typography, Icon, Tooltip, Button, Grid } from '@ntpkunity/controls'
import { ProductDetailDialog } from '@components'

const FinancialInsurancePopupDetails: FC<{
  details?: any
  rates: any
  selectedProducts: any
  setSelectedFinanceItems?: any
  setSelectedLeaseItems?: any
  selectedFinanceItems?: any
  selectedLeaseItems?: any
  activeTab: number
  defaultCurrency?: any
  fniPrice?: any
  setFniPrice?: any
}> = ({
  details,
  rates,
  selectedProducts,
  activeTab,
  setSelectedFinanceItems,
  setSelectedLeaseItems,
  selectedFinanceItems,
  selectedLeaseItems,
  defaultCurrency,
  fniPrice,
  setFniPrice
}) => {
  const theme = useTheme()
  const handleBrochureLink = () => {
    let url = details?.brochure_url
    window.open(url)
  }
  const handleVideoLink = () => {
    let url = details?.video_url
    window.open(url)
  }
  useEffect(() => {
    if (
      activeTab == 0 &&
      details?.price &&
      !fniPrice?.finance?.some((x: any) => x.id == details?.id)
    )
      setFinancePrice(
        details?.price,
        details?.id,
        details?.product_id,
        details?.rate_id,
        details?.form_id
      )
    else if (
      activeTab == 1 &&
      details?.price &&
      !fniPrice?.lease?.some((x: any) => x.id == details?.id)
    )
      setLeasePrice(
        details?.price,
        details?.id,
        details?.product_id,
        details?.rate_id,
        details?.form_id
      )
  }, [])
  const hideButton = (_product_id: number, price: number, rate_id: number) => {
    if (activeTab == 0) {
      return fniPrice?.finance?.find((x: any) => x?.price == price && x?.rate_id == rate_id)
    } else {
      return fniPrice?.lease?.find((x: any) => x?.price == price && x?.rate_id == rate_id)
    }
  }
  const setFinancePrice = (
    price: number,
    id: number,
    product_id: number,
    rate_id: number,
    form_id: number
  ) => {
    setFniPrice((prevState: any) => ({
      ...prevState,
      finance: [
        ...prevState.finance,
        {
          price: price,
          id: id,
          product_id: product_id,
          rate_id: rate_id,
          form_id
        }
      ]
    }))
  }

  const setLeasePrice = (
    price: number,
    id: number,
    product_id: number,
    rate_id: number,
    form_id: number
  ) => {
    setFniPrice((prevState: any) => ({
      ...prevState,
      lease: [
        ...prevState.lease,
        {
          price: price,
          id: id,
          product_id: product_id,
          rate_id: rate_id,
          form_id
        }
      ]
    }))
  }
  const handleOnClick = (price: number, product_id: number, rate_id: number, form_id: number) => {
    if (activeTab == 0) {
      //if price is not added against product
      if (!fniPrice?.finance?.some((x: any) => x.id == details?.id))
        setFinancePrice(price, details?.id, product_id, rate_id, form_id)
      //if price is already added against that product then update price only
      else {
        setFniPrice((prevState: any) => ({
          ...prevState,
          finance: prevState?.finance?.map((item: any) =>
            item.id === details?.id ? { ...item, price: price, rate_id, form_id } : item
          )
        }))
      }
      //if product already added then update
      if (selectedFinanceItems?.some((x: any) => x.id == details?.id)) {
        setSelectedFinanceItems((prevItem: any) => {
          return prevItem?.map((x: any) => {
            return x.id == details?.id ? { ...x, price: price, rate_id, form_id } : x
          })
        })
      }
    } else {
      //For Lease Case
      if (!fniPrice?.lease?.some((x: any) => x.id == details?.id))
        setLeasePrice(price, details?.id, product_id, rate_id, form_id)
      //if price is already added against that product then update price only
      else {
        setFniPrice((prevState: any) => ({
          ...prevState,
          lease: prevState?.lease?.map((item: any) =>
            item.id === details?.id ? { ...item, price: price, rate_id, form_id } : item
          )
        }))
      }
      if (selectedLeaseItems?.some((x: any) => x.id == details?.id)) {
        //if product already added then update
        setSelectedLeaseItems((prevItem: any) => {
          return prevItem?.map((x: any) => {
            return x.id == details?.id ? { ...x, price: price, rate_id, form_id } : x
          })
        })
      }
    }
  }

  const getActualPrice = (price: number): any => {
    return (price / (1 + details?.markup / 100)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }
  const getMarkupPrice = (price: number): any => {
    return (price - getActualPrice(price)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }
  return (
    <ProductDetailDialog theme={theme}>
      <Box theme={theme} className="img-wrap">
        <img
          src={
            details?.image[0]?.location ?? require('../../../../public/assets/images/no-img.svg')
          }
          alt="Product"
        />
      </Box>
      <Box theme={theme} className="" sx={{ mt: 3 }}>
        <Typography theme={theme} component="p" variant="body2">
          {details?.provider_name}
        </Typography>
        <Typography theme={theme} component="h3" variant="h3">
          {details?.product_name}
        </Typography>
        <Typography theme={theme} component="p" variant="body2" sx={{ mt: 1 }}>
          {details?.product_type}
        </Typography>
      </Box>
      <Typography theme={theme} component="p" variant="body1" sx={{ mt: 3 }}>
        {details?.description}
      </Typography>
      <Box theme={theme} className="" sx={{ mt: 3 }}>
        {details?.brochure_url && (
          <Box theme={theme}>
            <Link className="custom-link" onClick={handleBrochureLink}>
              <Typography
                theme={theme}
                variant="body2"
                component={'span'}
                className="custom-link"
                children={
                  <>
                    View Product Brochure <Icon name="IconLeftArrow" />
                  </>
                }
              />
            </Link>
          </Box>
        )}
        {details?.video_url && (
          <Link className="custom-link" onClick={handleVideoLink}>
            <Typography
              theme={theme}
              variant="body2"
              component={'span'}
              children={
                <>
                  View Product Video <Icon name="IconLeftArrow" />
                </>
              }
            />
          </Link>
        )}
      </Box>
      {(Array.isArray(rates) ? rates : rates ? [rates] : [])?.map((object: any, index: any) => (
        <Box theme={theme} sx={{ mt: 3 }} key={index}>
          {selectedProducts === object.product_id && (
            <>
              {object.coverages?.coverage.map((coverage: any, index: any) => (
                <Box theme={theme} className="mini-card" sx={{ mt: 2 }} key={index}>
                  <Grid theme={theme} direction="row" container>
                    <Grid theme={theme} item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Typography
                        theme={theme}
                        component="p"
                        variant="body2"
                        children={coverage.coverage_name.concat(
                          ` - ${coverage?.term_months} Months`
                        )}
                      />
                      <Box theme={theme} className="title-tooltip">
                        <Typography
                          theme={theme}
                          component="p"
                          variant="subtitle1"
                          children={`${defaultCurrency}${coverage.deductibles?.deductible?.retail_price.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }
                          )}`}
                        />
                        <Tooltip
                          theme={theme}
                          title={
                            <Box theme={theme} sx={{ width: 200 }}>
                              Provider Price: {defaultCurrency}
                              {getActualPrice(coverage.deductibles?.deductible?.retail_price)}
                              <br />
                              Markup: {defaultCurrency}
                              {getMarkupPrice(coverage.deductibles?.deductible?.retail_price)} (
                              {details?.markup}%)
                            </Box>
                          }
                          disablePortal={true}
                          placement="right"
                        >
                          <Button
                            defaultBtn
                            theme={theme}
                            iconText={<Icon name="InfoIcon" />}
                          ></Button>
                        </Tooltip>
                      </Box>
                    </Grid>

                    <Grid
                      theme={theme}
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ textAlign: 'right' }}
                    >
                      {!hideButton(
                        object?.product_id,
                        coverage.deductibles?.deductible?.retail_price,
                        coverage.deductibles?.deductible?.rate_id
                      ) && (
                        <Button
                          theme={theme}
                          secondary
                          text={'Add'}
                          onClick={() => {
                            handleOnClick(
                              coverage.deductibles?.deductible?.retail_price,
                              object?.product_id,
                              coverage.deductibles?.deductible?.rate_id,
                              coverage?.form?.form_id
                            )
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </>
          )}
        </Box>
      ))}
    </ProductDetailDialog>
  )
}

export default FinancialInsurancePopupDetails
