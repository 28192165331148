import { FC } from 'react'
import { useTheme, styled } from '@mui/material'
import { Box, Chip, Typography, IBoxProps } from '@ntpkunity/controls'
import { TableList } from '@components'

export const VehicleAmountWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.vehicle-amount-wrap': {
    padding: '8px',
    marginBottom: '8px',
    borderRadius: 8,
    textAlign: 'center',
    '.u-custom-chip': {
      borderRadius: 8,
      height: 'auto',
      marginBottom: '8px',
      '.MuiChip-label': {
        fontSize: '12px',
        textTransform: 'uppercase',
        padding: '2px 8px'
      }
    }
  }
}))

const VehicleAmountDraft: FC = () => {
  const theme = useTheme()
  return (
    <VehicleAmountWrap theme={theme} className="vehicle-amount-wrap grey-bg">
      <Chip
        theme={theme}
        label="DRAFT"
        component="div"
        clickable={false}
        variant="filled"
        href=""
      />
      <Typography
        theme={theme}
        component="p"
        variant="caption"
        sx={{ mb: 1 }}
        className="text-overflow"
        children={
          <>
            ${' '}
            <Typography
              theme={theme}
              component="span"
              variant="h3"
              sx={{ verticalAlign: 'middle' }}
            >
              000
            </Typography>{' '}
            /mon
          </>
        }
      />
      <TableList
        withBg
        items={[
          {
            title: 'Type',
            value: 'Finance'
          },
          {
            title: 'Lender',
            value: 'Sample Lender',
            hasPrimaryText: true
          },
          {
            title: 'Down Payment',
            value: '$00,000.00'
          },
          {
            title: 'Term',
            value: '00'
          },
          {
            title: 'Rate',
            value: '0.0000%'
          }
        ]}
      />
    </VehicleAmountWrap>
  )
}

export default VehicleAmountDraft
