import { useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Grid,
  Icon,
  Menu,
  Select,
  //Switch,
  Typography
} from '@ntpkunity/controls'
import BlankFrame from '../../public/assets/images/AffordabilityCheck_BlankFrame.svg'
import { ResponsiveCarousel, VehicleCardUi } from '@components'
import { useModal } from 'react-modal-hook'
import { AssetDetailCardView } from './assetDetailCards'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '@store/storeContext'
import { APP_ROUTES } from '@helpers/links'

export const VehicleCardView: FC<{
  vehicleDetails?: any
  programData: any
  finalTerms: any
  defaultCurrency: string
  balloonPayment: string
  creditScore?: string
}> = ({
  vehicleDetails,
  programData,
  finalTerms,
  defaultCurrency,
  balloonPayment,
  creditScore
}) => {
  const theme = useTheme()
  let navigate = useNavigate()
  const [vehiclePopup, setVehiclePopup] = useState<any>()

  const [openVdModal, closeVdModal] = useModal(
    () => (
      <>
        <Dialog
          disablePortal
          className="dialog-with-border"
          theme={theme}
          title={'Vehicle Details'}
          open
          onCloseDialog={closeVdModal}
          noFooter
        >
          <VehicleCardUi theme={theme} className="vehicle-card full-width vd-popup m-dialog">
            <Box
              className="vc-info-head"
              display={'flex'}
              justifyContent={'space-between'}
              theme={theme}
            >
              <Box className="vc-type-name" theme={theme}>
                <Typography
                  className="vc-type text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                  theme={theme}
                >
                  {vehiclePopup?.year} {vehiclePopup?.make} {vehiclePopup?.model}
                </Typography>
                <Typography
                  className="vc-name text-dark"
                  display={'block'}
                  variant="h3"
                  component="h3"
                  theme={theme}
                >
                  {vehiclePopup?.trim_description}
                </Typography>
              </Box>
              <Box className="price-info" theme={theme}>
                <Typography
                  className="price-title text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                  theme={theme}
                >
                  Selling Price
                </Typography>
                <Typography
                  className="vc-price text-primary"
                  display={'block'}
                  variant="h3"
                  component="h3"
                  theme={theme}
                >
                  {defaultCurrency}
                  {vehiclePopup?.internet_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            </Box>
            {vehiclePopup?.photo_urls?.length > 0 && (
              <ResponsiveCarousel responsive={responsive} autoPlaySpeed={5000}>
                {vehiclePopup?.photo_urls?.map((obj: any, index: any) => (
                  <Box className="vc-img-wrap" key={index} theme={theme}>
                    <img src={obj?.location} alt="vehicle" />
                  </Box>
                ))}
              </ResponsiveCarousel>
            )}
            {vehiclePopup?.photo_urls?.length <= 0 && <Icon name="NoPreviewAvailable" />}

            <Box className="vc-features" theme={theme}>
              <Typography
                className="vf-title text-dark"
                display={'block'}
                variant="h4"
                component="h4"
                theme={theme}
              >
                Vehicle Features & Specifications
              </Typography>
              <Grid container item spacing={3} theme={theme}>
                <Grid item xs={6} md={3} lg={4} theme={theme}>
                  <Box className="feat-item" theme={theme}>
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                      theme={theme}
                    >
                      Drivetrain:
                    </Typography>
                    <Typography
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                      theme={theme}
                    >
                      {vehiclePopup?.drive_train}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={4} theme={theme}>
                  <Box className="feat-item" theme={theme}>
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                      theme={theme}
                    >
                      Engine:
                    </Typography>
                    <Typography
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                      theme={theme}
                    >
                      {vehiclePopup?.engine_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={4} theme={theme}>
                  <Box className="feat-item" theme={theme}>
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                      theme={theme}
                    >
                      Exterior Color:
                    </Typography>
                    <Typography
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                      theme={theme}
                    >
                      {vehiclePopup?.exterior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={4} theme={theme}>
                  <Box className="feat-item" theme={theme}>
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                      theme={theme}
                    >
                      Interior Color:
                    </Typography>
                    <Typography
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                      theme={theme}
                    >
                      {vehiclePopup?.interior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={4} theme={theme}>
                  <Box className="feat-item" theme={theme}>
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                      theme={theme}
                    >
                      Transmission Type:
                    </Typography>
                    <Typography
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                      theme={theme}
                    >
                      {vehiclePopup?.transmission_type}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3} lg={4} theme={theme}>
                  <Box className="feat-item" theme={theme}>
                    <Typography
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                      theme={theme}
                    >
                      Stock Number:
                    </Typography>
                    <Typography
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                      theme={theme}
                    >
                      {vehiclePopup?.stock_number}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </VehicleCardUi>
        </Dialog>
      </>
    ),
    [vehiclePopup]
  )
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
  const [uniqueBodyTypes, setUniqueBodyTypes] = useState([])
  const [uniqueFuelTypes, setUniqueFuelTypes] = useState([])
  const [uniqueMakes, setUniqueMakes] = useState([])
  const [filters, setFilters] = useState<{
    asset_make: string
    asset_model: string
    asset_trim: string
    body_type: Array<string>
    fuel_type: Array<string>
  }>({ asset_make: '', asset_model: '', asset_trim: '', body_type: [], fuel_type: [] })
  const [availableTrims, setAvailableTrims] = useState<any>()
  const [availableModels, setAvailableModels] = useState<any>()
  const [allVehicles, setAllVehicles] = useState<any>(vehicleDetails)
  const { actions } = useStoreContext()
  useEffect(() => {
    if (vehicleDetails?.length > 0) {
      setAllVehicles(vehicleDetails)
      const bodyTypes: any = [
        ...new Set(vehicleDetails.map((vehicle: any) => vehicle.vehicle.body_type))
      ]
      const fuelTypes: any = [
        ...new Set(vehicleDetails.map((vehicle: any) => vehicle.vehicle.fuel_type))
      ]
      const makes: any = [...new Set(vehicleDetails.map((vehicle: any) => vehicle.vehicle.make))]

      setUniqueBodyTypes(bodyTypes)
      setUniqueFuelTypes(fuelTypes)
      setUniqueMakes(makes.map((make: string) => ({ text: make, value: make })))
      if (!hideResetButton()) {
        filterAssets()
      }
    }
  }, [vehicleDetails])

  useEffect(() => {
    if (vehicleDetails?.length > 0) {
      const uniqueModelsSet: any = new Set(
        vehicleDetails
          ?.filter(
            (vehicle: any) => !filters?.asset_make || vehicle.vehicle.make === filters?.asset_make
          )
          ?.map((vehicle: any) => vehicle.vehicle.model)
      )

      const uniqueModelsArray: string[] = [...uniqueModelsSet]

      setAvailableModels(uniqueModelsArray.map((model: string) => ({ text: model, value: model })))

      const filteredTrims: any = new Set(
        vehicleDetails
          ?.filter(
            (vehicle: any) =>
              (!filters?.asset_make || vehicle.vehicle.make === filters?.asset_make) &&
              (!filters?.asset_model || vehicle.vehicle.model === filters?.asset_model)
          )
          ?.map((vehicle: any) => vehicle.vehicle.trim_description)
      )
      const uniqueTrimsArray: string[] = [...filteredTrims]

      setAvailableTrims(uniqueTrimsArray.map((trim: string) => ({ text: trim, value: trim })))
    }
  }, [vehicleDetails, filters])

  const hideResetButton = () => {
    if (
      filters.asset_make === '' &&
      filters.asset_model === '' &&
      filters.asset_trim === '' &&
      filters.body_type.length === 0 &&
      filters.fuel_type.length === 0
    ) {
      return true
    }
    return false
  }
  const orderInitiation = (vin: string, terms: any) => {
    localStorage.setItem('closeModal', 'false')
    localStorage.removeItem('step')
    navigate(APP_ROUTES.CREATE_ORDER)
    actions?.setQuotationDefaultParam({ defaultCreditRating: creditScore, defaultTerms: terms })
    navigate('/dms/create-order/' + vin)
  }
  const filterAssets = () => {
    let filteredResultSet = vehicleDetails
    let isBodyTypeFiltered = false
    if (filters?.body_type?.length > 0) {
      isBodyTypeFiltered = true
      filteredResultSet = vehicleDetails?.filter((vehicle: any) =>
        filters?.body_type.includes(vehicle.vehicle.body_type)
      )
    }
    if (filters?.fuel_type?.length > 0) {
      if (!isBodyTypeFiltered) {
        filteredResultSet = vehicleDetails
      }
      filteredResultSet = filteredResultSet?.filter((vehicle: any) =>
        filters?.fuel_type.includes(vehicle.vehicle.fuel_type)
      )
    }
    if (filters?.asset_make) {
      filteredResultSet = filteredResultSet?.filter(
        (vehicle: any) => vehicle.vehicle.make == filters?.asset_make
      )
    }
    if (filters?.asset_model) {
      filteredResultSet = filteredResultSet?.filter(
        (vehicle: any) => vehicle.vehicle.model == filters?.asset_model
      )
    }
    if (filters?.asset_trim) {
      filteredResultSet = filteredResultSet?.filter(
        (vehicle: any) => vehicle.vehicle.trim_description == filters?.asset_trim
      )
    }

    setAllVehicles(filteredResultSet)
  }
  useEffect(() => {
    filterAssets()
  }, [filters])
  return (
    <Box theme={theme} className="grey-bg">
      {/*********** Dropdown Filters ***********/}
      {vehicleDetails?.length > 0 ? (
        <>
          <Box theme={theme} className="filters-wrap">
            <Menu
              theme={theme}
              disablePortal
              options={[]}
              render={(onMenuSelection) => (
                <Button
                  theme={theme}
                  secondary
                  text="All Make/Model/Trim"
                  onClick={onMenuSelection}
                />
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              customChildren={
                <>
                  <Typography
                    theme={theme}
                    className="title w-max"
                    component="p"
                    variant="subtitle1"
                    children="Make / Model / Trim"
                  />
                  <Select
                    theme={theme}
                    label={'Make'}
                    items={uniqueMakes}
                    value={filters?.asset_make}
                    fullWidth
                    onChange={(event: any) => {
                      setFilters({
                        ...filters,
                        asset_make: event.target.value,
                        asset_model: '',
                        asset_trim: ''
                      })
                    }}
                  />

                  <Select
                    theme={theme}
                    label={'Model'}
                    items={availableModels}
                    disabled={!filters?.asset_make}
                    value={filters?.asset_model}
                    fullWidth
                    onChange={(event: any) => {
                      setFilters({ ...filters, asset_model: event.target.value, asset_trim: '' })
                    }}
                  />
                  <Select
                    theme={theme}
                    label={'Trim'}
                    disabled={!filters?.asset_model}
                    value={filters?.asset_trim}
                    items={availableTrims}
                    onChange={(event: any) => {
                      setFilters({ ...filters, asset_trim: event.target.value })
                    }}
                    fullWidth
                  />
                </>
              }
            />
            <Menu
              theme={theme}
              disablePortal
              options={[]}
              render={(onMenuSelection) => (
                <Button theme={theme} secondary text="Body Type" onClick={onMenuSelection} />
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              customChildren={
                <>
                  <Typography
                    theme={theme}
                    className="title"
                    component="p"
                    variant="subtitle1"
                    children="Body Type"
                  />
                  {uniqueBodyTypes.map((option) => (
                    <Checkbox
                      key={option}
                      theme={theme}
                      value={option}
                      label={option}
                      checkBoxChecked={
                        filters?.body_type?.find((x: any) => x === option) ? true : false
                      }
                      checkBoxValue={option}
                      onChange={(event: any) => {
                        if (event.target.checked) {
                          setFilters({ ...filters, body_type: [...filters.body_type, option] })
                        } else {
                          setFilters({
                            ...filters,
                            body_type: filters.body_type.filter((x) => x !== option)
                          })
                        }
                      }}
                    />
                  ))}
                </>
              }
            />
            <Menu
              theme={theme}
              disablePortal
              options={[]}
              render={(onMenuSelection) => (
                <Button theme={theme} secondary text="Fuel Type" onClick={onMenuSelection} />
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              customChildren={
                <>
                  <Typography
                    theme={theme}
                    className="title"
                    component="p"
                    variant="subtitle1"
                    children="Fuel Type"
                  />
                  {uniqueFuelTypes.map((option) => (
                    <Checkbox
                      key={option}
                      theme={theme}
                      label={option}
                      checkBoxChecked={
                        filters?.fuel_type?.find((x: any) => x === option) ? true : false
                      }
                      checkBoxValue={option}
                      onChange={(event: any) => {
                        if (event.target.checked) {
                          setFilters({ ...filters, fuel_type: [...filters.fuel_type, option] })
                        } else {
                          setFilters({
                            ...filters,
                            fuel_type: filters.fuel_type.filter((x) => x !== option)
                          })
                        }
                      }}
                    />
                  ))}
                </>
              }
            />
            {!hideResetButton() && (
              <Button
                theme={theme}
                className="btn-reset text-primary"
                text="Reset All Filters"
                onClick={() => {
                  setFilters({
                    asset_make: '',
                    asset_model: '',
                    asset_trim: '',
                    body_type: [],
                    fuel_type: []
                  })
                }}
              />
            )}
          </Box>
          <Box theme={theme} className="scroll-container">
            {/*********** Card Grid View ***********/}
            <Grid
              sx={{ display: 'none !important' }}
              theme={theme}
              container
              columnSpacing={1}
              rowSpacing={1}
              mb={2}
              className="grid-card-wrap"
            >
              {/*********** Card Grid View ***********/}
              {vehicleDetails?.map((vehicleDetail: any) => (
                <Grid theme={theme} item xs={6} key={vehicleDetail?.id}>
                  <VehicleCardUi className="vehicle-card full-width no-border border-radius-lg">
                    <Grid container spacing={2} theme={theme} sx={{ mb: 2 }}>
                      <Grid item xs={7} theme={theme}>
                        <Box theme={theme}>
                          <Typography
                            theme={theme}
                            variant="caption"
                            component="b"
                            textAlign="left"
                            className="w-100 text-muted"
                            children={
                              vehicleDetail?.vehicle?.year +
                              ' ' +
                              vehicleDetail?.vehicle?.make +
                              ' ' +
                              vehicleDetail?.vehicle?.model
                            }
                          />
                        </Box>
                        <Box theme={theme}>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="b"
                            textAlign="left"
                            className="w-100 text-dark"
                            children={vehicleDetail?.vehicle?.trim_description}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={5} theme={theme}>
                        <Box theme={theme}>
                          <Typography
                            theme={theme}
                            variant="caption"
                            component="b"
                            textAlign="right"
                            className="w-100 text-muted"
                          >
                            Selling Price:{' '}
                          </Typography>
                        </Box>
                        <Box theme={theme}>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="b"
                            textAlign="right"
                            className="w-100 text-dark"
                          >
                            {defaultCurrency}
                            {vehicleDetail?.vehicle?.internet_price?.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            })}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box theme={theme} className="vc-img-wrap">
                      <img
                        src={
                          vehicleDetail?.vehicle?.photo_urls &&
                          vehicleDetail?.vehicle?.photo_urls?.length > 0
                            ? vehicleDetail?.vehicle?.photo_urls[0]?.location
                            : ''
                        }
                        alt="Thumbnail"
                      />
                    </Box>
                    <Box theme={theme} className="vc-footer w-100">
                      <Box theme={theme} className="specs">
                        <Box theme={theme} className="w-100">
                          <Typography
                            theme={theme}
                            variant="caption"
                            component="span"
                            className="text-muted"
                            sx={{ mr: 0.5 }}
                          >
                            Stock:
                          </Typography>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="span"
                            className="text-dark subtitle2"
                          >
                            {vehicleDetail?.vehicle?.stock_number}
                          </Typography>
                        </Box>
                        <Box theme={theme} className="w-100">
                          <Typography
                            theme={theme}
                            variant="caption"
                            component="span"
                            className="text-muted"
                            sx={{ mr: 0.5 }}
                          >
                            VIN:
                          </Typography>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="span"
                            className="text-dark subtitle2"
                          >
                            {vehicleDetail?.vehicle?.vin}
                          </Typography>
                        </Box>
                      </Box>
                      <Box theme={theme} className="action">
                        <Button
                          theme={theme}
                          secondary
                          className="btn-view"
                          iconText={<Icon name="IcView" />}
                          onClick={() => {
                            setVehiclePopup(vehicleDetail?.vehicle)
                            openVdModal()
                          }}
                        />
                        <Button
                          theme={theme}
                          primary
                          text="Initiate Order"
                          onClick={() => {
                            orderInitiation(vehicleDetail?.vehicle?.vin, finalTerms[1])
                          }}
                        />
                      </Box>
                    </Box>
                  </VehicleCardUi>
                </Grid>
              ))}
            </Grid>
            {/*********** Cards List ***********/}
            <Box theme={theme} className="card-list" display={'none'}>
              {/*********** Card List Item (Parent) ***********/}
              {allVehicles?.map((vehicleDetail: any) => (
                <Box theme={theme} key={vehicleDetail?.id}>
                  <Box theme={theme} className="card-list-item with-bg">
                    <Grid theme={theme} container columnSpacing={2}>
                      <Grid theme={theme} item xl={2.5} lg={3} md={4} sm={12} xs={12}>
                        <Box theme={theme} className="thumbnail">
                          <img
                            src={
                              vehicleDetail?.vehicle?.photo_urls &&
                              vehicleDetail?.vehicle?.photo_urls?.length > 0
                                ? vehicleDetail?.vehicle?.photo_urls[0]?.location
                                : ''
                            }
                            alt="Thumbnail"
                          />
                        </Box>
                      </Grid>
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={6} xs={6}>
                        <Typography
                          theme={theme}
                          component="small"
                          variant="caption"
                          children={
                            vehicleDetail?.vehicle?.year +
                            ' ' +
                            vehicleDetail?.vehicle?.make +
                            ' ' +
                            vehicleDetail?.vehicle?.model
                          }
                        />
                        <Typography
                          theme={theme}
                          component="h4"
                          variant="h4"
                          children={vehicleDetail?.vehicle?.trim_description}
                        />
                        <Typography
                          theme={theme}
                          component="small"
                          variant="caption"
                          children={
                            <>
                              Stock: <b>{vehicleDetail?.vehicle?.stock_number}</b>
                            </>
                          }
                        />
                        <Typography
                          theme={theme}
                          component="small"
                          variant="caption"
                          children={
                            <>
                              VIN: <b>{vehicleDetail?.vehicle?.vin}</b>
                            </>
                          }
                        />
                      </Grid>
                      <Grid
                        theme={theme}
                        item
                        xl={5.5}
                        lg={5}
                        md={4}
                        sm={6}
                        xs={6}
                        textAlign="right"
                        className="col-right"
                      >
                        <Box theme={theme} className="details">
                          <Typography
                            theme={theme}
                            component="small"
                            variant="caption"
                            children="Estimated Payment"
                          />
                          <Typography
                            theme={theme}
                            component="h4"
                            variant="h4"
                            children={
                              <>
                                {defaultCurrency}
                                {vehicleDetail?.vehicle?.estimated_monthly_payment?.toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 4,
                                    minimumFractionDigits: 2
                                  }
                                )}
                              </>
                            }
                          />
                          <Typography
                            theme={theme}
                            component="small"
                            variant="caption"
                            children={
                              <>
                                Selling Price:{' '}
                                <b>
                                  {defaultCurrency}
                                  {vehicleDetail?.vehicle?.internet_price?.toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 4,
                                      minimumFractionDigits: 2
                                    }
                                  )}
                                </b>
                              </>
                            }
                          />
                          <Typography
                            theme={theme}
                            component="small"
                            variant="caption"
                            children={
                              <>
                                Type:{' '}
                                <b>
                                  {finalTerms[1]} Months {programData?.finance_type}
                                </b>
                                , Rate:{' '}
                                <b>
                                  {programData?.final_customer_rate?.toLocaleString(undefined, {
                                    maximumFractionDigits: 4,
                                    minimumFractionDigits: 4
                                  })}
                                  %
                                </b>
                              </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="buttons">
                          <Button
                            theme={theme}
                            secondary
                            fullWidth
                            text="View Vehicle Details"
                            onClick={() => {
                              setVehiclePopup(vehicleDetail?.vehicle)
                              openVdModal()
                            }}
                          />
                          <Button
                            theme={theme}
                            primary
                            fullWidth
                            text="Initiate Order"
                            onClick={() => {
                              orderInitiation(vehicleDetail?.vehicle?.vin, finalTerms[1])
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/*********** Card List Item (Toggle Button) ***********/}
                  <AssetDetailCardView
                    vehicleDetail={vehicleDetail}
                    programData={programData}
                    defaultCurrency={defaultCurrency}
                    finalTerms={finalTerms}
                    balloonPayment={balloonPayment}
                    creditScore={creditScore}
                  />
                </Box>
              ))}
            </Box>
          </Box>

          {/*********** Footer Text ***********/}
          <Typography
            theme={theme}
            component="small"
            variant="caption"
            className="footer-text"
            children="These are estimated results only based on the parameters provided above; The amounts do not contain any taxes, F&I products, vehicle add-ons. The amounts may vary in case a quotation or application is started for any of the vehicles shown in the search results."
          />
        </>
      ) : vehicleDetails?.length === 0 ? (
        <Box theme={theme} className="blank-frame-wrap">
          <Box theme={theme} className="blank-frame">
            <img src={BlankFrame} alt="BlankFrame" />
            <Typography
              theme={theme}
              component="h3"
              variant="h3"
              children="Oops! Found Nothing?"
              className="title"
            />
            <Typography
              theme={theme}
              component="small"
              variant="caption"
              children="Looks like there is no vehicle available according to your given parameters. Please configure some other values to find vehicles."
            />
          </Box>
        </Box>
      ) : (
        <Box theme={theme} className="blank-frame-wrap">
          <Box theme={theme} className="blank-frame">
            <img src={BlankFrame} alt="BlankFrame" />
            <Typography
              theme={theme}
              component="h3"
              variant="h3"
              children="Check out what cars we have in store for you!"
              className="title"
            />
            <Typography
              theme={theme}
              component="small"
              variant="caption"
              children="To see the vehicles that fit your budget, start the chat with your personal assistant or customize your payment through the configurations."
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
