import { FC } from 'react'
import { styled, useTheme } from '@mui/material'
import { Icon, Box, IBoxProps, Typography, Select, Button } from '@ntpkunity/controls'
import { BtnAddWrap, BtnTabs, VehicleList } from '@components'

export const FLCWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.flc-wrap': {
    '.flc-ul': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      width: '100%',

      '.flc-li': {
        padding: 0,
        margin: '0 0 8px 0',
        listStyle: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        gap: 8,

        '.flc-li-left-col': {
          width: 'calc(100vh - 76vh)'
        },

        '.flc-li-right-col': {
          display: 'flex',
          gap: 8
        },

        '.flc-li-selfStretch-col': {
          width: 'calc(100vh - 84vh)',
          alignSelf: 'stretch'
        }
      }
    },

    '.amount': {
      padding: '8px !important',
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      '&.btn': {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.fontWeightBold,

        span: {
          fontSize: theme.typography.htmlFontSize,
          fontWeight: theme.typography.fontWeightRegular,
          marginRight: 5
        },

        '&:hover, &:focus': {
          backgroundColor: theme.palette.grey[600],
          color: theme.palette.common.white
        },

        '&:active, &.selected': {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.common.white
        }
      }
    },
    '.flc-labels-wrap': {
      width: '100%',
      display: 'inline-block',
      '.label-row': {
        display: 'flex',
        alignItems: 'center',
        height: 32,
        width: '100%',
        marginBottom: 2,
        '&:last-child': {
          marginBottom: 0
        },
        '.label': {
          width: 'calc(100% - 80%)'
        },
        '.amount, .u-form-group': {
          width: 'calc(100% - 25%)',
          marginLeft: 20
        },
        '.amount': {
          marginTop: -2
        }
      }
    },
    '.u-form-group': {
      '.u-form-control.u-select': {
        height: 32,
        backgroundColor: theme.customVariables.tableStripedBg,
        '.MuiInputBase-input': {
          padding: 8,
          fontSize: 12
        },
        '.MuiSelect-icon': {
          height: 16,
          width: 16,
          right: 8
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: '0 solid transparent'
        },
        '.MuiPaper-root.MuiMenu-paper': {
          top: '47px!important',
          '.MuiList-root.MuiMenu-list': {
            '.MuiButtonBase-root.u-dropdown-item': {
              padding: 8,
              fontSize: theme.typography.caption.fontSize
            }
          }
        }
      }
    },

    '.flc-footer': {
      '.amount': {
        padding: '8px 0',
        borderRadius: 0,
        borderBottom: '1px solid' + theme.palette.divider
      }
    }
  }
}))

const FLCalculator: FC = () => {
  const theme = useTheme()

  return (
    <>
      <FLCWrap theme={theme} className="flc-wrap">
        <ul className="flc-ul">
          {/********************** Add Vehicle **********************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <BtnTabs theme={theme} className="btn-tabs">
                <Button
                  theme={theme}
                  type="button"
                  text="Finance"
                  defaultBtn
                  className="selected"
                  onClick={() => {}}
                />
                <Button theme={theme} type="button" text="Lease" defaultBtn onClick={() => {}} />
              </BtnTabs>
            </Box>
            <VehicleList theme={theme} className="vehicle-list flc-li-right-col">
              <Box theme={theme} className="vl-item sm vertical flc-li-selfStretch-col">
                <Box theme={theme} className="vl-img-wrap">
                  <img src={require('../../public/assets/images/vc-img.png')} alt="Car" />
                </Box>
                <Box theme={theme} className="vl-details">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className=" text-overflow"
                    children={<b>2024 BMW 4 Series i4 eDrive 35</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="p"
                    className=" text-overflow vl-title-ul"
                    children={
                      <>
                        VIN: <b>3478288875124567</b>
                      </>
                    }
                  />
                </Box>
              </Box>
              <Box theme={theme} className="vl-item sm vertical flc-li-selfStretch-col">
                <Box theme={theme} className="vl-img-wrap">
                  <img src={require('../../public/assets/images/vc-img.png')} alt="Car" />
                </Box>
                <Box theme={theme} className="vl-details">
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    className=" text-overflow"
                    children={<b>2024 BMW 4 Series i4 eDrive 35</b>}
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="p"
                    className=" text-overflow vl-title-ul"
                    children={
                      <>
                        VIN: <b>3478288875124567</b>
                      </>
                    }
                  />
                </Box>
              </Box>
              <BtnAddWrap theme={theme} className="btn-add-wrap flc-li-selfStretch-col">
                <Button
                  theme={theme}
                  fullWidth
                  startIcon={<Icon name="AddCircleIcon" />}
                  text="Add Vehicle"
                  size="large"
                  className="btn-vertical"
                />
              </BtnAddWrap>
            </VehicleList>
          </li>
          {/********************** Header **********************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    children="Cash Down"
                  />
                </Box>
              </Box>
            </Box>
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount grey-bg flc-li-selfStretch-col"
              textAlign="center"
              children="$0,000.00"
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount grey-bg flc-li-selfStretch-col"
              textAlign="center"
              children="$0,000.00"
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount grey-bg flc-li-selfStretch-col"
              textAlign="center"
              children="$0,000.00"
            />
          </li>
          {/********************** Calculation Table **********************/}
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Term"
                  />
                  <Select
                    theme={theme}
                    fullWidth
                    disablePortal
                    items={[
                      { text: 'Option 1', value: '1', disabled: true },
                      { text: 'Option 2', value: '2' },
                      { text: 'Option 3', value: '3' }
                    ]}
                  />
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Miles"
                  />
                  <Select
                    theme={theme}
                    fullWidth
                    disablePortal
                    items={[
                      { text: 'Option 1', value: '1', disabled: true },
                      { text: 'Option 2', value: '2' },
                      { text: 'Option 3', value: '3' }
                    ]}
                  />
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="MF"
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="amount grey-bg"
                    children="0.00001"
                  />
                </Box>
              </Box>
            </Box>
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col selected"
              text={
                <>
                  <span>$</span>000
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>000
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>000
                </>
              }
            />
          </li>
          <li className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Term"
                  />
                  <Select
                    theme={theme}
                    fullWidth
                    disablePortal
                    items={[
                      { text: 'Option 1', value: '1', disabled: true },
                      { text: 'Option 2', value: '2' },
                      { text: 'Option 3', value: '3' }
                    ]}
                  />
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Miles"
                  />
                  <Select
                    theme={theme}
                    fullWidth
                    disablePortal
                    items={[
                      { text: 'Option 1', value: '1', disabled: true },
                      { text: 'Option 2', value: '2' },
                      { text: 'Option 3', value: '3' }
                    ]}
                  />
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="MF"
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="amount grey-bg"
                    children="0.00001"
                  />
                </Box>
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="label"
                    children="Res"
                  />
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    className="amount grey-bg"
                    children="00"
                  />
                </Box>
              </Box>
            </Box>
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>000
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>000
                </>
              }
            />
            <Button
              theme={theme}
              defaultBtn
              className="amount grey-bg flc-li-selfStretch-col"
              text={
                <>
                  <span>$</span>000
                </>
              }
            />
          </li>
          {/********************** Footer **********************/}
          <li className="flc-li flc-footer">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="div"
                    children="Amount Financed"
                  />
                </Box>
              </Box>
            </Box>
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount flc-li-selfStretch-col"
              textAlign="center"
              children="$00,000.00"
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount flc-li-selfStretch-col"
              textAlign="center"
              children="$00,000.00"
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount flc-li-selfStretch-col"
              textAlign="center"
              children="$00,000.00"
            />
          </li>
          <li className="flc-li flc-footer">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Typography theme={theme} variant="caption" component="div" children="Carry%" />
                </Box>
              </Box>
            </Box>
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount flc-li-selfStretch-col"
              textAlign="center"
              children="00.00"
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount flc-li-selfStretch-col"
              textAlign="center"
              children="00.00"
            />
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="amount flc-li-selfStretch-col"
              textAlign="center"
              children="00.00"
            />
          </li>
        </ul>
      </FLCWrap>
    </>
  )
}

export default FLCalculator
