export enum DialogMessages {
  confirmationTitle = 'Are you sure you want to cancel order?',
  confirmationText = 'Once cancelled, you cannot undo this action.',
  cancelBtnText = 'Confirm',
  removeBtnText = 'Remove',
  insuranceConfirmationTitle = 'Are you sure you want to remove insurance details?',
  paymentConfirmationTitle = 'Are you sure you want to remove payment details?',
  tradeInConfirmationTitle = 'Are you sure you want to remove trade-in details?',
  completeOrderTitle = 'Are you sure you want to complete order?',
  completeOrderText = 'Once confirm, you cannot undo this action.',
  updatedDMSDATA = 'Do you wish to update information received from Dealer Management System?',
  updatedDMSDATATEXT = 'Once updated, you cannot undo this action.'
}
