import { FC, useState, useEffect, memo, useCallback, useMemo } from 'react'
import { useTheme } from '@mui/material'
import { PersistentDrawer, Button } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { useForm } from 'react-hook-form'
import {
  GeneralPreferences,
  OrderFnIProducts,
  OrderOptions,
  OrderTradeIn,
  AssetUsage,
  ScheduleOption
} from './drawer-sections'
import { useGetDealerProfileById } from '@apis/dealer-configurations.service'
import OrderInsurance from '@app/order-management/order-preferences/drawer-sections/order-insurance'
import { IQuotationParameters } from 'types/quote-parameters'
import { QueryKeys, Status, StipulationType } from '@helpers/enums'
import { FinancialStipType } from '@helpers/enums/stipulation-type.enum'
import { useQueryClient } from 'react-query'
import { IFinancialInsuranceProduct } from '@models'

const OrderQuotationDrawer: FC<{
  orderStatus?: string
  popUpState: boolean
  setPopUpState: any
  quotationParameterData: IQuotationParameters | undefined
  setQuotationParameterData: (data: any) => unknown
  programs: any
  setQuoteCalculate: (data: any) => unknown
  openAddOnDialog: any
  setOpenAddOnDialog: any
}> = ({
  popUpState,
  setPopUpState,
  quotationParameterData,
  setQuotationParameterData,
  programs,
  setQuoteCalculate,
  orderStatus,
  openAddOnDialog,
  setOpenAddOnDialog
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { states, actions } = useStoreContext()
  // const dealerData = getUser();
  const [, setSelectedOptionsForRequest] = useState([])
  const [defaultCurrency, setDefaultCurrency] = useState('')

  //@ts-ignore
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  const [seletedOptionsArrayComplete, setSelectedOptionsArrayCompleteObjects] = useState<any[]>([])
  const [fnIRatesReqData, setFNIProductsRatesRequestData] = useState({})
  const [tradeInRequestData, setTradeInRequestData] = useState()
  const [insuranceRequestData, setInsuranceRequestData] = useState({})
  const { mutate: getDealerProfile, data: dealerProfileData } = useGetDealerProfileById()
  const [fniData, setFniData] = useState<{
    finance: Array<IFinancialInsuranceProduct>
    lease: Array<IFinancialInsuranceProduct>
  }>({
    finance:
      (quotationParameterData?.finance_fni &&
        quotationParameterData?.finance_fni?.map((fni: any) => {
          return {
            id: fni.financial_insurance_id,
            price: fni.applied_price,
            form_id: fni?.form_id,
            rate_id: fni?.rate_id,
            session_id: fni?.session_id,
            product_name: fni?.product_name,
            identifier: fni?.identifier
          }
        })) ||
      [],
    lease:
      (quotationParameterData?.lease_fni &&
        quotationParameterData?.lease_fni?.map((fni: any) => {
          return {
            id: fni.financial_insurance_id,
            price: fni.applied_price,
            form_id: fni?.form_id,
            rate_id: fni?.rate_id,
            session_id: fni?.session_id,
            product_name: fni?.product_name,
            identifier: fni?.identifier
          }
        })) ||
      []
  })
  const isOrderConditioned = orderStatus === Status.Conditioned
  const isStipTerms = (states.stipulationsData as any).stipulations
    ?.filter((stip: any) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL)
    ?.some((stip: any) => stip.parameter === FinancialStipType.TERMS)
  const isStipFinanceOrMonthly = (states.stipulationsData as any).stipulations
    ?.filter((stip: any) => stip.stipulation_type?.toLowerCase() === StipulationType.FINANCIAL)
    ?.some(
      (stip: any) =>
        stip.parameter === FinancialStipType.FINANCED_AMOUNT ||
        stip.parameter === FinancialStipType.PERIODIC_PAYMENT
    )
  const isDisable = isOrderConditioned && isStipTerms && !isStipFinanceOrMonthly

  const form: {
    handleSubmit: any
    reset: any
  } = useForm<IQuotationParameters>({
    defaultValues: useMemo(() => {
      return quotationParameterData
    }, [quotationParameterData])
  })

  useEffect(() => {
    if (states?.dealerAndLenderInfo?.id !== undefined) {
      getDealerProfile({
        dealer_id: states?.dealerAndLenderInfo?.dealer_code
      })
    }
  }, [])
  const onSubmit = async (data: IQuotationParameters) => {
    const term = states?.contractTermsData?.find(
      (term: any) => term.id == data?.contract_term_id
    )?.term
    actions.setStipulationsInitialData({ ...states.stipulationsData, terms: term as number })
    data.trade_in = tradeInRequestData
    data.insurance = insuranceRequestData
    data.options = seletedOptionsArrayComplete ? seletedOptionsArrayComplete : []
    fniData?.finance?.forEach((fnIItem: any) => {
      const matchingItem = quotationParameterData?.finance_fni?.find(
        (fniItem: any) => fniItem?.financial_insurance_id === fnIItem?.id
      )
      if (matchingItem) {
        fnIItem.identifier = matchingItem?.identifier
      }
    })
    data.finance_fni = fniData?.finance?.map((fni: any) => {
      return {
        financial_insurance_id: fni.id,
        applied_price: fni.price,
        product_name: fni.product_name,
        identifier: fni?.identifier,
        form_id: fni?.form_id,
        rate_id: fni?.rate_id,
        session_id: fni?.session_id
      }
    })

    fniData?.lease?.forEach((fnIItem: any) => {
      const matchingItem = quotationParameterData?.lease_fni?.find(
        (fniItem: any) => fniItem?.financial_insurance_id === fnIItem.id
      )
      if (matchingItem) {
        fnIItem.identifier = matchingItem?.identifier
      }
    })
    data.lease_fni = fniData?.lease?.map((fni: any) => {
      return {
        financial_insurance_id: fni.id,
        applied_price: fni.price,
        product_name: fni.product_name,
        identifier: fni?.identifier,
        form_id: fni?.form_id,
        rate_id: fni?.rate_id,
        session_id: fni?.session_id
      }
    })
    setQuotationParameterData(data)
    setQuoteCalculate(true)
    setPopUpState(false)

    actions.setTradeInEquityData(tradeInRequestData)
    actions.setSelectedOrderOptions(seletedOptionsArrayComplete)
  }
  const checkSelectedOptions = useCallback((data: any) => {
    const requestArray: any = []
    data?.map((obj: any) => {
      requestArray.push({
        option_name: obj?.product_name,
        option_amount: obj?.price,
        rv_amount_on_option: obj?.rv_adder
      })
    })
    setSelectedOptionsArrayCompleteObjects(
      data.map((option: any) => {
        return {
          installation_mode: option.installation_mode,
          option_id: option.option_id ? option.option_id : option.id,
          applied_price: (option.applied_price ? option.applied_price : option.price) || 0,
          product_name: option.product_name,
          is_price_inclusive: option.is_price_inclusive,
          rv_balloon_percentage:
            (option.rv_balloon_percentage ? option.rv_balloon_percentage : option.rv_adder) || 0,
          rv_balloon_value:
            (option.rv_balloon_value ? option.rv_balloon_value : option.rv_adder) || 0
        }
      })
    )
    setSelectedOptionsForRequest(requestArray)
  }, [])
  const checkTradeInData = useCallback((data: any) => {
    if (data && Object.keys(data).length > -1) {
      setTradeInRequestData(data)
    } else {
      setTradeInRequestData(tradeInRequestData)
    }
  }, [])
  const checkInsuranceData = useCallback((data: any) => {
    if (data && Object.keys(data).length > -1) {
      setInsuranceRequestData(data)
    } else {
      setInsuranceRequestData(insuranceRequestData)
    }
  }, [])

  const getSelectedDataForRatesRequest = useCallback((data: any) => {
    if (data && Object.keys(data).length > -1) {
      setFNIProductsRatesRequestData({ ...data })
    }
  }, [])
  useEffect(() => {
    quotationParameterData?.trade_in && checkTradeInData(quotationParameterData?.trade_in)
    quotationParameterData?.insurance && checkInsuranceData(quotationParameterData?.insurance)
  }, [quotationParameterData])
  return (
    <>
      <PersistentDrawer
        title={'Configure Quotation'}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        theme={theme}
        customFooter={
          <Button
            theme={theme}
            primary
            type="submit"
            text="Update Quotation"
            fullWidth
            onClick={form.handleSubmit(onSubmit)}
          />
        }
      >
        <GeneralPreferences
          isOrderConditioned={isOrderConditioned}
          isStipTerms={isStipTerms}
          isDisable={isDisable}
          defaultCurrency={defaultCurrency}
          form={form}
          getSelectedDataForRatesRequest={getSelectedDataForRatesRequest}
          programs={programs}
        />
        <OrderOptions
          isDisable={isDisable}
          defaultCurrency={defaultCurrency}
          selectedObjectsArray={checkSelectedOptions}
          defaultOptions={quotationParameterData?.options}
          setOpenAddOnDialog={setOpenAddOnDialog}
          openAddOnDialog={openAddOnDialog}
        />
        <OrderFnIProducts
          isOrderConditioned={isOrderConditioned}
          isDisable={isDisable}
          defaultCurrency={defaultCurrency}
          fnIRatesReqData={fnIRatesReqData}
          fniData={fniData}
          setFniData={setFniData}
          programs={programs}
          quotationParameterData={quotationParameterData}
          dealerProfileData={dealerProfileData}
        />
        <OrderInsurance
          isDisable={isOrderConditioned}
          selectedInsurance={checkInsuranceData}
          insuranceRequestData={insuranceRequestData}
        />
        <OrderTradeIn
          isDisable={isDisable}
          defaultCurrency={defaultCurrency}
          selectedTradeIn={checkTradeInData}
          tradeInRequestData={tradeInRequestData}
          orderStatus={orderStatus}
        />
        <AssetUsage isDisable={isOrderConditioned} form={form} />
        <ScheduleOption isDisable={isOrderConditioned} form={form} />
      </PersistentDrawer>
    </>
  )
}

export default memo(OrderQuotationDrawer)
