export enum WorkQueueState {
  isOrder = 'Order',
  isSchedulePickup = 'Scheduled/Pickup',
  isCancel = 'Cancel',
  isComplete = 'Complete',
  isAppointment = 'Appointment',
  isPendingConfirmation = 'Pending Confirmation',
  isDeclined = 'Declined',
  isWithdrawn = 'Withdrawn',
  isPreQualification = 'Pre-Qualification'
}
