import { styled } from '@mui/material/styles'
import { Box, IBoxProps, unityTheme } from '@ntpkunity/controls'

export const AutoSearch: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.auto-search': {
    color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[600],
    '.MuiButtonBase-root': {
      padding: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      border: '0 solid transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      svg: {
        path: {
          stroke:
            theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
        }
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        svg: {
          transform: 'scaleY(-1)'
        }
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    },
    '.MuiAutocomplete-endAdornment': {
      '.MuiButtonBase-root': {
        backgroundColor: 'transparent !important'
      }
    },
    '.overlay': {
      content: "''",
      transition: 'all 0.3s',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1300
    },
    '.list-wrap': {
      position: 'absolute',
      width: 350,
      marginTop: 10,
      backgroundColor:
        theme.palette.mode === 'light' ? [theme.palette.common.white] : theme.palette.grey[800],
      padding: 10,
      borderRadius: theme.shape.borderRadius,
      boxShadow: 'rgb(0 0 0 / 16%) 0px 16px 32px',
      zIndex: 1300,
      '.u-custom-autocomplete': {
        marginBottom: 0,
        '.MuiAutocomplete-root': {
          '.u-form-group': {
            '.MuiInputBase-root': {
              '.MuiInputBase-input': {
                color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.87)' : 'rgba(0,0,0,0.87)'
              }
            },
            '.MuiButtonBase-root': {
              '&.MuiAutocomplete-popupIndicator': {
                'svg.MuiSvgIcon-root': {
                  fill: 'none',
                  width: 24,
                  height: 24,
                  transform: 'rotate(-175deg)',
                  path: {
                    d: 'path("M21 21L15 15L21 21ZM17 10C17 10.9193 16.8189 11.8295 16.4672 12.6788C16.1154 13.5281 15.5998 14.2997 14.9497 14.9497C14.2997 15.5998 13.5281 16.1154 12.6788 16.4672C11.8295 16.8189 10.9193 17 10 17C9.08075 17 8.1705 16.8189 7.32122 16.4672C6.47194 16.1154 5.70026 15.5998 5.05025 14.9497C4.40024 14.2997 3.88463 13.5281 3.53284 12.6788C3.18106 11.8295 3 10.9193 3 10C3 8.14348 3.7375 6.36301 5.05025 5.05025C6.36301 3.7375 8.14348 3 10 3C11.8565 3 13.637 3.7375 14.9497 5.05025C16.2625 6.36301 17 8.14348 17 10Z")',
                    strokeWidth: '2',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round'
                  }
                }
              }
            }
          }
        }
      },
      '.MuiAutocomplete-popper': {
        position: 'relative !important',
        top: '0 !important',
        transform: 'initial !important',
        borderWidth: '0 !important',
        borderStyle: 'solid !important',
        borderColor: 'transparent !important',
        borderRadius: '0 !important',
        boxShadow: '0 0 0 transparent !important',
        color: '#515466',
        margin: '10px 0 0 0 !important',
        '.MuiAutocomplete-paper': {
          borderRadius: '0 !important',
          boxShadow: '0 0 0 transparent !important',
          backgroundColor: 'transparent'
        },
        '.MuiAutocomplete-listbox': {
          padding: 0,
          borderWidth: '0 !important',
          borderStyle: 'solid !important',
          borderColor: 'transparent !important',
          borderRadius: '0 !important',
          boxShadow: '0 0 0 transparent !important',
          color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[900],
          '@supports (-moz-appearance:none)': {
            scrollbarWidth: 'thin'
          },
          '&::-webkit-scrollbar': {
            width: 7,
            height: 7
          },
          '&::-webkit-scrollbar-track': {
            padding: '0 1px',
            backgroundColor: theme.palette.mode === 'light' ? '#f0f0f0' : theme.palette.grey[700]
          },
          '&::-webkit-scrollbar-thumb': {
            transition: 'all 0.3s',
            backgroundColor: theme.palette.mode === 'light' ? '#cdcdcd' : theme.palette.grey[500]
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.mode === 'light' ? '#a6a6a6' : theme.palette.grey[600]
          }
        },
        '.MuiPaper-root': {
          border: '0 solid transparent',
          '.MuiAutocomplete-listbox': {
            '.MuiAutocomplete-option': {
              backgroundColor: 'transparent',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.common.white
                  : theme.palette.grey[900],
              padding: '12px 24px 12px 16px',
              borderRadius: '8px',
              margin: 0,
              '&.Mui-focused, &.Mui-focused:hover': {
                backgroundColor: 'transparent'
              },
              '&:hover': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.grey[700] + '!important'
                    : theme.palette.grey[50] + '!important',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.grey[900]
              },
              '&.Mui-selected, &[aria-selected=true]': {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? unityTheme.palette.grey[600] + '!important'
                    : '#F2F4FA' + '!important'
              },
              '.MuiTouchRipple-root': {
                display: 'none'
              }
            }
          },
          '.MuiAutocomplete-noOptions': {
            color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[900]
          }
        }
      }
    }
  }
}))
