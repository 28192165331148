import { FC } from 'react'
import { Link, useTheme } from '@mui/material'
import { DetailCard } from 'components'
import { Typography, Grid } from '@ntpkunity/controls'
import { InsuranceRecord } from 'models/order-information.interface'
import { useGetPreSignedUrl } from '@apis/order-management.service'

const InsuranceDetails: FC<{ insurance: InsuranceRecord }> = ({ insurance }) => {
  const theme = useTheme()
  const { mutate: getPreSignedUrl } = useGetPreSignedUrl()

  const handleClick = () => {
    getPreSignedUrl(
      { key: new URL(insurance?.document_uri || '')?.pathname?.substring(1) },
      {
        onSuccess(res: any) {
          window.open(res, '_blank')
        }
      }
    )
  }

  return (
    <DetailCard className="detail-card" mb={2}>
      <Typography theme={theme} component="h4" variant="h4" mb={2}>
        Insurance
      </Typography>
      <Grid theme={theme} className="container" container>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Insurance Provider
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="span">
            {insurance?.insurer_name ? insurance?.insurer_name : '-'}
          </Typography>
        </Grid>
        {/* *********************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Insurance Contact
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {insurance?.agent_phone ? insurance?.agent_phone : '-'}
          </Typography>
        </Grid>
        {/* *********************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Policy Number
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {insurance?.policy_number ? insurance?.policy_number : '-'}
          </Typography>
        </Grid>
        {/* *********************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Expiration
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {insurance?.expiration_date?.split('T')[0]
              ? insurance?.expiration_date?.split('T')[0]
              : '-'}
          </Typography>
        </Grid>
        {/* *********************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Address
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label-detail">
            {`${insurance?.insurance_address?.address_line_1}, ${
              (insurance?.insurance_address?.address_line_2 ?? '').length > 0
                ? `${insurance?.insurance_address?.address_line_2},`
                : ''
            } ${insurance?.insurance_address?.city},
          ${insurance?.insurance_address?.state_name},
          ${insurance?.insurance_address?.zip_code}
          `}
          </Typography>
        </Grid>
        {/* *********************** */}
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography theme={theme} variant="body2" component="h3" className="label">
            Proof Insurance
          </Typography>
        </Grid>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={8} xs={12}>
          <Typography
            theme={theme}
            variant="body2"
            component="h3"
            className="label-detail"
            children={
              <>
                <Link className="link" onClick={handleClick}>
                  View Image
                </Link>
              </>
            }
          />
        </Grid>
      </Grid>
    </DetailCard>
  )
}

export default InsuranceDetails
