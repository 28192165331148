import { FC, Key, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AccordionDetails, AccordionSummary, MenuItem, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Accordion, Box, CircleLoader, Grid, Icon, Select, Typography } from '@ntpkunity/controls'
import { DetailCard, PageHeader, ProductBanner } from '@components'
import { useGetLeadDetails } from '@apis/lead-management.service'
import { formatCurrency } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'
import { LeadData } from 'models/lead-data.interface'

const LeadSummary: FC = () => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const [leadData, setLeadData] = useState<Record<string, any> | null>(null)
  const { states } = useStoreContext()
  const queryClient = useQueryClient()
  const { data, isLoading, error } = useGetLeadDetails(states?.dealerAndLenderInfo?.id)

  useEffect(() => {
    if (data && id) {
      const lead = data.find((item: LeadData) => {
        if (item.data && Array.isArray(item.data.vehicle_details)) {
          return item.data.vehicle_details.find((vehicle) => vehicle.id === Number(id))
        }
        return item.id === Number(id)
      })
      if (lead) {
        const vehicle = lead.data?.vehicle_details?.find(
          (vehicle: { id: number }) => vehicle.id === Number(id)
        )
        setLeadData({ ...lead, vehicleDetails: vehicle })
      } else {
        console.error(`No lead found for id: ${id}`)
        setLeadData(null)
      }
    }
  }, [data, id])

  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency.symbol : '$'
    )
  }, [currencies])

  if (isLoading) return <CircleLoader theme={theme} />
  if (error) return <div>An error occurred: {error.message}</div>
  if (!leadData) return null

  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader className="main-page-header border-bottom" theme={theme}>
          <Box flexGrow={'1'} theme={theme}>
            <Typography theme={theme} variant="h2" component="h2" className="title">
              Lead Summary
            </Typography>
            <Typography theme={theme} variant="body2" component="p" className="caption">
              You can review the lead details below and reach out to the customer
            </Typography>
          </Box>
        </PageHeader>
      </Box>
      <ProductBanner>
        <Grid theme={theme} spacing={2} container>
          <Grid theme={theme} item xl={2} lg={3} md={3} sm={5} xs={12}>
            <Box theme={theme} className="thumbnail">
              <img src={leadData?.vehicleDetails?.photo_urls?.[0]?.location || ''} alt="Product" />
            </Box>
          </Grid>
          <Grid
            theme={theme}
            item
            xl={5}
            lg={4.5}
            md={4.5}
            sm={12}
            xs={12}
            className="product-details"
          >
            <Box theme={theme} className="title-md">
              {`${leadData?.vehicleDetails?.make || '-'} ${leadData?.vehicleDetails?.model || '-'}`}
            </Box>
            <Box theme={theme} className="title-lg">
              {leadData?.vehicleDetails?.trim_description || '-'}
            </Box>
            <Box theme={theme} className="title-sm">
              Lead ID: <b>{leadData?.vehicleDetails?.id || '-'}</b>, Status:{' '}
              {leadData?.vehicleDetails?.status || '-'}
            </Box>
            <Box theme={theme} className="title-sm">
              VIN: <b>{leadData?.vehicleDetails?.vin || '-'}</b>
            </Box>
          </Grid>
          <Grid
            theme={theme}
            item
            xl={5}
            lg={4.5}
            md={4.5}
            sm={12}
            xs={12}
            sx={{ mt: 2 }}
            className="product-details"
            textAlign="right"
          >
            <Box theme={theme} className="actions-wrap">
              <Box theme={theme}>
                <Box theme={theme} className="title-md">
                  Net Financed Amount
                </Box>
                <Box theme={theme} className="title-lg">
                  {defaultCurrency}
                  {leadData?.vehicleDetails?.finance?.net_finance_amount != null
                    ? Math.abs(
                        leadData?.vehicleDetails?.finance?.net_finance_amount
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    : '-'}
                </Box>
                <Box theme={theme} className="title-sm">
                  Type:{' '}
                  <b>
                    {leadData?.vehicleDetails?.finance?.contract_term &&
                    leadData?.vehicleDetails?.finance?.finance_type
                      ? `${leadData?.vehicleDetails?.finance?.contract_term} Months ${leadData?.vehicleDetails?.finance?.finance_type}`
                      : '-'}
                  </b>
                  , Rate:{' '}
                  <b>
                    {leadData?.vehicleDetails?.finance?.base_rate != null
                      ? `${leadData?.vehicleDetails?.finance?.base_rate.toLocaleString(undefined, {
                          maximumFractionDigits: 4,
                          minimumFractionDigits: 4
                        })}%`
                      : '-'}
                  </b>
                </Box>
                <Box theme={theme} className="title-sm">
                  <>Est. Periodic Payment: </>
                  <b>
                    {defaultCurrency}
                    {leadData?.vehicleDetails?.finance?.estimated_monthly_payment != null
                      ? Math.abs(
                          leadData?.vehicleDetails?.finance?.estimated_monthly_payment
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      : '-'}
                  </b>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ProductBanner>
      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Customer Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Name"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.customer_details?.first_name || '-'} ${
                    leadData?.data?.customer_details?.last_name || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Address"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    (leadData?.data?.customer_details?.address?.address_line_1 || '') +
                    (leadData?.data?.customer_details?.address?.city
                      ? ', ' + leadData?.data?.customer_details?.address?.city
                      : '') +
                    (leadData?.data?.customer_details?.address?.state_name
                      ? ', ' + leadData?.data?.customer_details?.address?.state_name
                      : '') +
                    (leadData?.data?.customer_details?.address?.zipcode
                      ? ' ' + leadData?.data?.customer_details?.address?.zip_code
                      : '')
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Email"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.data?.customer_details?.email || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Credit Score"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.vehicleDetails?.finance?.credit_rating || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Mobile Phone Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.data?.customer_details?.mobile_number || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Preferred Communication"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.data?.customer_details?.preferred_contact || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="License Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.data?.customer_details?.license_info?.license_number || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Date of Birth"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.data?.customer_details?.license_info?.date_of_birth || '-'}
                />
              </Grid>
            </Grid>
          </DetailCard>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Asset Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.vehicleDetails?.make || '-'} ${
                    leadData?.vehicleDetails?.model || '-'
                  } ${leadData?.vehicleDetails?.trim_description || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset Condition"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.vehicleDetails?.type || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Stock Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.vehicleDetails?.stock_number || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Current Mileage"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData?.vehicleDetails?.mileage
                      ? `${leadData?.vehicleDetails?.mileage} Miles`
                      : '-'
                  }
                />
              </Grid>
            </Grid>
          </DetailCard>
          <DetailCard className="detail-card" mb={2}>
            <Box className="card-body" theme={theme}>
              <Box theme={theme} mb={2}>
                <Typography theme={theme} component="h4" variant="h4" children="Trade-In" />
                <Grid theme={theme} className="container" container>
                  <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="h3"
                      className="label"
                      children={
                        <>
                          {leadData?.vehicleDetails?.trade_in?.year || '-'}{' '}
                          {leadData?.vehicleDetails?.trade_in?.make || '-'}
                          {leadData?.vehicleDetails?.trade_in?.model || '-'}
                        </>
                      }
                    />
                    <Typography
                      theme={theme}
                      variant="subtitle1"
                      component="div"
                      className="card-title"
                      children={leadData?.vehicleDetails?.trade_in?.trim || '-'}
                    />
                  </Grid>
                  <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Box theme={theme} className="thumbnail">
                      <img
                        src={leadData?.vehicleDetails?.trade_in?.asset_image?.[0] || ''}
                        alt="Product"
                      />
                    </Box>
                  </Grid>
                  <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                    <Box theme={theme} className="priceList">
                      <Typography
                        theme={theme}
                        className="card-title"
                        mb={1}
                        component="h4"
                        variant="h4"
                        children={
                          leadData?.vehicleDetails?.trade_in?.trade_in_amount != null ? (
                            <>
                              {defaultCurrency}
                              {Math.abs(
                                leadData?.vehicleDetails?.trade_in?.trade_in_amount
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </>
                          ) : (
                            '-'
                          )
                        }
                      />
                      <Box theme={theme} className="price-detail">
                        <Typography
                          theme={theme}
                          className="detail offer"
                          component="span"
                          variant="caption"
                          children="Our Offer:&nbsp;"
                        />
                        <Typography
                          theme={theme}
                          className="detail price"
                          component="span"
                          variant="caption"
                          children={
                            <>
                              {leadData?.vehicleDetails?.finance?.our_offer != null ? (
                                <>
                                  {defaultCurrency}
                                  {Math.abs(
                                    leadData?.vehicleDetails?.finance?.our_offer
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 4,
                                    minimumFractionDigits: 2
                                  })}
                                </>
                              ) : (
                                '-'
                              )}
                            </>
                          }
                        />
                      </Box>
                      <Box theme={theme} className="price-detail">
                        <Typography
                          theme={theme}
                          className="detail offer"
                          component="span"
                          variant="caption"
                          children={<>Lease Balance:&nbsp; </>}
                        />
                        <Typography
                          theme={theme}
                          className="detail price"
                          component="span"
                          variant="caption"
                          children={
                            <>
                              {leadData?.vehicleDetails?.trade_in?.payoff_amount != null ? (
                                <>
                                  {defaultCurrency}
                                  {Math.abs(
                                    leadData?.vehicleDetails?.trade_in?.payoff_amount
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 4,
                                    minimumFractionDigits: 2
                                  })}
                                </>
                              ) : (
                                '-'
                              )}
                            </>
                          }
                        />
                      </Box>
                      <Box theme={theme} className="price-detail">
                        <Typography
                          theme={theme}
                          className="detail offer"
                          component="span"
                          variant="caption"
                          children="Net Trade-In Amount:&nbsp;"
                        />
                        <Typography
                          theme={theme}
                          className="detail price"
                          component="span"
                          variant="caption"
                          children={
                            leadData?.vehicleDetails?.trade_in?.trade_in_amount != null ? (
                              <>
                                {defaultCurrency}
                                {Math.abs(
                                  leadData?.vehicleDetails?.trade_in?.trade_in_amount
                                ).toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2
                                })}
                              </>
                            ) : (
                              '-'
                            )
                          }
                        />
                      </Box>
                      <Box theme={theme} className="price-detail">
                        <Typography
                          theme={theme}
                          className="detail offer"
                          component="span"
                          variant="caption"
                          children="Estimated Mileage:&nbsp;"
                        />
                        <Typography
                          theme={theme}
                          className="detail price"
                          component="span"
                          variant="caption"
                          children={
                            <>
                              {' '}
                              {leadData?.vehicleDetails?.trade_in?.mileage?.toLocaleString() ||
                                '-'}{' '}
                            </>
                          }
                        />
                      </Box>
                      <Box theme={theme} className="price-detail">
                        <Typography
                          theme={theme}
                          className="detail offer"
                          component="span"
                          variant="caption"
                          children="Condition:&nbsp;"
                        />
                        <Typography
                          theme={theme}
                          className="detail price"
                          component="span"
                          variant="caption"
                          children={<> {leadData?.vehicleDetails?.trade_in?.condition || '-'} </>}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </DetailCard>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Search Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Keyword"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData?.data?.vehicle_search_criteria?.length > 0
                      ? leadData.data.vehicle_search_criteria
                          .map(
                            (
                              criteria: { makes: any[]; models: any[]; trims: any[] },
                              index: Key | null | undefined
                            ) => {
                              const parts = [
                                criteria.makes?.[0],
                                criteria.models?.[0],
                                criteria.trims?.[0]
                              ].filter(Boolean)

                              return parts.length > 0 ? (
                                <div key={index}>{parts.join(', ')}</div>
                              ) : null
                            }
                          )
                          .filter(Boolean)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset Condition"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${
                    leadData?.data?.vehicle_search_criteria?.[0]?.asset_conditions[0] || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Selling Price"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData?.data?.vehicle_search_criteria?.length > 0
                      ? leadData.data.vehicle_search_criteria.map(
                          (
                            criteria: { minSellingPrice: any; maxSellingPrice: any },
                            index: Key | null | undefined
                          ) => (
                            <div key={index}>
                              {criteria.minSellingPrice != null &&
                              criteria.maxSellingPrice != null ? (
                                <>
                                  {defaultCurrency}
                                  {Math.abs(criteria.minSellingPrice).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                  {' - '}
                                  {defaultCurrency}
                                  {Math.abs(criteria.maxSellingPrice).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </>
                              ) : (
                                '-'
                              )}
                            </div>
                          )
                        )
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Year"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.year_from || '-'} - ${
                    leadData?.data?.vehicle_search_criteria?.[0]?.year_to || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Make/Model/Trim"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData?.data?.vehicle_search_criteria?.length > 0
                      ? leadData.data.vehicle_search_criteria
                          .map(
                            (
                              criteria: { makes: any[]; models: any[]; trims: any[] },
                              index: Key | null | undefined
                            ) => {
                              const parts = [
                                criteria.makes?.[0],
                                criteria.models?.[0],
                                criteria.trims?.[0]
                              ].filter(Boolean)

                              return parts.length > 0 ? (
                                <div key={index}>{parts.join(', ')}</div>
                              ) : null
                            }
                          )
                          .filter(Boolean)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="VIN"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.vin || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Stock Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.stock || '-'}`}
                />
              </Grid>
            </Grid>
          </DetailCard>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} theme={theme}>
          <DetailCard className="detail-card payment-bd" mb={3}>
            <Box className="card-header" theme={theme}>
              <Typography component="h3" variant="h3" theme={theme}>
                {leadData?.vehicleDetails?.finance?.contract_term &&
                leadData?.vehicleDetails?.finance?.finance_type
                  ? `${leadData?.vehicleDetails?.finance?.contract_term} Months ${leadData?.vehicleDetails?.finance?.finance_type}`
                  : '-'}
              </Typography>
              <Typography className="text" component="span" variant="body2" theme={theme}>
                Payment Breakdown
              </Typography>
            </Box>
            <Box className="line" theme={theme}>
              <hr />
            </Box>
            <Box className="card-body" theme={theme}>
              <Box className="summary-row toggle" sx={{ mb: 2 }} theme={theme}>
                <Box className="row" theme={theme}>
                  <Box className="center" theme={theme}>
                    <span className="card-text">
                      <b>Periodic Payment</b>
                    </span>
                    <Icon name="ChevronDown" />
                  </Box>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    <b>
                      {defaultCurrency}
                      {leadData?.vehicleDetails?.finance?.estimated_monthly_payment != null
                        ? formatCurrency(
                            Math.abs(leadData?.vehicleDetails?.finance?.estimated_monthly_payment),
                            {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            }
                          )
                        : '-'}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion items={[]} theme={theme}>
                <AccordionSummary />
                <AccordionDetails sx={{ ml: 2 }}>
                  <Select fullWidth defaultValue="netPeriodicPayment" theme={theme} items={[]}>
                    <MenuItem value="netPeriodicPayment">Net Periodic Payment</MenuItem>
                    <MenuItem value="taxAmount">Tax Amount</MenuItem>
                  </Select>
                </AccordionDetails>
              </Accordion>
              <Box className="row" sx={{ mb: 2 }} theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Terms
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {leadData?.vehicleDetails?.finance?.contract_term
                    ? `${leadData?.vehicleDetails?.finance?.contract_term} Months`
                    : '-'}
                </Typography>
              </Box>

              <Box className="row" sx={{ mb: 2 }} theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Rate
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {leadData?.vehicleDetails?.finance?.base_rate != null
                    ? `${leadData?.vehicleDetails?.finance?.base_rate.toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                      })}%`
                    : '-'}
                </Typography>
              </Box>

              <Box className="line" theme={theme}>
                <hr />
              </Box>

              <Box className="row" sx={{ mb: 2 }} theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Selling Price
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {leadData?.vehicleDetails?.internet_price != null ? (
                    <>
                      {defaultCurrency}
                      {Math.abs(leadData?.vehicleDetails?.internet_price).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Box>

              <Box className="row" theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Down Payment
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {defaultCurrency}
                  {leadData?.vehicleDetails?.finance?.down_payment != null
                    ? Math.abs(leadData?.vehicleDetails?.finance?.down_payment).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }
                      )
                    : '-'}
                </Typography>
              </Box>

              <Box className="summary-row toggle" sx={{ mt: 2 }} theme={theme}>
                <Box className="row" theme={theme}>
                  <Box className="center" theme={theme}>
                    <span className="card-text">Trade-In Equity/Inequity</span>
                  </Box>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    <b>
                      {leadData?.vehicleDetails?.trade_in?.trade_in_amount != null ? (
                        <>
                          {defaultCurrency}
                          {Math.abs(
                            leadData?.vehicleDetails?.trade_in?.trade_in_amount
                          ).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}
                        </>
                      ) : (
                        '-'
                      )}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion items={[]} theme={theme}>
                <AccordionSummary />
                <AccordionDetails sx={{ ml: 2 }}>
                  <Select fullWidth defaultValue="tradeInAllowance" theme={theme} items={[]}>
                    <MenuItem value="tradeInAllowance">Trade-In Allowance</MenuItem>
                    <MenuItem value="payoffAmount">Payoff Amount</MenuItem>
                  </Select>
                </AccordionDetails>
              </Accordion>
            </Box>
          </DetailCard>
        </Grid>
      </Grid>
    </LayoutWithSideNav>
  )
}

export default LeadSummary
